import React from "react";

export const Beds = () => {
  return (
    <svg
      // width="2.29vw"
      // height="1.3vw"
      className="beds-icon"
      viewBox="0 0 33 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1105 9.7576L1.88965 1.88783L2.40499 1.39004C3.2148 0.607807 4.39271 0.323357 5.76695 0.607807C7.01848 0.844848 8.29455 1.55597 9.34976 2.57525C10.405 3.59453 11.1412 4.80344 11.4111 6.03605C11.7056 7.36348 11.4357 8.50128 10.6258 9.28352L10.1105 9.7576ZM4.09824 2.00635L9.96326 7.62423C10.086 7.24496 10.086 6.79459 9.96326 6.2968C9.7424 5.34863 9.15345 4.37676 8.29455 3.57082C7.43566 2.74118 6.42952 2.19598 5.44793 2.00635C5.12891 1.91153 4.58903 1.86413 4.09824 2.00635Z"
        fill="#AF916D"
      />
      <path
        d="M28.1717 19.5H16.957V7.12643H28.1717V19.5ZM18.4294 18.0777H26.6994V8.54868H18.4294V18.0777Z"
        fill="#AF916D"
      />
      <path
        d="M32.8833 5.56198H31.4109V19.3341H32.8833V5.56198Z"
        fill="#AF916D"
      />
      <path d="M1.47239 1.29523H0V19.3341H1.47239V1.29523Z" fill="#AF916D" />
      <path
        d="M17.0799 13.8584H1.448V15.2807H17.0799V13.8584Z"
        fill="#AF916D"
      />
      <path
        d="M17.325 8.80945H0.981445V10.2317H17.325V8.80945Z"
        fill="#AF916D"
      />
      <path
        d="M31.9017 13.8584H27.7791V15.2807H31.9017V13.8584Z"
        fill="#AF916D"
      />
      <path
        d="M32.1471 8.80945H27.3127V10.2317H32.1471V8.80945Z"
        fill="#AF916D"
      />
    </svg>
  );
};

export const Baths = () => {
  return (
    <svg
      // width="2.29vw"
      // height="1.67vw"
      className="baths-icon"
      viewBox="0 0 33 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3233 11.7032H8.83667V4.25824C8.83667 2.10638 10.5959 0.375 12.7515 0.375C14.9071 0.375 16.6415 2.10638 16.6415 4.25824V4.9508H15.1549V4.25824C15.1549 2.92261 14.0647 1.85904 12.7515 1.85904C11.4383 1.85904 10.3481 2.94734 10.3481 4.25824V11.7032H10.3233Z"
        fill="#AF916D"
      />
      <path
        d="M18.6238 4.43138H12.925V5.91542H18.6238V4.43138Z"
        fill="#AF916D"
      />
      <path
        d="M26.6764 23.625H6.60685L6.40863 23.4519C2.89026 20.4591 0.883301 16.0811 0.883301 11.4806V10.7386H32.4V11.4806C32.4 16.0811 30.393 20.4343 26.8746 23.4271L26.6764 23.625ZM7.17672 22.141H26.1313C29.0055 19.5934 30.7151 16.0317 30.9133 12.2473H2.39471C2.59293 16.0317 4.30256 19.5934 7.17672 22.141Z"
        fill="#AF916D"
      />
    </svg>
  );
};
export const Area = () => {
  return (
    <svg
      // width="1.9vw"
      // height="1.9vw"
      className="area-icon"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.03265 11.1819H5.45776V5.29785H11.2122V6.90821H7.03265V11.1819Z"
        fill="#AF916D"
      />
      <path
        d="M12.8096 11.6196L7.28442 5.96999L6.17082 7.10867L11.696 12.7583L12.8096 11.6196Z"
        fill="#AF916D"
      />
      <path
        d="M22.6301 22.826H16.8757V21.2156H21.025V16.942H22.6301V22.826Z"
        fill="#AF916D"
      />
      <path
        d="M21.9476 20.9884L16.4224 15.3388L15.3088 16.4775L20.8339 22.1271L21.9476 20.9884Z"
        fill="#AF916D"
      />
      <path
        d="M22.6301 11.1819H21.025V6.90821H16.8757V5.29785H22.6301V11.1819Z"
        fill="#AF916D"
      />
      <path
        d="M21.9635 7.1319L20.8499 5.99321L15.3247 11.6428L16.4383 12.7815L21.9635 7.1319Z"
        fill="#AF916D"
      />
      <path
        d="M11.2122 22.826H5.45776V16.942H7.03265V21.2156H11.2122V22.826Z"
        fill="#AF916D"
      />
      <path
        d="M12.7796 16.4974L11.666 15.3587L6.14082 21.0084L7.25443 22.1471L12.7796 16.4974Z"
        fill="#AF916D"
      />
      <path
        d="M2.21708 2.10811H26.0827V25.8919H2.21708V2.10811ZM0.399902 27.75H27.8999V0.25H0.399902V27.75Z"
        fill="#AF916D"
      />
    </svg>
  );
};

export const Delete = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999919 10.6667C0.999919 11.4 1.59992 12 2.33325 12H7.66659C8.39992 12 8.99992 11.4 8.99992 10.6667V2.66667H0.999919V10.6667ZM9.66659 0.666667H7.33325L6.66659 0H3.33325L2.66659 0.666667H0.333252V2H9.66659V0.666667Z"
        fill="#C2CFE0"
      />
    </svg>
  );
};

export const Loading = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#E5E5E5"
    >
      <g
        fill="none"
        fill-rule="evenodd"
        transform="translate(1 1)"
        stroke-width="2"
      >
        <circle cx="22" cy="22" r="6" stroke-opacity="0">
          <animate
            attributeName="r"
            begin="1.5s"
            dur="3s"
            values="6;22"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="1.5s"
            dur="3s"
            values="1;0"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-width"
            begin="1.5s"
            dur="3s"
            values="2;0"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="22" cy="22" r="6" stroke-opacity="0">
          <animate
            attributeName="r"
            begin="3s"
            dur="3s"
            values="6;22"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="3s"
            dur="3s"
            values="1;0"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-width"
            begin="3s"
            dur="3s"
            values="2;0"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="22" cy="22" r="8">
          <animate
            attributeName="r"
            begin="0s"
            dur="1.5s"
            values="6;1;2;3;4;5;6"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  );
};

export const EditIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      id="edit-icon"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 15.3663C0.4448 13.8399 0.888 12.3135 1.3328 10.7871C1.368 10.6655 1.4048 10.5439 1.4368 10.4223C1.488 10.2383 1.584 10.0895 1.7312 9.96628C1.8688 9.85268 1.9904 9.71668 2.1168 9.59028C4.2016 7.52468 6.2864 5.45909 8.3696 3.39189C8.4176 3.34389 8.4592 3.28949 8.4928 3.24789C8.776 3.53269 9.0416 3.79989 9.3376 4.09749C9.3056 4.11989 9.2432 4.15189 9.1952 4.19989C7.0352 6.35508 4.8768 8.51028 2.7216 10.6703C2.6448 10.7487 2.5808 10.8559 2.5504 10.9615C2.2912 11.8175 2.0448 12.6783 1.7888 13.5375C1.7568 13.6447 1.7728 13.7119 1.8544 13.7855C1.9872 13.9055 2.104 14.0431 2.2384 14.1615C2.2848 14.2015 2.3728 14.2319 2.4288 14.2175C3.2896 13.9711 4.1488 13.7199 5.0064 13.4607C5.08 13.4383 5.1488 13.3759 5.2064 13.3167C7.3968 11.1327 9.584 8.94548 11.7712 6.75988C11.8128 6.71828 11.848 6.67188 11.872 6.64468C12.1824 6.95508 12.4832 7.25588 12.8128 7.58548C12.7984 7.59508 12.7408 7.61908 12.7008 7.65908C10.544 9.79508 8.384 11.9263 6.2384 14.0735C5.9392 14.3743 5.6144 14.5599 5.2128 14.6735C3.7888 15.0767 2.3712 15.5023 0.9504 15.9199C0.856 15.9471 0.76 15.9727 0.6656 15.9999H0.5376C0.4688 15.9695 0.3984 15.9423 0.3296 15.9103C0.1376 15.8207 0.072 15.6383 0 15.4607V15.3663ZM4.3024 12.7151C4.2608 12.4495 4.216 12.2063 4.1872 11.9631C4.1728 11.8479 4.1264 11.8015 4.0144 11.7807C3.7408 11.7279 3.4704 11.6591 3.2176 11.6031C5.4864 9.32948 7.7552 7.05428 10.0096 4.79349C10.3696 5.15349 10.7456 5.52789 11.1088 5.88949C8.8624 8.14228 6.5952 10.4159 4.3024 12.7151ZM10.8368 1.0255C11.0416 0.825499 11.2464 0.612699 11.4656 0.4143C12.0944 -0.152099 12.9136 -0.139299 13.5264 0.4591C14.2032 1.1183 14.8704 1.7855 15.5296 2.46229C16.1536 3.10069 16.144 3.95509 15.5216 4.59189C15.3344 4.78389 15.1408 4.97109 14.9616 5.14709C13.592 3.77909 12.224 2.41269 10.8368 1.0255ZM9.168 2.53589C9.4784 2.2447 9.7968 1.9455 10.0992 1.6607C11.5328 3.09429 12.9584 4.52149 14.3968 5.95989C14.0976 6.23989 13.776 6.54228 13.4656 6.83348L9.168 2.53589Z"
        fill="#C2CFE0"
      />
    </svg>
  );
};

export const ViewIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 576 512"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="eye"
      class="svg-inline--fa fa-eye fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#C2CFE0"
        d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
      ></path>
    </svg>
  );
};
