import React from "react";
import styled from "styled-components";
import illustration from "../images/propmanag3.jpg";
import colors from "../util/colors";

const PmSection = () => {
  return (
    <StyledSection id="about">
      <img className="illustration" src={illustration} alt="" />
      <div className="text">
        <h1 className="title">Property Management</h1>
        <p>
          Established in 2021, Equity Egypt is a financial firm. We aim to create value for our
          clients through providing financial solutions in property management.
          We aim at catering the needs of the real estate market which contains
          around 40% – 50% of the client’s investment portfolios. Given the
          market size, expert management is currently at a high demand towards
          handling real estate assets and maximizing project returns and value.
          The firm manages the real estate portfolios of several prominent
          clients to make sure their assets are producing maximum returns.
        </p>
      </div>
    </StyledSection>
  );
};

const StyledSection = styled.div`
  display: flex;
  align-items: center;
  padding: 3.8vw 7.6vw 3.8vw 8.2vw;
  color: ${colors.white};
  min-height: 32.4vw;
  @media (max-width: 768px) {
    min-height: 64.2vw;
    width: 85.5vw;
    margin: auto;
    padding: 0;
  }
  .illustration {
    width: 30.6vw;
    height: 100%;
    height: 24.7vw;
    background: url(${illustration});
    background-size: cover;
    margin-right: 4.17vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .text {
    width: 49.4vw;
    @media (max-width: 768px) {
      width: 85.3vw;
    }
    .title {
      margin-top: -1.79vw; // Aligning title with image
      color: ${colors.brown};
      font-size: 3.5vw;
      font-weight: 700;
      line-height: 6.5vw;
      @media (max-width: 768px) {
        margin-top: 0;
        margin-bottom: 6.7vw;
        font-size: 6.7vw;
        line-height: 12.5vw;
      }
    }
    p {
      font-size: 1.5vw;
      color: ${colors.lightGrey};
      line-height: 2vw;
      @media (max-width: 768px) {
        font-size: 3.2vw;
        line-height: 6vw;
      }
    }
  }
`;
export default PmSection;

{
  /* <p>
        By following our mother company’s commitment of driving value and
        providing financial solutions, we have extended our offerings to an
        additional service – Property Management. We aim at catering the needs
        of the real estate market which contains around 40% – 50% of the
        client’s investment portfolios.
      </p>
      <p>
        Given the market size, expert management is currently at a high demand
        towards handling real estate assets and maximizing project returns and
        value.
      </p>
      <p>
        We will tap into the largest market available and provide our clients
        with a deep analysis of the real-estate market which will assist in
        optimizing asset value and setting the finest price.
      </p>
      <p>
        Our services will include various solutions and recommendations for
        our clients’ assets. This however, will be performed hand in hand with
        an accredited valuation service from our end. The services we provide
        encompass all legal and taxation documents which will be integrated
        into an accounting digital platform.
      </p> */
}
