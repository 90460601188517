import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { logout } from "../util/api/authFunctions";
import colors from "../util/colors";
import { formatText } from "../util/utilFunctions";
const Cta = () => {
  const location = useLocation();
  const userData = useSelector((state) => state.user);
  return (
    <StyledCta className="cta">
      <p className="separator">|</p>
      {userData.UserID ? (
        <>
          {(location.pathname !== "/users" ||
            location.pathname !== "/lisings") && (
            <>
              <Link to="/dashboard">
                <p className="user-name">
                  {formatText(userData.name?.split(" ")[0], " ")}
                </p>
              </Link>
              <p className="dash">-</p>
            </>
          )}
          <p onClick={() => logout()} className="logout-button">
            LOGOUT
          </p>
        </>
      ) : (
        <>
          <Link to="/login">SIGN IN</Link>
        </>
      )}
      <div className="mobile-border"></div>
    </StyledCta>
  );
};

const StyledCta = styled.div`
  color: ${colors.brown};
  font-size: 1.25vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .mobile-border {
    display: none;
    @media (max-width: 768px) {
      display: unset;
      width: 100%;
      height: 0.02vw;
      background: ${colors.brown};
      margin-bottom: 1vw;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    font-size: 4vw;
    align-items: flex-start;
    .dash,
    .separator {
      display: none;
    }
  }
  * {
    &:not(:first-child) {
      margin-left: 0.5vw;
    }
  }
  .logout-button {
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: ${colors.brown};
    &:first-of-type {
      margin-left: 1vw;
    }
  }
`;

export default Cta;
