import React from "react";
import styled from "styled-components";
import bg from "../images/bg.jpg";
import bgmobile from "../images/bgmobile.jpg";
import BorderLine from "./BorderLine";

const Intro = () => {
  return (
    <StyledIntro>
      <BorderLine />
    </StyledIntro>
  );
};

const StyledIntro = styled.div`
  /* position: absolute; */
  position: relative;
  top: 0;
  z-index: -1;
  min-height: 100vh;
  width: 100%;
  background: url(${bg});
  background-size: 100vw auto;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    min-height: 100vh;
    background: url(${bgmobile});
    background-repeat: no-repeat;
    background-size: 100vw auto;
  }
`;
export default Intro;
