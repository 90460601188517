import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Amount from "./Amount";
const NumberCard = ({ label, number, isAmount, isDefault }) => {
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  return (
    <StyledCard className="number-card">
      <p className="number-card-label">{label}</p>
      {isAmount ? (
        <div className="amount-div">
          <Amount
            className={`number-card-number ${isDefault ? "black" : ""}`}
            log={isMobile}
          >
            {number}
          </Amount>
          {isMobile && <span> EGP</span>}
        </div>
      ) : (
        <p className="number-card-number">{number}</p>
      )}
    </StyledCard>
  );
};
const StyledCard = styled.div`
  * {
    font-family: "Mulish", sans-serif;
  }
  min-width: 19.1vw;
  height: 9.65vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 0.6vw;
  border: solid 0.07vw #ebe8df;
  padding: 1.7vw 0.2vw;
  @media (max-width: 768px) {
    width: 44vw;
    height: 20.533vw;
    padding: 4.267vw;
    align-items: start;
  }
  .number-card-label {
    color: ${colors.goldenGrey};
    font-size: 1.3vw;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 3.733vw;
      font-weight: 600;
      line-height: 4.8vw;
    }
  }
  .number-card-number {
    font-size: 2vw;
    font-weight: 700;
    &.black {
      color: black;
    }
    @media (max-width: 768px) {
      font-size: 4.8vw;
      line-height: 6.133vw;
    }
  }
  .amount-div {
    display: flex;
    align-items: flex-end;
    gap : 1vw;
    span {
      font-size: 3.2vw;
      font-weight: 500;
      line-height: 4.8vw;
      font-weight: 500;
    }
  }
`;
export default NumberCard;
