import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
const Modal = ({ children, history, urlKey, ...props }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // Tracking whether the modal is visible or not
  const [visible, setVisible] = useState(() => {
    if (params.get(urlKey) === "true") {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    setVisible(() => {
      if (params.get(urlKey) === "true") {
        return true;
      } else {
        return false;
      }
    });
  }, [location, params, urlKey]);

  const escapeClick = useCallback(
    (e) => {
      if (history && e.keyCode === 27) {
        history.replace(history.location.pathname);
      }
    },
    [history]
  );
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", escapeClick, false);
    }
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", escapeClick, false);
    };
  }, [escapeClick, visible]);

  return (
    visible &&
    createPortal(
      <StyledModal
        id="modal-overlay"
        onClick={(e) => {
          if (e.target.id === "modal-overlay" && history)
            history.replace(history.location.pathname);
        }}
        urlKey={urlKey}
      >
        {children}
      </StyledModal>,
      document.getElementById("modal_root")
    )
  );
};
const StyledModal = styled.div`
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes attachemnts-fade-in {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  position: fixed;
  display: flex;
  justify-content: ${(props) =>
    props.urlKey == "all-attachments" ? "flex-end" : "center"};
  align-items: ${(props) =>
    props.urlKey == "all-attachments" ? "start" : "center"};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  animation: ${(props) =>
    props.urlKey == "all-attachments"
      ? "attachemnts-fade-in 0.6s"
      : "fade-in 0.6s"};
`;

export default Modal;
