import React from "react";
import styled from "styled-components";

const Amount = ({
  children: number,
  log,
  table,
  debit,
  className,
  ...rest
}) => {
  const formatter = new Intl.NumberFormat("en");
  return (
    <StyledAmount
      className={`${className} ${number >= 0 ? "positive" : "negative"}`}
      {...rest}
    >
      {!log && (rest.currency || "EGP ")}
      {log && number > 0 && !table && "+"}
      {number < 0 && debit
        ? formatter.format(parseInt(number * -1))
        : formatter.format(parseInt(number))}
    </StyledAmount>
  );
};

const StyledAmount = styled.p`
  color: black;
  display: inline;
  &.positive {
    color: #7fb089;
  }
  &.negative {
    color: #b06d77;
  }
`;
export default Amount;
