import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import placeholder from "../../images/placeholder.png";
import { statsItems } from "../../util/api/data";
import colors from "../../util/colors";
import { formatText } from "../../util/utilFunctions";
import Amount from "../assets/Amount";
import Label from "../assets/Label";
import { LogItem } from "./DashboardPropertyCard";

const TableFamilyMember = ({ member }) => {
  return (
    <StyledRow>
      <td className="member-name">
        <img src={placeholder} alt="" className="desktop-only" />
        <div className="name-container">
          <p className="user-name">{formatText(member.name)}</p>
          <p className="last-update hidden">
            Added {moment.utc(member.created_at).fromNow()}
            {/* {moment(new Date(member.created_at)).add(2, "hours").fromNow()} */}
          </p>
        </div>
      </td>
      <td className="balance-cell">
        <Label
          className="amount-label"
          backgroundColor={member.balance < 0 ? "red" : ""}
        >
          <Amount id="balance-amount">{member.balance}</Amount>
        </Label>
        {/* <Label className="amount-label">£E {member.balance}</Label> */}
        <p className="last-update hidden">Updated on 24.05.2019</p>
      </td>
      <td className="units-cell">
        <p>{member.units_count}</p>
        <p className="last-update hidden">Added on 27.09.2019</p>
        <Link to={`/all-properties?UserID=${member.UserID}`} className="button">
          View Properties
        </Link>
      </td>
      <td className="summary-cell">
        <div className="log-grid">
          {statsItems.map((item) => (
            <LogItem
              key={item}
              className="log-item"
              itemName={item}
              itemAmount={member.services_stats[item] || 0}
            />
          ))}
          {/* {Object.keys(member.services_stats).map((item) => (
            <LogItem
              key={item}
              className="log-item"
              itemName={item}
              itemAmount={member.services_stats[item]}
            />
          ))} */}
        </div>
        <Link
          to={`/all-transactions?UserID=${member.UserID}`}
          className="button viewtranscation-button"
        >
          View Transations
        </Link>
      </td>
    </StyledRow>
  );
};
const StyledRow = styled.tr`
  min-height: 11.8vw;
  display: flex;
  width: 100%;
  padding: 1.67vw 2.15vw;
  &:not(:last-child) {
    border-bottom: 0.07vw solid #ebe8df;
    @media (max-width: 768px) {
      border-bottom: 0.21vw solid #ebe8df;
    }
  }
  @media (max-width: 768px) {
    min-height: 35.4vw;
    padding: 5vw 6.45vw;
  }

  td {
    text-align: left;
    height: 100%;
    flex: 1;

    &.member-name {
      flex: 2;
      @media (max-width: 768px) {
        flex: 1.5;
      }

      display: flex;
      img {
        width: 3.06vw;
        height: 3.06vw;
        border-radius: 50%;
        margin-right: 1.67vw;
        @media (max-width: 768px) {
          width: 9.18vw;
          height: 9.18vw;
          margin-right: 5vw;
        }
      }
      .name-container {
        .user-name {
          font-weight: 600;
        }
      }
    }
    &.balance-cell {
      display: flex;
      /* justify-content: center; */
      .amount-label {
        width: fit-content;
        #balance-amount {
          color: white;
        }
      }
    }
    &.summary-cell {
      flex: 2.5;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
      //  flex : 3;
      }
      .log-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-row-gap: 0.8vw;
        grid-column-gap: 2.08vw;
        @media (max-width: 768px) {
          grid-row-gap: 1.2vw;
          grid-column-gap: 6.24vw;
        }
        .log-item {
          height: 1.5vw;
          border: none;
          padding: 0;
          @media (max-width: 768px) {
            // min-height: 4.8vw;
            // max-height : 4.267vw; 
            height : auto;
            font-size : 3.2vw;
            // line-height : 4.267vw;
          }
        }
      }
      .button {
        align-self: flex-end;
      }
    }
  }

  .last-update {
    font-size: 0.833vw;
    color: #c5c7cd;
    margin-top: 0.35vw;
    &.hidden {
      display: none;
    }
    @media (max-width: 768px) {
      font-size: 2.667vw;
      margin-top: 1.05vw;
    }
  }
  .button {
    display: flex;
    align-items: center;
    text-decoration: none;
    min-width: 9.31vw;
    width: fit-content;
    height: 2.64vw;
    border-radius: 0.56vw;
    padding: 0vw 0.9vw;
    outline: none;
    font-weight: 600;
    border: 0.07vw solid ${colors.brown};
    color: ${colors.brown};
    background: white;
    font-size: 0.97vw;
    margin-top: 1vw;
    cursor: pointer;
    @media (max-width: 768px) {
      width: 35vw;
      height: 7.92vw;
      border-radius: 1.68vw;
      padding: 0vw 2vw;
      border: 0.21vw solid ${colors.brown};
      font-size: 3.733vw;
      line-height: 4.8vw;
      margin-top: 3vw;
    }
  }
  .viewtranscation-button{
    @media (max-width: 768px) {
      width: 38vw;
    }
  }
`;

export default TableFamilyMember;
