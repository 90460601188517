import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import colors from "../../../util/colors";
import Modal from "../../assets/Modal";
import { ReactComponent as Close } from "../../../images/close.svg";
import { formatText } from "../../../util/utilFunctions";
import history from "../../../util/api/history";
import { useLocation } from "react-router-dom";
import { axiosAuth } from "../../../util/api/integration";

const AddInstallment = ({ pId }) => {
  const [singleDetails, setSingleDetails] = useState({
    installment_type: "installment",
    status: "paid",
  });
  const [bulkDetails, setBulkDetails] = useState({});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  const [installment_id, setInstallment_id] = useState();
  const [isPastInstallment, setIsPastInstallment] = useState(false);

  const status = {
    1: "paid",
    2: "overdue",
    3: "upcoming",
  };

  const types = {
    1: "maintenance",
    2: "installment",
  };

  useEffect(() => {
    setInstallment_id(location.state?.id);
    // setIsPastInstallment(location.state?.status == 1);
  }, [location]);

  useEffect(() => {
    if (installment_id) {
      const getData = async () => {
        try {
          const res = await axiosAuth.get(`/installment/${installment_id}`);
          console.log(res);
          setSingleDetails({
            ...res.data.installment,
            status: status[res.data.installment.status],
            installment_type: types[res.data.installment.installment_type],
          });
        } catch (e) {
          console.log(e);
        }
      };
      getData();
    }
  }, [installment_id]);

  const single_installment_fields = [
    { name: "installment_due_date", type: "date" },
    {
      name: "installment_type",
      type: "select",
      options: [
        { name: "Installment", value: "installment" },
        { name: "Maintenance Deposit", value: "maintenance" },
      ],
    },
    { name: "percentage", type: "number", unit: "%" },
    { name: "installment_amount", type: "number", unit: "EGP" },
    {
      name: "status",
      type: "select",
      options: [
        { name: "Paid", value: "paid" },
        { name: "Overdue ", value: "overdue" },
        { name: "Upcoming", value: "upcoming" },
      ],
    },
    { name: "paid_date", type: "date", required: false },
  ];

  const bulk_installment_fields = [
    { name: "start_date", type: "date" },
    { name: "down_payment", type: "number", unit: "EGP" },
    { name: "amount", type: "number", unit: "EGP" },
    {
      name: "duration",
      type: "number",
      unit: "month(s)",
    },
    { name: "number_of_installments", type: "number" },
  ];

  const fields =
    type == "bulk" ? bulk_installment_fields : single_installment_fields;

  const manageSingleInstallment = async (e) => {
    e.preventDefault();
    if (installment_id) {
      try {
        const res = await axiosAuth.put(`/installment/${installment_id}`, {
          ...singleDetails,
          property_id: pId,
          installment_amount: +singleDetails.installment_amount,
          percentage: +singleDetails.percentage,
          paid_date: singleDetails.paid_date || null,
        });
        console.log(res);
        alert("Installment Updated successfully.");
        history.replace(history.location.pathname);
      } catch (e) {
        alert(e.response?.data?.message || "Something went wrong!");
      }
    } else {
      try {
        const res = await axiosAuth.post(`/installment`, {
          ...singleDetails,
          property_id: pId,
          installment_amount: +singleDetails.installment_amount,
          percentage: +singleDetails.percentage,
          paid_date: singleDetails.paid_date || null,
        });
        console.log(res);
        alert("Single Installment Added successfully.");
        history.replace(history.location.pathname);
      } catch (e) {
        alert(e.response?.data?.message || "Something went wrong!");
      }
    }
  };

  const addBulkInstallment = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosAuth.post(`/installments`, {
        property_id: pId,
        unit_value: +bulkDetails.amount,
        downpayment: +bulkDetails.down_payment,
        num_installments: +bulkDetails.number_of_installments,
        first_due_date: bulkDetails.start_date,
        installment_period: +bulkDetails.duration,
      });
      console.log(res);
      alert("Bulk Installment Added successfully.");
      history.replace(history.location.pathname);
    } catch (e) {
      alert(e.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <Modal urlKey={"manage-installment"} history={history}>
      <StyledModal>
        <div className="header">
          {installment_id ? (
            <p className="title">Edit Installment</p>
          ) : (
            <p className="title">
              {type == "bulk"
                ? "Add Bulk Installment"
                : "Add Single Installment"}
            </p>
          )}
          <Close
            onClick={() => {
              history.replace(location.pathname);
            }}
          />
        </div>

        {isPastInstallment && (
          <p className="no-edit-text">You Can’t Edit Past Installments</p>
        )}
        <form
          id="listing"
          className="listing-form"
          onSubmit={
            type == "bulk" ? addBulkInstallment : manageSingleInstallment
          }
        >
          {fields.map((field) =>
            field.type === "select" ? (
              <div className="input-div" key={field.name}>
                <label htmlFor={field.name}>
                  {field.displayName || formatText(field.name)}
                </label>
                <select
                  required
                  className="input-global"
                  disabled={isPastInstallment}
                  value={
                    type == "bulk"
                      ? bulkDetails[field.name]
                      : singleDetails[field.name]
                  }
                  onChange={(e) => {
                    if (type == "bulk") {
                      setBulkDetails({
                        ...bulkDetails,
                        [field.name]: e.target.value,
                      });
                    } else {
                      setSingleDetails({
                        ...singleDetails,
                        [field.name]: e.target.value,
                      });
                    }
                  }}
                  name={field.name}
                  id={field.name}
                >
                  {field.options &&
                    field.options.map((o) => (
                      <option value={o.value}>{o.name}</option>
                    ))}
                </select>
              </div>
            ) : (
              <div className="input-div" key={field.name}>
                {field.unit && <p className="unit">{field.unit}</p>}

                <label htmlFor={field.name}>
                  {field.displayName || formatText(field.name)}
                </label>
                <input
                  required={field.required == false ? false : true}
                  value={
                    type == "bulk"
                      ? bulkDetails[field.name]
                      : singleDetails[field.name]
                  }
                  disabled={isPastInstallment}
                  className="input-global"
                  onChange={(e) => {
                    if (type == "bulk") {
                      setBulkDetails({
                        ...bulkDetails,
                        [field.name]: e.target.value,
                      });
                    } else {
                      setSingleDetails({
                        ...singleDetails,
                        [field.name]: e.target.value,
                      });
                    }
                  }}
                  type={field.type}
                  key={field.name}
                />
              </div>
            )
          )}
          {!isPastInstallment && (
            <>
              <div></div>
              {type == "bulk" && <div></div>}
              <div className="actions-div">
                <button
                  className="cancel-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    history.replace(location.pathname);
                  }}
                >
                  Cancel
                </button>
                <button className="save-btn" type="submit">
                  {!installment_id ? "Add Installment" : "Save Changes"}
                </button>
              </div>
            </>
          )}
        </form>
      </StyledModal>
    </Modal>
  );
};
const StyledModal = styled.div`
  width: 66.806vw;
  padding: 1.667vw;
  padding-bottom: 2.222vw;
  border-radius: 0.347vw;
  border: 0.069vw solid #dcdcdc;
  background: #fff;
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      color: var(--black, #010101);
      font-family: Mulish;
      font-size: 1.389vw;
      line-height: 1.736;
      font-style: normal;
      font-weight: 800;
      text-transform: capitalize;

      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 25px;
      }
    }
    svg {
      width: 1.667vw;
      height: 1.667vw;
      cursor: pointer;
      @media (min-width: 1440px) {
        width: 24px;
        height: 24px;
      }
    }
  }
  .no-edit-text {
    color: var(--red, #b06d77);
    font-family: Mulish;
    font-size: 0.833vw;
    line-height: 1.042vw;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 0.833vw;
    @media (min-width: 1440px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .listing-form {
    margin-top: 1.667vw;
    // padding-right: 3vw;
    gap: 1.667vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    .input-div {
      position: relative;
      display: flex;
      flex-direction: column;
      &:not(:last-of-type) {
        // margin-bottom: 1vw;
        @media (max-width: 768px) {
          margin-bottom: 4vw;
        }
      }

      .unit {
        margin: 0;
        position: absolute;
        top: 2.847vw;
        right: 1.111vw;
        color: var(--black, #010101);
        font-family: Mulish;
        font-size: 0.972vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25vw;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
      input,
      select {
        border-radius: 0.347vw;
        border: 0.069vw solid var(--stroke, #ebe8df);
        background: #fbfbfd;
        width: 30.903vw;
        height: 3.472vw;
        padding: 0 1.111vw;
        color: var(--black, #010101);
        font-family: Mulish;
        font-size: 0.972vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      label {
        color: #9e9e9e;
        font-size: 0.764vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.11px;
        margin-bottom: 0.278vw;
        @media (max-width: 768px) {
          font-size: 2.9vw;
        }
      }
    }
    .upload-div {
      display: flex;
      flex-direction: column;
      justify-content: end;
    }
    p {
      color: #9e9e9e;
      font-size: 0.764vw;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.11px;
      margin-bottom: 0.278vw;
    }
    .upload-btn {
      width: 38.819vw;
      height: 3.472vw;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.347vw;
      border: 0.069vw solid var(--brown, #af916d);
      background: #fff;
      color: var(--brown, #af916d);
      text-align: center;
      font-family: Mulish;
      font-size: 0.972vw;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
    }
    button {
      background: ${colors.brown};
      margin-top: 5.35vw;
      margin-bottom: 0.76vw;
      &.disabled-button {
        background: ${colors.lightGrey};
      }
      @media (max-width: 768px) {
        margin-top: 29.3vw;
      }
    }
    a {
      align-self: flex-end;
    }
  }
  .actions-div {
    margin-top: 1.667vw;
    display: flex;
    gap: 1.667vw;
    align-items: center;
    justify-content: end;
    button {
      outline: none;
      width: 9.306vw;
      height: 2.778vw;
      text-align: center;
      font-family: Mulish;
      font-size: 1.111vw;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 0.278vw;
      border: 0.069vw solid var(--brown, #af916d);
      cursor: pointer;
      margin: 0;
    }
    .cancel-btn {
      background: #fff;
      color: var(--brown, #af916d);
    }
    .save-btn {
      background: var(--brown, #af916d);
      color: var(--white, #fff);
    }
  }
`;

export default AddInstallment;
