import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import { formatText, getUnitStatus } from "../../util/utilFunctions";
import Amount from "../assets/Amount";
import Button from "../assets/Button";
import Label from "../assets/Label";
import unitplaceholder from "../../images/unitplaceholder.png";

const DashboardPropertyCard = ({ ...props }) => {
  const property = { ...props.property };
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    console.log("property", property);
  }, [property]);

  return (
    <StyledCard
      ref={props.MyRef}
      statsLength={
        property.property_stats["Rent"]
          ? Object.keys(property.property_stats).length
          : Object.keys(property.property_stats).length + 1
      }
    >
      <div className="backdrop">
        <Button
          label="View details"
          link={`/dashboard/properties/${property.unit_code}`}
        />
      </div>
      {/* {property.images[0]} */}
      <div className="property-item-details">
        <img
          src={
            property.images?.length > 0
              ? property.images[0].url
              : unitplaceholder
          }
          className="property-card-image"
        />

        <div className="card-header">
          <div className="row">
            <p className="p-name">{property.name1}</p>

            <Amount className="total-balance">
              {property.ending_balance || 0}
            </Amount>
          </div>
          {property.address.street && property.address.area && (
            <p className="address">
              {`${property.address.street}, ${property.address.area} ${
                property.address.building_no &&
                `, Building ${property.address.building_no}`
              }
               ${
                 property.address.floor_no &&
                 ` , Floor ${property.address.floor_no}`
               }`}
            </p>
          )}

          <p className="owner">
            By :<span> {formatText(property.owner_name, " ")}</span>
          </p>
        </div>

        <div className="status">
          <Label>
            {getUnitStatus(property.rent_state, property.sale_state)}
          </Label>
        </div>
      </div>
      {Object.keys(property.property_stats).length > 0 ? (
        <div className="property-stats">
          <div className="stats-item">
            <p className="key">Rent</p>
            <Amount className="value" log>
              {property.property_stats["Rent"] || 0}
            </Amount>
          </div>

          {Object.keys(property.property_stats).map(
            (item) =>
              item !== "Rent" && (
                <div className="stats-item">
                  <p className="key">
                    {item == " Engineering & Construction Works"
                      ? "Eng. Consultations"
                      : item}
                  </p>
                  <Amount className="value" log>
                    {property.property_stats[item]}
                  </Amount>
                </div>
              )
          )}
        </div>
      ) : (
        <div className="blank-text">No records to show.</div>
      )}
    </StyledCard>
  );
};

export const LogItem = ({ itemName, itemAmount, ...props }) => {
  return (
    <StyledItem {...props}>
      <p className="item-name">{itemName}</p>
      <Amount className="log-amount" log>
        {itemAmount}
      </Amount>
    </StyledItem>
  );
};
const StyledCard = styled.div`
  width: 26.181vw;
  position: relative;
  overflow-y: hidden;
  .blank-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    font-size: 1vw;
    // border-top: solid 0.07vw #dfe0eb;
    min-height: 7vw;
    @media (max-width: 768px) {
      font-size: 3vw;
      border-top: solid 0.21vw #dfe0eb;
      min-height: 20vw;
    }
  }
  .backdrop {
    transition: all 0.2s ease-out;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    /* opacity: 0.9; */
    position: absolute;
    top: -100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      padding: 1vw;
      background: ${colors.brown};
      opacity: 0;
      transition: opacity 0.2s ease-out 0.2s;
    }
  }
  // min-height: 38.542vw;
  border: solid 0.07vw #ebe8df;
  background: white;
  border-radius: 0.56vw;
  @media (max-width: 768px) {
    width: 100%;
    // min-height: 86.7vw;
    border-radius: 2.13vw;
  }
  .property-item-details {
    position: relative;
    .property-card-image {
      width: 100%;
      height: 13.958vw;
      object-fit: cover;
      @media (max-width: 768px) {
        height: 53.6vw;
      }
    }
    .card-header {
      width: 100%;
      height: 6.806vw;
      padding: 0.972vw 1.111vw 1.111vw 1.111vw;
      border-bottom: 0.069vw solid #ebe8df;
      font-family: Mulish;
      font-size: 1.111vw;
      line-height: 1.389vw;
      font-style: normal;
      font-weight: 700;

      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media (max-width: 768px) {
        margin: 0;
        padding: 3.2vw 4.267vw;
        position: static;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 2.133vw;
      }
      .row {
        display: flex;
        justify-content: space-between;
      }

      .p-name {
        width: 15vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: Mulish;
        font-size: 1.111vw;
        font-style: normal;
        font-weight: 700;
        line-height: 1.389vw;

        @media (min-width: 1440px) {
          font-size: 16px;
          line-height: 20px;
        }

        @media (max-width: 768px) {
          font-size: 4.267vw;
          line-height: 5.333vw;
          color: #010101;
          font-weight: 700;
          width: auto;
          flex-basis: 100%;
          flex: 1;
        }
      }

      .owner {
        font-family: Mulish;
        font-size: 0.833vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25;
        margin-top: 0.556vw;
        @media (min-width: 1440px) {
          font-size: 12px;
          line-height: 18px;
          margin: 0;
        }

        span {
          // font-weight: 700;
          margin-left: 0.5vw;
        }
        @media (max-width: 768px) {
          color: #010101;
          font-size: 3.2vw;
          line-height: 4.8vw;
          font-weight: 400;
          margin: 0;
          width: 82.933vw;
        }
      }
      .address {
        font-family: Mulish;
        font-size: 0.972vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25;
        margin-top: 0.556vw;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (min-width: 1440px) {
          font-size: 12px;
          line-height: 18px;
          margin: 0;
        }
        @media (max-width: 768px) {
          color: #010101;
          font-size: 3.733vw;
          line-height: 4.8vw;
          margin: 0;
          width: 82.933vw;
        }
      }

      .total-balance {
        // color : #010101;
        font-family: Mulish;
        font-size: 1.111vw;
        line-height: 1.389vw;
        font-style: normal;
        font-weight: 700;

        @media (min-width: 1440px) {
          font-size: 16px;
          line-height: 20px;
        }

        @media (max-width: 768px) {
          font-size: 4.267vw;
          line-height: 5.333vw;
        }
      }
    }
    .status {
      position: absolute;
      top: 1.111vw;
      right: 1.111vw;
      font-size: 1vw;
      // padding: 1.25vw 2.36vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        font-size: 3.2vw;
        line-height: 4vw;
        padding: 0;
        top: 4.267vw;
        right: 4.267vw;
      }
    }
  }
  .property-stats {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0 0 0.278vw 0;

    @media (max-width: 768px) {
      margin-bottom: 0;
      padding: 0 0 2vw 0vw 0;
      // grid-template-columns: 1fr;
    }
    .stats-item {
      border-bottom: 0.069vw solid #ebe8df;
      padding: 0.833vw 0 1.111vw 1.111vw;
      @media (max-width: 768px) {
        border-bottom: 0.256vw solid #ebe8df;
        padding: 3.2vw 0 4.267vw 4.267vw;
      }

      .key {
        color: #010101;
        font-family: Mulish;
        font-size: 0.972vw;
        font-style: normal;
        font-weight: 600;
        line-height: 1.389vw;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 768px) {
          font-size: 3.733vw;
          line-height: 5.333vw;
        }
      }
      .value {
        font-family: Mulish;
        font-size: 0.972vw;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25vw;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 18px;
        }
        @media (max-width: 768px) {
          font-size: 3.733vw;
          line-height: 5.333vw;
        }
      }
    }
    .stats-item:nth-last-of-type(
        -n + ${(props) => (props.statsLength % 2 == 0 ? 2 : 1)}
      ) {
      border: 0;
    }
  }
  &:hover,
  &:active {
    .backdrop {
      z-index: 1000;
      top: 0;
      button {
        opacity: 1;
      }
    }
  }
`;

const StyledItem = styled.div`
  height: 4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1vw;
  font-weight: 600;
  /* padding: 0vw 1.53vw 0vw 2.36vw; */
  padding: 0vw 2.36vw 0vw 2.36vw;
  .item-name {
    width: 70%;
  }
  .log-amount {
    display: ${(props) => (props.noAmount ? "none" : "")};
  }
  @media (max-width: 768px) {
    height: 13.3vw;
    font-size: 2.73vw;
    /* padding: 0vw 5.19vw 0vw 8vw; */
    padding: 0vw 8vw 0vw 8vw;
  }
  border-top: solid 0.07vw #dfe0eb;
`;
export default DashboardPropertyCard;
