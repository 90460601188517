import React from "react";
import styled from "styled-components";
import colors from "../util/colors";

const ServiceCard = ({ service }) => {
  return (
    <StyledCard>
      <CardTitle>{service.cardTitle}</CardTitle>
      <img src={service.imageUrl} alt="service-logo" />
      <p className="description">{service.serviceDesc}</p>
    </StyledCard>
  );
};

const CardTitle = ({ children }) => {
  return (
    <StyledTitle>
      <p>{children}</p>
    </StyledTitle>
  );
};

const StyledCard = styled.div`
  background: ${colors.darkGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 13.125vw;
  border-radius: 0.35vw;
  min-height: 30.07vw;
  padding-top: 0.8125vw;
  padding-left: 1.08vw;
  padding-right: 1.08vw;
  padding-bottom: 2.7vw;
  &:not(:last-child) {
    margin-right: 2.8vw;
  }
  @media (max-width: 768px) {
    width: 69.6vw;
    height: 115.5vw;
    padding-left: 5.9vw;
    padding-right: 5.9vw;
  }
  img {
    margin-top: 2.03vw;
    width: 10.7vw;
    height: 10.7vw;
    object-fit: fill;
    @media (max-width: 768px) {
      margin-top: 12.3vw;
      width: 41.1vw;
      height: 41.1vw;
      object-fit: scale-down;
    }
  }
  /* .card-title {
    color: ${colors.brown};
    width: 14.8vw;
    height: 4.375vw;
    margin: 4.44vw auto 0 auto;
    font-size: 1.39vw;
    line-height: 1.94vw;
    font-weight: 700;
    @media (max-width: 768px) {
      width: 56.8vw;
      height: 16.8vw;
      font-size: 5.33vw;
      line-height: 7.5vw;
      margin-top: 14.4vw;
    }
  } */
  .description {
    font-size: 0.9vw;
    line-height: 1.11vw;
    margin-top: 0.55vw;
    color: ${colors.lightGrey};
    // text-align: justify;
    // word-wrap: break-word;
    // hyphens: auto;
    @media (max-width: 768px) {
      font-size: 3.5vw;
      line-height: 4.26vw;
      margin-top: 2.13vw;
      text-align: left;
    }
  }
`;

const StyledTitle = styled.div`
  height: 5.75vw;
  /* height: 10vw; */
  width: 100%;
  font-size: 1.18vw;
  font-weight: 400;
  color: white;
  border-bottom: 0.07vw solid ${colors.brown};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* word-spacing: 100vw; */
  @media (max-width: 768px) {
    font-size: 4.5vw;
    height: 22.1vw;
    padding-top: 1vw;
  }
`;

export default ServiceCard;
