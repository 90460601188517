import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../components/assets/Button";
import { Delete, ViewIcon } from "../components/assets/icons/icons";
import StyledPage from "../components/assets/StyledPage";
import history from "../util/api/history";
import { axiosAuth } from "../util/api/integration";
import colors from "../util/colors";
import Pagination from "../components/assets/Pagination";
import LoadingPage from "./LoadingPage";
import { Link } from "react-router-dom";
import { ReactComponent as ViewProperties } from "../images/propertyicon.svg";
import { ReactComponent as DotsIcon } from "../images/dots.svg";
import { ReactComponent as SyncIcon } from "../images/sync.svg";

const Users = () => {
  const [users, setUsers] = useState();
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res = await axiosAuth.get(
          `/users?page=${page}&per_page=${perPage}`
        );
        setUsers(res.data);
        setLoading(false);
      } catch (error) {
        alert(error.response?.data?.message || "Something went wrong!");
        setLoading(false);
      }
    };
    fetchUsers();
  }, [refresh, page, perPage]);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  const syncData = async () => {
    setLoading(true);
    try {
      const response = await axiosAuth.get("/integrator/all");
      alert(response.data.message);
      window.location.reload();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <StyledPage lightMode backgroundColor="#FCFAF7" no-padding>
      <SyncBtn>
        <button onClick={syncData}>Sync Data</button>
      </SyncBtn>
      {users && (
        <StyledTable>
          <div className="header">
            <h1 className="title fs30">Users</h1>
            <Button link="/register" label="+ Add User" className="add-btn" />
          </div>
          <table>
            <thead>
              <TableHeader className="hidden" />
            </thead>
            <tbody>
              {users.results.map((user) => (
                <User
                  key={user.UserID}
                  user={user}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  loading={loading}
                  setLoading={setLoading}
                />
              ))}
            </tbody>
          </table>
          <Pagination
            className="styled-pagination"
            data={users}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </StyledTable>
      )}
    </StyledPage>
  );
};

// Helper components
const User = ({ user, setRefresh, refresh, loading, setLoading, ...rest }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }
    console.log("menu is ", menuIsOpen);
    if (menuIsOpen) {
      console.log("menu", menuIsOpen);
      setMenuIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuIsOpen]);

  useEffect(() => {
    if (!localStorage.getItem("isAdmin")) history.push("/dashboard");
  }, []);

  const deleteHandler = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete user ?");
    if (confirmed) {
      try {
        const res = await axiosAuth.delete(`/users/${id}`);
        alert(`User is deleted successfully`);
        setRefresh(!refresh);
      } catch (error) {
        alert(error.response?.data?.message || "Something went wrong!");
      }
    }
  };

  const syncUserHandler = async (id) => {
    setLoading(true);
    try {
      const response = await axiosAuth.get(`/integrator/users/${id}`);
      alert(response.data.message);
      window.location.reload();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error.message ||"Something went wrong");
    }
  };

  const viewHandler = async (id) => {
    try {
      const response = await axiosAuth.get(`/viewUser/${id}`);
      console.log(response);
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      history.push("/dashboard");
    } catch (error) {
      alert(error.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <StyledUser {...rest}>
      <td>{user.UserID}</td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{user.mobile_no}</td>
      <td>{user.family_id}</td>
      <td>{user.user_type}</td>
      <td>{user.autogenerated_password}</td>
      <td className="actions-cell">
        <ViewIcon id="view-icon" onClick={() => viewHandler(user.UserID)} />
        <Link to={`/user-properties/${user.UserID}`}>
          <ViewProperties className="view-properties" />
        </Link>
        <DotsIcon
          className="dots-icon"
          onClick={() => {
            setMenuIsOpen(!menuIsOpen);
          }}
        />
      </td>
      {menuIsOpen && (
        <div
          className="actions-menu"
          onBlur={() => {
            setMenuIsOpen(false);
          }}
          ref={ref}
        >
          <button className="row" onClick={() => deleteHandler(user.UserID)}>
            <Delete className="btn-delete" />
            <p className="action-name">Delete</p>
          </button>
          <button
            className="row"
            onClick={() => {
              syncUserHandler(user.UserID);
            }}
          >
            <SyncIcon className="btn-sync" />
            <p className="action-name">Sync</p>
          </button>
        </div>
      )}
    </StyledUser>
  );
};

const TableHeader = () => {
  const tableHeaders = [
    "User ID",
    "Name",
    "E-mail",
    "Mobile",
    "Family ID",
    "User Type",
    "Generated Password",
    "Actions",
  ];

  return (
    <StyledHeading>
      {tableHeaders.map((header) => (
        <th key={header}>{header}</th>
      ))}
    </StyledHeading>
  );
};
//Styles
const StyledTable = styled.div`
  min-height: 26.1vw;
  border: solid 0.07vw #ebe8df;
  background: white;
  border-radius: 0.56vw;
  margin: auto;
  margin-top: 1vw;
  margin-bottom: 3vw;
  width: 80%;
  text-align: center;
  table {
    width: 100%;
  }
  .styled-pagination {
    /* display: none; */
    @media (max-width: 768px) {
      height: fit-content;
      display: flex;

      justify-content: center;
    }
  }
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      text-align: center;
      margin: 1vw 0;
    }
    a {
      text-decoration: none;
    }
    .add-btn {
      position: absolute;
      top: 1vw;
      right: 1vw;
      background: ${colors.brown};
      padding: 0 2vw;
      margin-left: 1vw;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    width: 80%;
    min-height: 86.7vw;
    border-radius: 2.13vw;
  }
`;
const StyledUser = styled.tr`
  position: relative;
  height: 4vw;
  width: 100%;
  gap: 1vw;
  font-size: 1vw;
  font-weight: 600;
  /* padding: 0vw 1.53vw 0vw 2.36vw; */
  padding: 0vw 2.36vw 0vw 2.36vw;
  /* p {
    flex: 1;
  } */

  .dots-icon {
    fill: #c2cfe0;
    path {
      fill: #c2cfe0;
    }
  }
  #view-icon,
  .dots-icon,
  .view-properties {
    width: 3vw;
    padding: 0 1vw;
    cursor: pointer;
    @media (max-width: 768px) {
      /* font-size: 1.5vw; */
      width: 3.5vw;
    }
  }

  .actions-menu {
    position: absolute;
    z-index: 1000;
    right: 1vw;
    top: 3vw;
    display: flex;
    flex-direction: column;
    gap: 1.667vw;
    background: #fff;
    border: 0.07vw solid #eee;
    padding: 1.111vw;
    .row {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 1.111vw;
      background: none;
      outline: none;
      border: none;
      .action-name {
        font-size: 1.111vw;
        line-height: 1.667vw;
        font-weight: 600;
        opacity: 0.8;
        // color : rgb(140, 140, 140);
      }
    }
  }
  .btn-delete,
  .btn-sync {
    width: 1.667vw;
    height: 1.667vw;
    opacity: 0.8;
    fill: rgb(140, 140, 140);
    path {
      fill: rgb(140, 140, 140);
    }
  }
  @media (max-width: 768px) {
    height: 13.3vw;
    font-size: 1.5vw;
    /* padding: 0vw 5.19vw 0vw 8vw; */
    padding: 0vw 8vw 0vw 8vw;
  }
  &:not(:last-child) {
    border-bottom: solid 0.07vw #dfe0eb;
  }
`;
const StyledHeading = styled(StyledUser)`
  opacity: 0.5;
`;

const SyncBtn = styled.div`
  /* width: 6vw; 
  displa*/
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  button {
    background: ${colors.brown};
    border: none;
    outline: none;
    border-radius: 0.56vw;
    color: white;
    font-weight: 600;
    cursor: pointer;
    padding: 0 2vw;
    height: 2.5vw;
    width: 10vw;
    font-size: 1vw;
  }
`;

export default Users;
