import React, { useState, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserData } from "../../util/api/authFunctions";
import store from "../../redux/store";
import { updateLoggedInUser } from "../../redux/actions/loginAction";
import history from "../../util/api/history";
import LoadingPage from "../../pages/LoadingPage";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    if (localStorage.getItem("token")) {
      console.log("Token found ..");
      getUserData()
        .then((data) => {
          store.dispatch(updateLoggedInUser(data));
          setLoading(false);
        })
        .catch((err) => {
          history.push("/login");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);
  const location = useLocation();
  const uid = useSelector((state) => state.user.UserID);
  return loading ? (
    <LoadingPage />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        uid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { referrer: location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
