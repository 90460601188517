import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styled from "styled-components";
import colors from "../util/colors";
import services from "../util/services";
import ServiceCard from "./ServiceCard";

const OurServices = () => {
  const [vw, setVw] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setVw(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setVw(window.innerWidth));
    };
  }, []);

  return (
    <StyledServices id="our-services">
      <h1>Services</h1>
      {vw > 786 && (
        <div className="services">
          {services.map((service) => (
            <ServiceCard
              key={service.cardTitle.toLowerCase().split(" ").join("-")}
              service={service}
            />
          ))}
        </div>
      )}

      {vw <= 786 && (
        <div className="services">
          <Carousel
            centerMode={true}
            width="91vw"
            showStatus={false}
            transitionTime={150}
            showArrows={false}
            showThumbs={false}
            autoPlay={false}
          >
            {services.map((service) => (
              <ServiceCard
                key={service.cardTitle.toLowerCase().split(" ").join("-")}
                service={service}
              />
            ))}
          </Carousel>
        </div>
      )}
    </StyledServices>
  );
};

const StyledServices = styled.div`
  /* height: 44.24vw; */
  margin-bottom: 6.25vw;

  @media (max-width: 768px) {
    height: 174.4vw;
  }
  h1 {
    color: ${colors.brown};
    font-size: 3.5vw;
    font-weight: 700;
    line-height: 6.5vw;
    margin-top: 0.6vw;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 6.7vw;
      line-height: 12.5vw;
      margin: auto;
      margin-bottom: 10.6vw;
      text-align: left;
      width: 85.33vw;
    }
  }
  .services {
    width: 76.9vw;
    margin: 2.57vw auto 0 auto;
    display: flex;

    /* justify-content: space-around; */
    @media (max-width: 768px) {
      width: 91.7vw;
      margin: 2.57vw 0 0 auto;
    }
  }
`;

export default OurServices;
