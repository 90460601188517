import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "../../assets/Modal";
import { ReactComponent as Close } from "../../../images/close.svg";
import history from "../../../util/api/history";
import pdf from "../../../images/pdf.png";
import logo from "../../../images/logo.png";
import { ReactComponent as Back } from "../../../images/arrow-left.svg";
const AllAttachments = ({ attachments }) => {
  return (
    <Modal urlKey={"all-attachments"} history={history}>
      <StyledModal>
        <div className="modal-header desktop-only">
          <p>Attachments</p>
          <Close
            onClick={() => {
              history.replace(history.location.pathname);
            }}
          />
        </div>

        <div className="mobile-header mobile-only">
          <Back
            onClick={() => {
              history.replace(history.location.pathname);
            }}
          />
          <img src={logo} alt="equity-logo" />
        </div>

        <div className="attachments-grid">
          {attachments.map((doc) => (
            <a className="attachment-link" key={doc.url} href={doc.url}>
              <div className="attachment">
                <img src={pdf} />
                <p>{doc.name}</p>
              </div>
            </a>
          ))}
        </div>
      </StyledModal>
    </Modal>
  );
};
const StyledModal = styled.div`
  background: #fff;
  width: 37.083vw;
  min-height: 100vh;
  padding: 1.667vw;

  @media (max-width: 768px) {
    width: 100vw;
    padding: 6.4vw 4.267vw;
  }
  .mobile-header {
    display: flex;
    align-items: center;
    gap: 27.733vw;
    margin-bottom: 6.4vw;
    img {
      width: 18.667vw;
      height: 12.267vw;
    }
    svg {
      cursor: pointer;
      width: 8.533vw;
      height: 8.533vw;
    }
  }
  .modal-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.222vw;
    p {
      color: #010101;
      font-family: Mulish;
      font-size: 2.083vw;
      line-height: 2.639vw;
      font-style: normal;
      font-weight: 800;

      @media (min-width: 1440px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
    svg {
      width: 2.222vw;
      height: 2.222vw;
      cursor: pointer;
      @media (min-width: 1440px) {
        width: 32px;
        height: 32px;
      }
    }
  }
  .attachments-grid {
    display: grid;
    gap: 0.833vw;
    grid-template-columns: repeat(3, 1fr);
    .attachment-link {
      text-decoration: none;
      color: black;
      .attachment {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 0.556vw;
        border: 0.069vw solid #ebe8df;
        background: #fff;
        width: 10.694vw;
        height: 9.931vw;
        padding: 0.972vw;

        img {
          width: 3.681vw;
          height: 4.514vw;
        }
        p {
          text-align: center;
          color: #010101;
          font-family: Cairo;
          font-size: 0.833vw;
          line-height: 1.528vw;
          font-style: normal;
          font-weight: 600;
          @media (min-width: 1440px) {
            font-size: 12px;
            line-height: 22px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 4.267vw;
      .attachment-link {
        .attachment {
          border-radius: 2.133vw;
          border: 0.267vw solid #ebe8df;
          width: 43.467vw;
          height: 38.133vw;
          padding: 6.4vw;

          img {
            width: 14.133vw;
            height: 17.333vw;
          }
          p {
            font-size: 3.2vw;
            line-height: 5.867vw;
          }
        }
      }
    }
  }
`;

export default AllAttachments;
