import React from "react";
import StyledPage from "../components/assets/StyledPage";
import DashboardDetails from "../components/dashboard/DashboardDetails";
const Dashboard = () => {
  return (
    <StyledPage scroll lightMode backgroundColor="#FCFAF7" no-padding>
      <DashboardDetails />
    </StyledPage>
  );
};

export default Dashboard;
