import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Button from "../assets/Button";

const BlankDashboard = () => {
  return (
    <StyledPanel>
      <h1 className="fs30">No properties to display</h1>
      <p className="fs16">You don't have properties to follow up on yet</p>
      <p className="fs16">Start adding properties to get started</p>
      <Button label="Contact Us" />
    </StyledPanel>
  );
};
const StyledPanel = styled.div`
  width: 83.6vw;
  height: 37.7vw;
  background: white;
  margin: 0vw auto 8.3vw auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.35vw;
  @media (max-width: 768px) {
    width: 80vw;
    height: 129vw;
    margin-top: 38.4vw;
    border-radius: 1.3vw;
    justify-content: flex-start;
    padding: 16.8vw 6.67vw 10.67vw 6.67vw;
    margin-bottom: 30.4vw;
  }
  h1 {
    margin-bottom: 1.7vw;
    color: ${colors.brown};
    @media (max-width: 768px) {
      margin-bottom: 22.7vw;
      font-size: 5.9vw;
    }
  }
  button {
    width: 28.47vw;
    background: ${colors.brown};
    margin-top: 10.35vw;
    @media (max-width: 768px) {
      width: 67.7vw;
      margin-top: 36.8vw;
    }
  }
  p {
    line-height: 1.3vw;
    @media (max-width: 768px) {
      font-size: 4.3vw;
      line-height: 5.12vw;
      text-align: center;
    }
  }
`;

export default BlankDashboard;
