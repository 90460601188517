import React, { useEffect, useState } from "react";
import styled from "styled-components";
import image from "../../../images/docplaceholder.jpeg";
import { axiosAuth } from "../../../util/api/integration";
import colors from "../../../util/colors";
import { formatText, getUnitStatus } from "../../../util/utilFunctions";
import Amount from "../../assets/Amount";
import Label from "../../assets/Label";
import { ReactComponent as Document } from "../../../images/document.svg";

const properties_unit_values = {
  U000141: 16596000,
  U000166: 71910000,
  U000163: 17396000,
  U000165: 104770000,
  U000168: 105350000,
  U000164: 100910000,
  U000167: 6953000,
  U000143: 17002000,
};

const PropertyHeader = ({ unit_code, data }) => {
  return (
    <StyledHeader className="detailed-property-header">
      {data && (
        <div className="container">
          <div className="property-summary-card">
            <h1>Property Details</h1>
            <div className="property-details">
              <div className="details-item">
                <p>Owner </p>
                <p>{data.owner_name}</p>
                {/* <p>{formatText(data?.owner_name, " ")}</p> */}
              </div>
              <div className="details-item">
                <p>Unit Name</p>
                <p>{data.name1}</p>
              </div>
              <div className="details-item">
                <p>Value</p>
                {data.value ? (
                  <p>
                    {data.value
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      .replace(/\.00$/, "")}
                  </p>
                ) : (
                  <p>{properties_unit_values[unit_code]?.toLocaleString()}</p>
                )}
              </div>
              {data.address.street && data.address.area && (
                <div className="details-item">
                  <p>Address </p>
                  <p className="address-text">
                    {`${data.address.street}, ${data.address.area} ${
                      data.address.building_no &&
                      `, Building ${data.address.building_no}`
                    }${
                      data.address.floor_no &&
                      ` , Floor ${data.address.floor_no}`
                    }`}
                  </p>
                </div>
              )}
            </div>

            <h1 className="taxation-details-header">Taxation</h1>
            <div className="taxation-details">
              <div className="details-item">
                <p>Annual Tax</p>
                <p>{data.annual_tax}</p>
              </div>
              <div className="details-item">
                <p>Tax State</p>
                <p>{data.tax_state}</p>
              </div>
              <div className="details-item">
                <p>Last Settlement Date</p>
                <p>{data.last_settlement_date}</p>
              </div>
            </div>
            <h1 className="rental-dets-header">Rental Details</h1>
            <div className="retal-details">
              <div className="details-item">
                <p>Status </p>
                <p>{getUnitStatus(data.rent_state, data.sale_state)}</p>
              </div>
              <div className="details-item">
                <p>Tenant Name </p>
                <p>{data.tenant_name2}</p>
              </div>
              <div className="details-item">
                <p>Amount</p>
                <p>EGP {data.rent_amount}</p>
              </div>
              <div className="details-item">
                <p>Type</p>
                <p>{data.rent_type}</p>
              </div>
              <div className="details-item">
                <p>From</p>
                <p>{data.rent_startdate}</p>
              </div>
              <div className="details-item">
                <p>To</p>
                <p>{data.rent_enddate}</p>
              </div>
            </div>
            <div className="details-item balance-item">
              <p>Balance </p>
              <Label
                className="amount-label"
                backgroundColor={data.ending_balance < 0 ? "red" : ""}
              >
                <Amount style={{ color: "white", fontWeight: "600" }}>
                  {data.ending_balance}
                </Amount>
              </Label>
            </div>
          </div>
          <div className="attachments">
            <h1>Attachments</h1>
            {data.property_docs.length > 0 ? (
              <div className="attachments-grid">
                {/* {data.property_docs.map((doc) => ( */}
                {data.property_docs.map((doc) => (
                  <a className="attachment-link" key={doc.url} href={doc.url}>
                    <div className="attachment">
                      {/* <img src={image} alt="doc-placeholder" /> */}
                      <Document />
                      <p>{doc.name}</p>
                    </div>
                  </a>
                ))}
              </div>
            ) : (
              <p className="no-docs">No documents available</p>
            )}
          </div>
        </div>
      )}
    </StyledHeader>
  );
};
const StyledHeader = styled.div`
  * {
    font-family: "Mulish", sans-serif;
  }
  .container {
    display: flex;
    width: 83.6vw;
    justify-content: space-between;
    /* align-items: center; */
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .property-summary-card {
      width: 33vw;
      min-height: 32.9vw;
      overflow-y: auto;
      background: white;
      border: solid 0.07vw #ebe8df;
      border-radius: 0.56vw;
      padding: 0vw 0vw 1.25vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
        border: solid 0.21vw #ebe8df;
        border-radius: 1.8vw;
        padding: 3.75vw 0vw;
      }

      h1 {
        display: flex;
        align-items: center;
        font-size: 1.3vw;
        font-size: 700;
        padding: 0 2.22vw;
        border-bottom: solid 0.07vw #dfe0eb;
        height: 4vw;
        @media (max-width: 768px) {
          font-size: 3.9vw;
          height: 12vw;
          border-bottom: solid 0.21vw #dfe0eb;
        }
        &.rental-dets-header,
        &.taxation-details-header {
          margin-top: 1.25vw;
          @media (max-width: 768px) {
            margin-top: 3.75vw;
          }
        }
      }
      .details-item {
        display: flex;
        justify-content: space-between;
        font-size: 1vw;
        margin-top: 1.25vw;
        padding: 0vw 2.22vw;
        .address-text {
          width: 70%;
        }
        &.balance-item {
          border-top: solid 0.07vw #dfe0eb;
          padding-top: 1vw;
          font-size: 1.2vw;
          font-weight: 600;
          align-items: center;
          .amount-label {
            height: fit-content;
            width: fit-content;
          }
          @media (max-width: 768px) {
            border-top: solid 0.21vw #dfe0eb;
            padding-top: 3vw;
            font-size: 3.6vw;
          }
        }
        @media (max-width: 768px) {
          font-size: 3vw;
          margin-top: 3.75vw;
          padding: 0vw 6vw;
        }
      }
    }

    .attachments {
      width: 47.5vw;
      background: white;
      border: solid 0.07vw #ebe8df;
      border-radius: 0.56vw;
      padding: 0vw 0vw 1.25vw;
      overflow-y: hidden;
      .no-docs {
        margin-top: 1vw;
        text-align: center;
        font-size: 1vw;
        color: ${colors.lightGrey};
        @media (max-width: 768px) {
          margin-top: 3vw;
          font-size: 3vw;
        }
      }
      /* width */
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
        border: solid 0.21vw #ebe8df;
        border-radius: 1.8vw;
        padding: 0vw 0vw 3.75vw;
        margin-top: 6vw;
      }

      h1 {
        display: flex;
        align-items: center;
        font-size: 1.3vw;
        font-size: 700;
        padding: 0 2.22vw;
        border-bottom: solid 0.07vw #dfe0eb;
        height: 4vw;
        @media (max-width: 768px) {
          font-size: 3.9vw;
          height: 12vw;
          /* padding: 3.75vw 6vw; */
          border-bottom: solid 0.21vw #dfe0eb;
        }
      }
      .attachments-grid {
        padding: 1.25vw 2.22vw;
        display: grid;
        max-height: calc(100% - 4vw);
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2.7vw;
        grid-row-gap: 1.25vw;
        overflow-y: auto;
        overflow-x: hidden;
        @media (max-width: 768px) {
          padding: 3.75vw 6vw;
          max-height: 90vw;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 8.1vw;
          grid-row-gap: 3.75vw;
        }
        /* Customizing scroll bar */
        ::-webkit-scrollbar {
          width: 0.55vw;
          height: 1vw;
          @media (max-width: 768px) {
            width: 1.7vw;
          }
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f3efe9;
          border-radius: 1vw;
          @media (max-width: 768px) {
            border-radius: 3vw;
          }
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${colors.brown};
          border-radius: 1vw;
          @media (max-width: 768px) {
            border-radius: 3vw;
          }
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          /* background: ${colors.lightMain}; */
        }
        .attachment-link {
          text-decoration: none;
          color: black;
          .attachment {
            text-align: center;
            svg {
              width: 12.5vw;
              height: 8.69vw;
              object-fit: cover;
              border-radius: 0.56vw;
              margin-bottom: 1vw;
              @media (max-width: 768px) {
                width: 100%;
                height: 27vw;
                border-radius: 1.8vw;
              }
            }
            p {
              font-size: 1vw;
              font-weight: 600;
              @media (max-width: 768px) {
                font-size: 3vw;
              }
            }
          }
        }
      }
    }
  }
`;
export default PropertyHeader;
