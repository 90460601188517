import React from "react";
import styled from "styled-components";
import { ReactComponent as Search } from "../assets/icons/search.svg";

const SearchBar = ({ value, setValue, placeholder = "Search", ...props }) => {
  return (
    <StyledSearch>
      <input
        {...props}
        // type="text"
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      {!props.type && <Search />}
    </StyledSearch>
  );
};
export const SelectBar = ({
  options,
  value,
  setValue,
  placeholder,
  ...props
}) => {
  return (
    <StyledSearch>
      <select
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {placeholder && (
          <option value="" selected>
            {placeholder}
          </option>
        )}
        {options &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
      </select>
    </StyledSearch>
  );
};
const StyledSearch = styled.div`
  position: relative;
  input,
  select {
    width: 28.47vw;
    height: 2.92vw;
    outline: none;
    border: 0.07vw solid #ebe8df;
    border-radius: 0.556vw;
    padding: 0vw 1vw;
    font-size: 1vw;
    background-color: white;
    &::placeholder {
      color: #9e9e9e;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 8.8vw;
      border: 0.21vw solid #ebe8df;
      border-radius: 1.67vw;
      padding: 0 3vw;
      font-size: 3vw;
      margin-top: 3vw;
    }
  }
  svg {
    width: 1.05vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1vw;
    @media (max-width: 768px) {
      width: 3.15vw;
      right: 3vw;
      transform: translateY(0%);
    }
  }
`;

export default SearchBar;
