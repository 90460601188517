import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import Amount from "../components/assets/Amount";
import StyledPage from "../components/assets/StyledPage";
import PropertyDetails from "../components/dashboard/detailed-property/PropertyDetails";
import PropertyHeader from "../components/dashboard/detailed-property/PropertyHeader";
import PropertyTables from "../components/dashboard/detailed-property/PropertyTables";
import { axiosAuth } from "../util/api/integration";
import colors from "../util/colors";
import LoadingPage from "./LoadingPage";
import placeholder from "../images/property-placeholder.png";

const DetailedProperty = () => {
  const { unit_code } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [installmentsStats, setInstallmentsStats] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosAuth.get(`properties/${unit_code}`);
        setData(res.data);
        setLoading(false);
      } catch (error) {
        alert(error.response?.data?.message || "Something went wrong!");
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return !loading ? (
    <StyledDetailedProperties lightMode backgroundColor="#FCFAF7">
      <div className="mobile-image-container mobile-only">
        <img
          src={data?.images[0]?.url || placeholder}
          className={`property-image ${
            data.images[0]?.url ? "" : "placeholder"
          }`}
        />
      </div>
      <div className="header">
        <div className="col1">
          <p className="address-text">
            {`${data.address.street}, ${data.address.area} ${
              data.address.building_no &&
              `, Building ${data.address.building_no}`
            }${data.address.floor_no && ` , Floor ${data.address.floor_no}`}`}
          </p>
          <p className="page-title">
            {`${data?.name1} - ${unit_code?.split("U000")[1]}`}
          </p>
        </div>
        <div className="details-item balance-item">
          <p className="text desktop-only">Balance</p>
          <Amount
            style={{
              color: data.ending_balance < 0 ? "#B06D77" : "#7fb089",
              fontWeight: "800",
            }}
          >
            {data.ending_balance}
          </Amount>
        </div>
      </div>
      <PropertyDetails
        unit_code={unit_code}
        data={data}
        stats={installmentsStats}
      />
      {/* <PropertyHeader unit_code={unit_code} data={data} /> */}
      <PropertyTables
        unit_code={unit_code}
        installmentsStats={installmentsStats}
        setInstallmentsStats={setInstallmentsStats}
      />
    </StyledDetailedProperties>
  ) : (
    <LoadingPage />
  );
};
const StyledDetailedProperties = styled(StyledPage)`
  padding: 12.26vw 0 0 8.681vw;
  @media (max-width: 768px) {
    padding: 24vw 0 0 0;
  }
  .mobile-image-container {
    width: 100vw;
    height: 87.733vw;
    background: #000;
    margin-bottom: 4.8vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .placeholder {
      object-fit: cover;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8.681vw;
    margin-bottom: 2.222vw;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
      padding: 0 4.267vw;
      margin-bottom: 4.267vw;
    }
    .col1 {
      // max-width: 70vw;
      flex-basis: 100%;
      flex: 1;
      .page-title {
        color: #010101;
        font-family: Mulish;
        font-size: 2.083vw;
        line-height: 2.639vw;
        font-style: normal;
        font-weight: 800;
        @media (min-width: 1440px) {
          font-size: 30px;
          line-height: 38px;
        }
        @media (max-width: 768px) {
          font-size: 4.267vw;
          font-weight: 800;
          line-height: 5.333vw;
          width: 91.467vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .address-text {
        color: #010101;
        font-family: Cairo;
        font-size: 1.25vw;
        line-height: 2.361vw;
        font-style: normal;
        font-weight: 400;
        @media (min-width: 1440px) {
          font-size: 18px;
          line-height: 34px;
        }
        @media (max-width: 768px) {
          font-size: 3.2vw;
          line-height: 5.867vw;
          width: 91.467vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .balance-item {
      .text {
        color: var(--black, #010101);
        font-family: Cairo;
        font-size: 1.25vw;
        font-style: normal;
        font-weight: 400;
        line-height: 2.361vw;

        @media (min-width: 1440px) {
          font-size: 18px;
          line-height: 34px;
        }
      }
      font-family: Mulish;
      font-size: 2.083vw;
      line-height: 2.639vw;
      font-style: normal;
      font-weight: 800;
      @media (min-width: 1440px) {
        font-size: 30px;
        line-height: 38px;
      }

      @media (max-width: 768px) {
        font-size: 4.267vw;
        line-height: 5.333vw;
        width: 91.467vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export default DetailedProperty;
