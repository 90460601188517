import { useEffect } from "react";
import { Route, Switch } from "react-router";
import "./App.css";
import ProtectedRoute from "./components/assets/ProtectedRoute";
import ScrollTop from "./components/assets/ScrollTop";
import ChangePassword from "./components/auth/ChangePassword";
import Register from "./components/auth/Register";
import Reset from "./components/auth/Reset";
import SignIn from "./components/auth/SignIn";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import AllProperties from "./pages/AllProperties";
import AllTransactions from "./pages/AllTransactions";
import ContactUs from "./pages/ContactUs";
import Dashboard from "./pages/Dashboard";
import DetailedProperty from "./pages/DetailedProperty";
import HomePage from "./pages/HomePage";
import Listings from "./pages/Listings";
import ManageListing from "./pages/ManageListing";
import ManageProperty from "./pages/ManageProperty";
import Properties from "./pages/Properties";
import UserProperties from "./pages/UserProperties";
import Users from "./pages/Users";
import { updateLoggedInUser } from "./redux/actions/loginAction";
import store from "./redux/store";
import { getUserData } from "./util/api/authFunctions";
import history from "./util/api/history";
import GlobalStyles from "./util/globalStyles";

function App() {
  useEffect(() => {
    // setLoading(true);
    if (localStorage.getItem("token")) {
      console.log("Token found ..");
      getUserData()
        .then((data) => {
          store.dispatch(updateLoggedInUser(data));
          // setLoading(false);
        })
        .catch((err) => {
          history.push(`${history.location.pathname}`);
          // setLoading(false);
        });
    } else {
      // setLoading(false);
    }
  }, []);
  return (
    <div className="App">
      <GlobalStyles />
      <ScrollTop />
      <Nav />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/login" component={SignIn} />
        <Route path="/register" component={Register} />
        <Route path="/reset" component={Reset} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/contacts" component={ContactUs} />
        <Route
          path={["/sale-properties", "/lease-properties", "/properties"]}
          component={Properties}
        />
        <Route path="/register" component={Register} />
        <ProtectedRoute path="/dashboard" component={Dashboard} exact />
        <ProtectedRoute
          path={["/all-properties", "/my-properties"]}
          component={AllProperties}
          exact
        />
        <ProtectedRoute
          path={["/all-transactions", "/my-transactions"]}
          component={AllTransactions}
          exact
        />
        <ProtectedRoute
          path="/dashboard/properties/:unit_code"
          component={DetailedProperty}
        />
        {/* TODO Make available for admins only */}
        <ProtectedRoute path="/users" component={Users} />
        <ProtectedRoute path="/listings" component={Listings} />
        <ProtectedRoute
          path={["/manage-listings/:id", "/manage-listings"]}
          component={ManageListing}
        />
        <ProtectedRoute
          path="/user-properties/:uid"
          component={UserProperties}
        />
        <ProtectedRoute
          path="/manage-properties/:id"
          component={ManageProperty}
        />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
