import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components";
import Amount from "../components/assets/Amount";
import Label from "../components/assets/Label";
import Pagination from "../components/assets/Pagination";
import StyledPage from "../components/assets/StyledPage";
import SearchBar, { SelectBar } from "../components/dashboard/SearchBar";
import { transactionsNames } from "../util/api/data";
import history from "../util/api/history";
import { axiosAuth } from "../util/api/integration";
import colors from "../util/colors";
import { formatObjArr, formatText } from "../util/utilFunctions";
import LoadingPage from "./LoadingPage";

const AllTransactions = () => {
  const [transactions, setTransactions] = useState();
  const [rawData, setRawData] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const { pathname, search } = useLocation();

  let searchParams = new URLSearchParams(search);
  const uid = searchParams.get("UserID");
  const [userLoading, setUserLoading] = useState(true);
  const [userName, setUserName] = useState();

  // Search States
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [familyMember, setFamilyMember] = useState("");
  const [transactionID, setTransactionID] = useState("");
  const [searchSubmitted, setSearchSubmitted] = useState("");
  const { family } = useSelector((state) => state.user);

  function _onFocus(e) {
    e.currentTarget.type = "date";
  }
  function _onBlur(e) {
    e.currentTarget.type = "text";
  }

  const [endpoints, setEndpoints] = useState({
    "/all-transactions": `/me/family/transactions?${
      uid ? `UserID=${uid}&` : ""
    }`,
    "/my-transactions": "/me/transactions?",
  });
  useEffect(() => {
    if (!transactionID) setSearchSubmitted("");
  }, [transactionID]);
  // Getting user data
  useEffect(() => {
    const getUsersData = async () => {
      if (uid) {
        try {
          const response = await axiosAuth.get(`/users/${uid}`);
          setUserName(response.data.name);
          setUserLoading(false);
        } catch (error) {
          alert(error.response?.data?.message || "Something went wrong!");
          setUserLoading(false);
          history.push("/all-transactions");
        }
      } else {
        setUserName(null);
        setUserLoading(false);
      }
    };
    getUsersData();
  }, [search]);
  // Getting transactions

  useEffect(() => {
    setPage(1);
  }, [
    pathname,
    toDate,
    fromDate,
    endpoints,
    searchSubmitted,
    familyMember,
    transactionType,
  ]);

  useEffect(() => {
    const getTransactions = async () => {
      setLoading(true);
      try {
        const response = await axiosAuth.get(
          // `/me/family/transactions?page=${page}&per_page=${perPage}`
          `${endpoints[pathname]}page=${page}&per_page=${perPage}&from_date=${fromDate}&to_date=${toDate}&UserID=${familyMember}&code=${searchSubmitted}&name=${transactionType}`
        );
        setRawData(response.data);
        setTransactions(response.data.results);
        setLoading(false);
      } catch (error) {
        // alert(error.response?.data?.message || "Something went wrong!");
        setLoading(false);
      }
    };
    getTransactions();
  }, [
    page,
    perPage,
    pathname,
    toDate,
    fromDate,
    endpoints,
    searchSubmitted,
    familyMember,
    transactionType,
  ]);
  return (
    <StyledPage scroll lightMode backgroundColor="#FCFAF7" no-padding>
      {loading || userLoading ? (
        <LoadingPage />
      ) : (
        <StyledSection>
          <div className="title-bar">
            <h1>
              {userName
                ? `${formatText(userName, " ")}'s Transactions`
                : pathname === "/all-transactions"
                ? "All Transactions"
                : "My Transactions"}
            </h1>
            <div className="search-section">
              <SearchBar
                type="text"
                onFocus={_onFocus}
                onBlur={_onBlur}
                // type="date"
                className="from-to"
                placeholder="From"
                value={fromDate}
                setValue={setFromDate}
              />
              <SearchBar
                type="text"
                onFocus={_onFocus}
                onBlur={_onBlur}
                className="from-to"
                placeholder="To"
                value={toDate}
                setValue={setToDate}
              />
              <SelectBar
                className="trans-type-select"
                placeholder="Select Transaction Type"
                options={transactionsNames}
                value={transactionType}
                setValue={setTransactionType}
              />
              {pathname === "/all-transactions" && !uid && (
                <SelectBar
                  className="member-select"
                  placeholder="Select Family Member"
                  options={formatObjArr(family, "name", "UserID")}
                  value={familyMember}
                  setValue={setFamilyMember}
                />
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setSearchSubmitted(transactionID);
                }}
              >
                <SearchBar
                  placeholder="Search by transaction ID"
                  value={transactionID}
                  setValue={setTransactionID}
                />
              </form>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Type</td>
                  <td>Date</td>
                  <td>Details</td>
                  <td>Property</td>
                  <td>Amount</td>
                  <td>Balance</td>
                </tr>
              </thead>
              {transactions && (
                <tbody>
                  {transactions.map((transaction) => (
                    <tr key={`transaction.unit_code ${Math.random(10000)}`}>
                      <td className="type">
                        {transaction.amount > 0 ? (
                          <Label className="credit">Credit</Label>
                        ) : (
                          <Label backgroundColor="red" className="debit">
                            Debit
                          </Label>
                        )}
                      </td>
                      <td>{transaction.date}</td>
                      <td>{`${transaction.name} (${transaction.code})`}</td>
                      <td>{transaction.property_name}</td>
                      <td className="amount">
                        <Amount>{transaction.amount}</Amount>
                      </td>
                      {/* <td className="amount">£E {transaction.actual_amount}</td> */}
                      <td className="table-balance">
                        <Amount table>{transaction.ending_balance}</Amount>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              <Pagination
                data={rawData}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </table>
          </div>
        </StyledSection>
      )}
    </StyledPage>
  );
};
const StyledSection = styled.div`
  width: 82.6vw;
  margin: auto;
  margin-top: 0;
  .table-container {
    width: 100%;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 3vw;
    /* Customizing scroll bar */
    ::-webkit-scrollbar {
      width: 0.55vw;
      height: 1vw;
      @media (max-width: 768px) {
        width: 1.7vw;
      }
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f3efe9;
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.brown};
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      /* background: ${colors.lightMain}; */
    }
  }
  .title-bar {
    margin-bottom: 2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .search-section {
      display: flex;
      select {
        margin-right: 1vw;
        @media (max-width: 768px) {
          width: 100%;
        }

        &.trans-type-select,
        &.member-select {
          width: 13.8vw;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      input {
        width: 17.3vw;
        @media (max-width: 768px) {
          width: 100%;
        }
        /* margin-right: 1vw; */
        &.from-to {
          width: 8vw;
          margin-right: 1vw;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 11.5vw;
    }
    a {
      color: ${colors.brown};
      font-size: 1.38vw;
      margin-left: 0.7vw;
    }
  }
  table {
    position: relative;
    width: 100%;
    background: white;
    overflow-y: hidden;
    border-radius: 2.1vw;
    padding: 1vw;
    font-size: 0.9vw;
    margin-top: 1.04vw;
    @media (max-width: 768px) {
      border-radius: 6vw;
      margin-bottom: 0vw;
      padding: 3vw;
      font-size: 2.7vw;
      margin-top: 3.12vw;
      width: 200vw;
    }
    thead {
      color: #334d6e;
      font-weight: 700;
    }
    tbody {
      * {
        font-family: "Poppins", sans-serif;
      }
      display: block;
      /* max-height: 30vw; */
      /* overflow-y: scroll; */
      @media (max-width: 768px) {
        /* max-height: 90vw; */
      }

      /* Customizing scroll bar */
      ::-webkit-scrollbar {
        width: 0.55vw;
        height: 1vw;
        @media (max-width: 768px) {
          width: 1.7vw;
        }
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f3efe9;
        border-radius: 1vw;
        @media (max-width: 768px) {
          border-radius: 3vw;
        }
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${colors.brown};
        border-radius: 1vw;
        @media (max-width: 768px) {
          border-radius: 3vw;
        }
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        /* background: ${colors.lightMain}; */
      }
      td {
        /* color: #707683;
      &.credit {
        color: #7fb089;
      }
      &.debit {
        color: #b06d77;
      } */
        &.table-balance {
          font-weight: 600;
        }
      }
    }

    // general rows and cells styling
    tr {
      width: 100%;
      height: 3.7vw;
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        height: 11.1vw;
      }
    }
    td {
      flex: 1;
      // text-align: center;
      &:nth-child(1) {
        flex: 0.7;
        @media(max-width : 768px){
          flex: 0.8;
        }
      }
      &:nth-child(2) {
        flex: 0.7;
      }
      &:nth-child(3) {
        flex: 1.5;
      }
      &:nth-child(4) {
        flex: 1.5;
      }
      .credit {
        width: 5.317vw;
        @media (max-width: 768px) {
          width: 20.8vw;
        }
      }
      .debit {
        width: 5.317vw;
        @media (max-width: 768px) {
          width: 18.133vw;
        }
      }
    }
    .scroll-top-button {
      background: ${colors.brown};
      position: absolute;
      top: 1vw;
      right: 1vw;
      width: 1vw;
      height: 1vw;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 0.1vw;
      font-size: 0.8vw;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;
export default AllTransactions;
