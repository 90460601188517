import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyledPage from "../components/assets/StyledPage";
import { axiosAuth } from "../util/api/integration";
import { Delete, ViewIcon } from "../components/assets/icons/icons";
import { formatText } from "../util/utilFunctions";
import Button from "../components/assets/Button";
import colors from "../util/colors";
import Pagination from "../components/assets/Pagination";
import { Link } from "react-router-dom";

const Listings = () => {
  const [rawData, setRawData] = useState();
  const [properties, setProperties] = useState();
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getProps = async () => {
      try {
        const res = await axiosAuth.get(
          `/properties?page=${page}&per_page=${perPage}`
        );
        console.log(res);
        setRawData(res.data);
        setProperties(res.data.results);
      } catch (error) {
        console.log(error);
      }
    };
    getProps();
  }, [refresh, page, perPage]);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  const keys = [
    { name: "id", displayName: "ID" },
    { name: "name1", displayName: "Name" },
    { name: "area" },
    { name: "sale_state" },
    { name: "rent_state" },
    { name: "value" },
  ];
  const deleteHandler = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete property ?"
    );
    if (confirmed) {
      try {
        const res = await axiosAuth.delete(`/properties/${id}`);
        alert(`Property is deleted successfully`);
        setRefresh(!refresh);
      } catch (error) {
        alert(error.response?.data?.message || "Something went wrong!");
      }
    }
  };

  return (
    <StyledPage lightMode backgroundColor="#FCFAF7" no-padding>
      {properties && (
        <StyledListings>
          <div className="listings-table-container">
            <div className="listings-container-header">
              <h1 className=" fs30">Properties</h1>
              <Button
                link="/manage-listings"
                label="+ Add Property"
                className="add-btn"
              />
            </div>
            <table>
              <thead>
                <tr>
                  {keys.map((key) => (
                    <th key={key.name}>
                      {key.displayName || formatText(key.name)}
                    </th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {properties.map((property) => (
                  <tr key={property.id}>
                    {keys.map((key) => (
                      <td key={property.id + key}>{property[key.name]}</td>
                    ))}
                    <td className="actions-cell">
                      <Link to={`/manage-listings/${property.id}`}>
                        <ViewIcon id="view-icon" />
                      </Link>
                      <Delete
                        className="btn-delete"
                        onClick={() => deleteHandler(property.id)}
                      />
                    </td>{" "}
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              className="styled-pagination"
              data={rawData}
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </div>
        </StyledListings>
      )}
    </StyledPage>
  );
};

const StyledListings = styled.div`
  /* min-height: 26.1vw; */
  border: solid 0.07vw #ebe8df;
  background: white;
  border-radius: 0.56vw;
  margin: auto;
  margin-top: 1vw;
  margin-bottom: 3vw;
  width: 80%;
  text-align: center;
  .listings-container-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      text-align: center;
      margin: 1vw 0;
    }
    a {
      text-decoration: none;
    }
    .add-btn {
      position: absolute;
      top: 1vw;
      right: 1vw;
      background: ${colors.brown};
      padding: 0 2vw;
      margin-left: 1vw;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  table {
    width: 100%;
    th {
      opacity: 0.5;
    }
    tr {
      height: 4vw;
      width: 100%;
      gap: 1vw;
      font-size: 1vw;
      font-weight: 600;
      padding: 0vw 2.36vw 0vw 2.36vw;
      .btn-delete,
      #view-icon {
        width: 3vw;
        padding: 0 1vw;
        cursor: pointer;
        @media (max-width: 768px) {
          /* font-size: 1.5vw; */
          width: 3.5vw;
        }
      }
      @media (max-width: 768px) {
        height: 13.3vw;
        font-size: 1.5vw;
        /* padding: 0vw 5.19vw 0vw 8vw; */
        padding: 0vw 8vw 0vw 8vw;
      }
      &:not(:last-child) {
        border-bottom: solid 0.07vw #dfe0eb;
      }
    }
  }
`;
export default Listings;
