import React from "react";
import styled from "styled-components";
import colors from "../util/colors";
import StyledPage from "../components/assets/StyledPage";
import { ReactComponent as Phone } from "../images/phone.svg";
import { ReactComponent as Location } from "../images/location.svg";
import { ReactComponent as Mail } from "../images/mail.svg";
const ContactUs = () => {
  return (
    <StyledPage no-padding>
      <PageContent>
        <div className="contacts-container">
          <h1 className="contacts-heading fs30">Contact Info</h1>
          <div className="contacts">
            <div className="item inquiries fs25">
              <Phone />
              <p>
                <a href="tel:+201211145222">01211145222</a>
              </p>
            </div>
            <div className="item address-and-number fs25">
              <Location />
              <p className="address"> 
              <a href="https://maps.app.goo.gl/H9gLHWJKUEoDYUcY6" target="_blank" className="address-link">
              <p>Nile view club, Dokki</p>
              <p>Giza, Egypt</p>
              </a>
              </p>
              <p className="number">
                Tel: <a href="tel:+200233358584">+200233358584</a>
              </p>
            </div>
            <div className="item inquiries fs25">
              <Mail />
              <p>
                For inquires, kindly email us at:{" "}
                <span className="inq-email">
                  <a href="mailto:info@equityegypt.com">info@equityegypt.com</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </PageContent>
    </StyledPage>
    // <StyledPage no-padding>
    //   <PageContent>
    //     <h1 className="contacts-heading fs30">Contact Us</h1>
    //     <div className="address-and-number fs25">
    //       <p className="address">37, Abu El Feda St, Zamalek. Cairo, Egypt </p>
    //       <p className="number">
    //         Tel: <a href="tel:+20227355100">+20227355100</a>
    //       </p>
    //     </div>
    //     <div className="inquiries fs25">
    //       <p>
    //         For inquires, kindly email us at;{" "}
    //         <span className="inq-email">
    //           <a href="mailto:info@equityegypt.com">info@equityegypt.com</a>
    //         </span>
    //       </p>
    //       <p>
    //         or call us at; <a href="tel:+201211145222">01211145222</a>
    //       </p>
    //     </div>
    //   </PageContent>
    // </StyledPage>
  );
};

const PageContent = styled.div`
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${colors.brown};
    width: 5vw;
    margin-bottom: 2vw;
    @media (max-width: 768px) {
      width: 15vw;
      margin-bottom: 6vw;
    }
  }
  color: ${colors.lightGrey};
  margin-bottom: 17.8vw;
  padding: 6.26vw;
  .contacts-container {
    /* background: #e5e5e5; */
    text-align: center;
    border-radius: 1vw;
    padding: 3vw;
    @media (max-width: 768px) {
      border-radius: 3vw;
      padding: 9vw;
    }
    .contacts {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 6.5vw;
      /* margin-top: 1.5vw; */
      padding: 3vw;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        padding: 9vw;
      }
      .item {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 768px) {
          width: 80%;
          margin-top: 4vw;
        }
        p {
          text-align: center;
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    margin-top: 3.7vw;
    margin-bottom: 30.7vw;
  }

  .contacts-heading {
    color: ${colors.brown};
  }
  .address-and-number {
    /* margin-top: 4vw; */
    .number {
      a {
        text-decoration: none;
        color: ${colors.lightGrey};
      }
    }
  }
  .inquiries {
    /* margin-top: 4.5vw; */
    a {
      color: ${colors.lightGrey};
    }
    .inq-email {
      a {
        text-decoration: none;
        color: ${colors.brown};
      }
    }
  }
`;

// const PageContent = styled.div`
//   color: ${colors.lightGrey};
//   margin-bottom: 17.8vw;
//   padding: 6.26vw 0 0 6.94vw;
//   .contacts-container {
//     display: flex;
//     justify-content: space-between;
//     div {
//       width: 20%;
//     }
//   }
//   a {
//     text-decoration: none;
//   }
//   @media (max-width: 768px) {
//     margin-top: 3.7vw;
//     margin-bottom: 30.7vw;
//   }

//   .contacts-heading {
//     color: ${colors.brown};
//   }
//   .address-and-number {
//     margin-top: 4vw;
//     .number {
//       a {
//         text-decoration: none;
//         color: ${colors.lightGrey};
//       }
//     }
//   }
//   .inquiries {
//     margin-top: 4.5vw;
//     a {
//       color: ${colors.lightGrey};
//     }
//     .inq-email {
//       a {
//         text-decoration: none;
//         color: ${colors.brown};
//       }
//     }
//   }
// `;

export default ContactUs;
