import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { axiosAuth } from "../../../util/api/integration";
import colors from "../../../util/colors";
import Amount from "../../assets/Amount";
import Label from "../../assets/Label";
import Pagination from "../../assets/Pagination";
const TransactionsTable = ({
  unit_code,
  showProperty,
  transactions,
  page,
  setPage,
  perPage,
  setPerPage,
  rawData,
}) => {
  return (
    <StyledTable className="details-table">
      <thead>
        <tr>
          <td>Type</td>
          <td>Date</td>
          <td>Details</td>
          {showProperty && <td>Property</td>}
          <td>Amount</td>
          <td>Balance</td>
        </tr>
      </thead>
      {transactions && (
        <tbody>
          {transactions.length > 0 ? (
            transactions.map((transaction, index) => {
              return (
                <tr key={`transaction.unit_code ${Math.random(10000)}`}>
                  <td className="type">
                    {transaction.amount > 0 ? (
                      <Label className="label">Credit</Label>
                    ) : (
                      <Label backgroundColor="red" className="label">
                        Debit
                      </Label>
                    )}
                  </td>
                  <td>{transaction.date}</td>
                  <td>{`${transaction.name} (${transaction.code})`}</td>
                  {showProperty && <td>{transaction.property.unit_code}</td>}
                  <td className="amount">
                    <Amount>{transaction.amount}</Amount>
                  </td>
                  <td className="table-balance">
                    <Amount table>{transaction.ending_balance}</Amount>
                  </td>
                </tr>
              );
            })
          ) : (
            <p className="no-records">No records found</p>
          )}
        </tbody>
      )}
      {rawData && (
        <Pagination
          data={rawData}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          noScroll
        />
      )}
    </StyledTable>
  );
};
const StyledTable = styled.table`
  position: relative;
  width: 83.6vw;
  background: white;
  overflow-y: hidden;
  border-radius: 2.1vw;
  margin-bottom: 3vw;
  padding: 1vw;
  font-family: Mulish;
  font-size: 0.9vw;
  margin-top: 1.04vw;
  @media (max-width: 768px) {
    border-radius: 2.133vw;
    margin-bottom: 0vw;
    padding: 4.267vw;
    font-size: 3.733vw;
    margin-top: 4.267vw;
    width: 250vw;
  }
  thead {
    color: #334d6e;
    font-weight: 700;
    font-family: Mulish;
    td {
      font-weight: 700;
      font-family: Mulish;
      text-align: left;
    }
  }
  tbody {
    * {
      // font-family: "Poppins", sans-serif;
      font-family: Mulish;
    }
    display: block;
    max-height: 30vw;
    overflow-y: scroll;
    @media (max-width: 768px) {
      max-height: 90vw;
    }

    /* Customizing scroll bar */
    ::-webkit-scrollbar {
      width: 0.55vw;
      height: 1vw;
      @media (max-width: 768px) {
        width: 1.7vw;
      }
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f3efe9;
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.brown};
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      /* background: ${colors.lightMain}; */
    }

    td {
      /* color: #707683;
     
      font-weight: 600;
      &.credit {
        color: #7fb089;
      
      }
      &.debit {
        color: #b06d77;
       
           }
      */
      &.table-balance {
        font-weight: 800;
      }
    }
    .type {
      font-weight: 700;
      .label {
        width: 5.317vw;
        @media (max-width: 768px) {
          width: 15vw;
        }
      }
    }
  }

  // general rows and cells styling
  .no-records {
    margin-top: 1vw;
    text-align: center;
    font-size: 1vw;
    color: ${colors.darkGrey};
    @media (max-width: 768px) {
      margin-top: 3vw;
      font-size: 3vw;
    }
  }
  tr {
    width: 100%;
    height: 3.7vw;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      height: 11.1vw;
    }
  }
  td {
    flex: 1;
    text-align: left;
    font-weight: 600;
    &:nth-child(1) {
      flex: 0.8;
    }
    &:nth-child(3) {
      flex: 1.5;
    }
  }
  .scroll-top-button {
    background: ${colors.brown};
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 1vw;
    height: 1vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.1vw;
    font-size: 0.8vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .pagination {
    @media (max-width: 768px) {
      padding: 0;
      margin: 0;
      justify-content: flex-start;

      .options {
        margin-left: 0;
        padding: 0 2vw;
      }
      .first-page {
        margin: 0;
      }
    }
  }
`;

export default TransactionsTable;
