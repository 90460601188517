const colors = {
  white: "#FFFFFF",
  brown: "#AF916D",
  black: "#010101",
  lightGrey: "#A7A9AC",
  darkGrey: "#262626",
  goldenGrey: "#B4AC9F",
  green: "#7FB089",
  red: "#B06D77",
  darkBrown: "#6D634B",
};
export default colors;
