import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components";
import useDataFetch from "../components/assets/hooks/useDataFetch";
import { Loading } from "../components/assets/icons/icons";
import StyledPage from "../components/assets/StyledPage";
import DashboardPropertyCard from "../components/dashboard/DashboardPropertyCard";
import SearchBar, { SelectBar } from "../components/dashboard/SearchBar";
import history from "../util/api/history";
import { axiosAuth } from "../util/api/integration";
import colors from "../util/colors";
import { formatObjArr, formatText } from "../util/utilFunctions";
import LoadingPage from "./LoadingPage";

const AllProperties = () => {
  const { pathname, search } = useLocation();
  // console.log(search);
  let searchParams = new URLSearchParams(search);
  const [uid, setUid] = useState(searchParams.get("UserID"));
  const [endpoints, setEndpoints] = useState({
    "/all-properties": `/me/family/properties${uid ? `?UserID=${uid}` : ""}`,
    "/my-properties": "/me/properties",
  });
  const [userLoading, setUserLoading] = useState(true);
  const [userName, setUserName] = useState();
  const [searchMatches, setSearchMatches] = useState();
  const [familyMember, setFamilyMember] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  const { family } = useSelector((state) => state.user);

  const searchHandler = async (e) => {
    e.preventDefault();
    if (searchKey) {
      setSearchLoading(true);
      try {
        const response = await axiosAuth(
          `/me/family/properties?unit_code=${searchKey}`
        );
        setSearchMatches(response.data.results);
        setSearchLoading(false);
      } catch (error) {
        alert(error.response?.data?.message || "Something went wrong!");
        setSearchLoading(false);
      }
    }
  };

  useEffect(() => {
    setUid(new URLSearchParams(search).get("UserID"));
  }, [search]);

  useEffect(() => {
    setEndpoints({
      "/all-properties": `/me/family/properties${uid ? `?UserID=${uid}` : ""}`,
      "/my-properties": "/me/properties",
    });
  }, [uid]);

  useEffect(() => {
    const getUsersData = async () => {
      if (uid && pathname === "/all-properties") {
        try {
          const response = await axiosAuth.get(`/users/${uid}`);
          setUserName(response.data.name);
          setUserLoading(false);
        } catch (error) {
          alert(error.response?.data?.message || "Something went wrong!");
          setUserLoading(false);
          history.push("/all-properties");
        }
      } else {
        setUserName(null);
        setUserLoading(false);
      }
    };
    getUsersData();
  }, [search, uid, pathname]);

  useEffect(() => {
    if (pathname !== "/all-properties") {
      setFamilyMember();
    }
  }, [pathname]);

  useEffect(() => {
    setUid(familyMember || searchParams.get("UserID"));
  }, [familyMember]);

  useEffect(() => {
    if (!searchKey) setSearchMatches(null);
  }, [searchKey]);

  useEffect(() => {
    setPageNumber(1);
  }, [pathname]);

  const { properties, loading, hasMore, error } = useDataFetch(
    pageNumber,
    endpoints[pathname],
    uid,
    setPageNumber
  );
  const observer = useRef();
  const lastPropertyRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber((n) => n + 1);
          }
        },
        { threshold: 0.5 }
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return userLoading ? (
    <LoadingPage />
  ) : (
    <StyledPage scroll lightMode backgroundColor="#FCFAF7" no-padding>
      <StyledSection>
        <p className={`loading ${!loading && !searchLoading ? "hidden" : ""}`}>
          LOADING.. <Loading />
        </p>
        <div className="title-bar">
          <h1>
            {userName
              ? `${formatText(userName, " ")}'s Properties`
              : pathname === "/all-properties"
              ? "All Properties"
              : "My Properties"}
          </h1>
          <div className="search-section">
            {pathname === "/all-properties" && !searchParams.get("UserID") && (
              <SelectBar
                className="member-select"
                placeholder="Select Family Member"
                options={formatObjArr(family, "name", "UserID")}
                value={familyMember}
                setValue={setFamilyMember}
              />
            )}
            <form onSubmit={searchHandler}>
              <SearchBar
                value={searchKey}
                setValue={setSearchKey}
                placeholder="Search by property ID"
              />
            </form>
          </div>
        </div>
        {searchMatches ? (
          <div className="properties-panel">
            {searchMatches.map((property, index) => (
              <DashboardPropertyCard
                property={property}
                key={`property.unit_code ${Math.random(10000)}`}
              />
            ))}
          </div>
        ) : (
          <div className="properties-panel">
            {properties.map((property, index) => {
              if (properties.length === index + 1) {
                return (
                  <DashboardPropertyCard
                    property={property}
                    MyRef={lastPropertyRef}
                    key={`property.unit_code ${Math.random(10000)}`}
                  />
                );
              } else {
                return (
                  <DashboardPropertyCard
                    property={property}
                    key={`property.unit_code ${Math.random(10000)}`}
                  />
                );
              }
            })}
          </div>
        )}
      </StyledSection>
    </StyledPage>
  );
};

const StyledSection = styled.div`
  width: 82.6vw;
  margin: auto;
  margin-top: 0;
  .title-bar {
    margin-bottom: 2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    h1 {
      font-size: 2vw;
      @media (max-width: 768px) {
        font-size: 6vw;
      }
    }

    .search-section {
      display: flex;
      select {
        margin-right: 1vw;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 11.5vw;
    }
    a {
      color: ${colors.brown};
      font-size: 1.38vw;
      margin-left: 0.7vw;
    }
  }
  .properties-panel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    grid-gap: 2.1vw;
    margin-bottom: 2vw;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
  .loading {
    z-index: 10;
    transition: max-height 1s linear;
    overflow: hidden;
    width: 100%;
    display: flex;
    background: #af916d;
    color: #e5e5e5;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0vw;
    left: 0;
    font-size: 1vw;
    max-height: 100vw;
    &.hidden {
      /* opacity: 0; */
      max-height: 0vw;
    }
    svg {
      width: 3vw;
    }
    @media (max-width: 768px) {
      font-size: 3vw;
      svg {
        width: 9vw;
      }
    }

    /* color: black; */
  }
`;

export default AllProperties;
