export const transactionsNames = [
  { value: "Rent", name: "Rent" },
  { value: "Management Fees", name: "Management Fees" },
  { value: "Utilities", name: "Utilities" },
  { value: "Property Tax", name: "Property Tax" },
  { value: "Furnishing Expenses", name: "Furnishing Expenses" },
  { value: "Engineering Consultations", name: "Engineering Consultations" },
  { value: "Administirative Expense", name: "Administirative Expense" },
];

export const statsItems = [
  "Rent",
  "Management Fees",
  "Utilities",
  "Property Tax",
  "Furnishing Expense",
  "Engineering Consultations",
  "Administrative Expense",
  "Deposit",
  "Withdrawal",
];
