import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import colors from "../util/colors";
const NavLinks = ({ ...props }) => {
  const location = useLocation();
  const { UserID } = useSelector((state) => state.user);
  const isFamilyMember = useSelector((state) => state.user.family_id);
  const homeRoutes = [
    "/",
    "/properties",
    "/contacts",
    "/sale-properties",
    "/lease-properties",
  ];
  return (
    <StyledMenu className="nav-links" {...props}>
      {(location.pathname.split("/")[1] === "users" ||
        location.pathname.split("/")[1] === "register" ||
        location.pathname.split("/")[1] === "listings" ||
        location.pathname.split("/")[1] === "manage-listing" ||
        location.pathname.split("/")[1] === "user-properties" ||
        location.pathname.split("/")[1] === "manage-properties") &&
      localStorage.getItem("isAdmin") ? (
        <ul>
          <li>
            <Link to="/listings">ALL PROPERTIES</Link>
          </li>
          <li>
            <Link to="/users">ALL USERS</Link>
          </li>
        </ul>
      ) : homeRoutes.includes(location.pathname) ? (
        <ul>
          <li>
            <a href="/#about">ABOUT US</a>
          </li>
          <li>
            <Link to="/properties">PROPERTIES</Link>
            <div className="popup-menu">
              <div className="menu-item">
                <Link to="/sale-properties">PROPERTIES FOR SALE</Link>
              </div>
              <div className="menu-item">
                <Link to="/lease-properties">PROPERTIES FOR LEASE</Link>
              </div>
            </div>
          </li>
          <li>
            <a href="/#our-services">OUR SERVICES</a>
          </li>
          <li>
            <Link to="/contacts">CONTACT US</Link>
          </li>
          {localStorage.getItem("isAdmin") && (
            <>
              <li>
                <Link to="/listings">ALL PROPERTIES</Link>
              </li>
              <li>
                <Link to="/users">ALL USERS</Link>
              </li>
            </>
          )}
        </ul>
      ) : UserID ? (
        <ul>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          {isFamilyMember && (
            <>
              <li>
                <Link to="/all-properties">All Properties</Link>
              </li>
              <li>
                <Link to="/all-transactions">All Transactions</Link>
              </li>
            </>
          )}
          <li>
            <Link to="/my-properties">My Properties</Link>
          </li>
          <li>
            <Link to="/my-transactions">My Transactions</Link>
          </li>
          {localStorage.getItem("isAdmin") && (
            <>
              <li>
                <Link to="/listings">ALL PROPERTIES</Link>
              </li>
              {/* <li>
                <Link to="/users">ALL USERS</Link>
              </li> */}
            </>
          )}
        </ul>
      ) : (
        <ul>
          <li>
            <Link to="/">BACK TO HOME</Link>
          </li>
        </ul>
      )}
      {localStorage.getItem("isAdmin") && location.pathname === "/register" && (
        <ul>
          <li>
            <Link to="/users">ALL USERS</Link>
          </li>
        </ul>
      )}
    </StyledMenu>
  );
};
const StyledMenu = styled.div`
  display: flex;
  margin-right: 0.76vw;
  font-size: 1.25vw;
  @media (max-width: 768px) {
    font-size: 4vw;
  }
  ul {
    height: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      min-width: fit-content;
      position: relative;
      &:not(:last-child) {
        margin-right: 1.1vw;
      }
      a {
        text-decoration: none;
        color: ${colors.white};
      }
      &:hover {
        .popup-menu {
          display: block;
        }
      }
      .popup-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background: ${colors.black};
        width: 20vw;
        border-radius: 1vw;
        overflow: hidden;
        @media (max-width: 768px) {
          display: block;
          position: static;
          width: auto;
        }
        .menu-item {
          padding: 0.5vw 2vw;
          width: 100%;
          &:hover {
            background: ${colors.darkBrown};
            @media (max-width: 768px) {
              background: ${colors.black};
            }
          }
        }
      }
    }
  }
`;
export default NavLinks;
