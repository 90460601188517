import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";

const Label = (props) => {
  return <StyledLabel {...props}>{props.children}</StyledLabel>;
};
const StyledLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7vw;
  height: 1.4vw;
  padding: 0.35vw 0.83vw;
  font-family: "Mulish", sans-serif;
  color: ${props => props.color || "white"};
  background: ${(props) =>
    !props.backgroundColor ? colors.green : props.backgroundColor === "red" ? colors.red : props.backgroundColor};
  @media (max-width: 768px) {
    height: 5.333vw;
    border-radius: 21vw;
    padding: 1.333vw 3.2vw;
  }
`;

export default Label;
