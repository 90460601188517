import { useEffect, useState } from "react";
import { axiosAuth, axiosOMS } from "../../../util/api/integration";

const usePropertiesFetch = (
  pageNumber,
  endpoint = "/properties",
  uid,
  setPageNumber
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [properties, setProperties] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setPageNumber(1);
    setProperties([]);
  }, [endpoint]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      if (pageNumber === 1) setProperties([]);
      try {
        const res = await axiosOMS.get(endpoint, {
          params: { page: pageNumber, per_page: 10 },
        });
        setProperties((prevProperties) => {
          return [...prevProperties, ...res.data.results];
        });
        setHasMore(res.data.next ? true : false); //CHECK
        setLoading(false);
      } catch (error) {
        // alert(error.response?.data?.message || "Something went wrong!");
        setError(true);
      }
    };
    fetchData();
  }, [pageNumber, endpoint]);

  return {
    loading,
    error,
    properties,
    hasMore,
  };
};

export default usePropertiesFetch;
