import React, { useState } from "react";
import styled from "styled-components";
import colors from "../util/colors";

const TaxCalc = () => {
  const [value, setValue] = useState("");
  const [results, setResults] = useState({
    capital_value: 0,
    yearly_rental: 0,
    net_y: 0,
    tax: 0,
  });
  const submitHandler = (e) => {
    e.preventDefault();
    console.log("3243");
    setResults({
      ...results,
      capital_value: 0.6 * value,
    });
    setResults((prev) => {
      return { ...prev, yearly_rental: 0.03 * prev.capital_value };
    });
    setResults((prev) => {
      return { ...prev, net_y: 0.7 * prev.yearly_rental };
    });
    setResults((prev) => {
      return { ...prev, tax: 0.1 * prev.net_y };
    });
  };
  return (
    <Calculator>
      <h1 className="fs30">Property tax calculator</h1>
      <form onSubmit={submitHandler}>
        <div className="calc-container">
          <div className="calc-row">
            <label className="fs25" htmlFor="c-value">
              Property Value
            </label>
            <input
              min={0}
              value={value}
              onChange={(e) => setValue(parseInt(e.target.value))}
              required
              className=" fs20 calc-value-of"
              type="number"
              id="c-value"
            />
            <label htmlFor="c-value" className="fs20 egp-placeholder">
              EGP
            </label>
          </div>
          <div className="vals-row">
            <p className="fs20" htmlFor="c-value">
              Capital Value
            </p>
            <p className=" fs16 item-value">
              {results.capital_value.toFixed(2)} EGP
            </p>
          </div>
          <div className="vals-row">
            <p className="fs20" htmlFor="c-value">
              Yearly Rental Value
            </p>
            <p className=" fs16 item-value">
              {results.yearly_rental.toFixed(2)} EGP
            </p>
          </div>
          <div className="vals-row">
            <p className="fs20" htmlFor="c-value">
              Net Y. Rental Value
            </p>
            <p className=" fs16 item-value">{results.net_y.toFixed(2)} EGP</p>
          </div>
          <div className="vals-row result">
            <p className="fs25" htmlFor="c-value">
              Tax
            </p>
            <p className="fs20 item-value">{results.tax.toFixed(2)} EGP</p>
          </div>
        </div>
        <button>Calculate</button>
      </form>
    </Calculator>
  );
};
const Calculator = styled.div`
  /* padding: 0 0 0 8.2vw; */
  padding: 2vw 4vw;
  border-radius: 0.5vw;
  width: 50vw;
  margin: auto;
  margin-bottom: 4vw;
  background: ${colors.darkGrey};
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 5vw;
  }
  form {
    button {
      width: 100%;
      height: 3vw;
      margin-top: 2vw;
      background: ${colors.brown};
      font-weight: 700;
      border-radius: 0.21vw;
      font-size: 1.2vw;
      @media (max-width: 768px) {
        height: 9vw;
        margin-top: 6vw;
        border-radius: 1vw;
        font-size: 4vw;
      }
    }

    /* Hiding default number input arrows */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  h1 {
    color: ${colors.brown};
    margin-bottom: 3vw;
  }
  .calc-container {
    display: flex;
    flex-direction: column;
    gap: 1vw;
  }
  .calc-row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;
    .egp-placeholder {
      position: absolute;
      right: 1vw;
      top: 50%;
      transform: translateY(-50%);
      color: black;
    }

    /* .calc-value-of {
      flex: 1;
    } */
    label,
    p {
      color: ${colors.brown};
    }
    input {
      flex: 0.5;
      background: ${colors.goldenGrey};
      outline: none;
      border: none;
      padding: 0 1vw;
      padding-right: 4vw;
    }
  }
  .vals-row {
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    @media (max-width: 768px) {
      .fs16 {
        font-size: 3.73vw;
      }
    }
    &.result {
      color: ${colors.brown};
    }
  }
`;

export default TaxCalc;
