import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { statsItems } from "../../util/api/data";
import { axiosAuth } from "../../util/api/integration";
import colors from "../../util/colors";
import { LogItem } from "./DashboardPropertyCard";
import FamilyStats from "./FamilyStats";
import SearchBar from "./SearchBar";
import TableFamilyMember from "./TableFamilyMember";

const Family = ({ family, familyStats }) => {
  const [displayMembers, setDisplayMembers] = useState(family);
  const [searchMatches, setSearchMatches] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (searchMatches) {
      setDisplayMembers(searchMatches);
    } else {
      setDisplayMembers(family || []);
    }
  }, [searchMatches]);

  useEffect(() => {
    if (!searchKey) setSearchMatches(null);
  }, [searchKey]);

  const searchHandler = async (e) => {
    e.preventDefault();
    setSearchLoading(true);
    if (searchKey) {
      try {
        const response = await axiosAuth(`/me/family?name=${searchKey}`);
        setSearchMatches(response.data);
        setSearchLoading(false);
      } catch (error) {
        alert(error.response?.data?.message || "Something went wrong!");
        setSearchLoading(false);
      }
    }
  };

  return (
    <StyledSection id="my-family">
      <div className="title-bar">
        <h1 className="table-title fs30">Family</h1>
        <form onSubmit={searchHandler}>
          <SearchBar value={searchKey} setValue={setSearchKey} />
        </form>
      </div>
      {family && (
        <>
          <FamilyStats familyStats={familyStats} />
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td className="name-cell">Name</td>
                  <td className="balance-cell">Balance</td>
                  <td>Number Units</td>
                  <td className="services-header">Services Summary</td>
                </tr>
              </thead>
              <tbody>
                {displayMembers.map((member) => (
                  <TableFamilyMember key={member.UserID} member={member} />
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </StyledSection>
  );
};
const StyledSection = styled.div`
  margin-bottom: 4vw;
  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      align-items: start;
      .table-title {
        font-size: 4.8vw;
        line-height: 6.133vw;
      }
    }

    form {
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    h1 {
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  .table-container {
    width: 100%;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    /* Customizing scroll bar */
    ::-webkit-scrollbar {
      width: 0.55vw;
      height: 1vw;
      @media (max-width: 768px) {
        width: 1.7vw;
      }
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f3efe9;
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.brown};
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      /* background: ${colors.lightMain}; */
    }
  }
  table {
    width: 100%;
    border-radius: 0.56vw;
    font-size: 1vw;
    background: white;
    border: 0.07vw solid #ebe8df;
    * {
      font-family: "Mulish", sans-serif;
    }
    @media (max-width: 768px) {
      border-radius: 1.67vw;
      width: 250vw;
      font-size: 3.733vw;
      line-height: 4.8vw;
    }
    thead {
      width: 100%;
      tr {
        width: 100%;
        display: flex;
        height: 4.3vw;
        padding: 0 2.15vw;
        padding-top: 2.15vw;
        border-bottom: 0.07vw solid #ebe8df;
        color: ${colors.darkBrown};
        font-weight: 600;
        @media (max-width: 768px) {
          height: 12.9vw;
          padding: 0 6.45vw;
          padding-top: 6.45vw;
          border-bottom: 0.21vw solid #ebe8df;
        }
        td {
          flex: 1;
          text-align: left;
          &.services-header {
            flex: 2.5;
            @media (max-width: 768px) {
              // flex: 3;
            }
          }
          &.balance-cell {
            display: flex;
            /* justify-content: center; */
          }
          &.name-cell {
            flex: 2;
            @media (max-width: 768px) {
              flex: 1.5;
            }
            /* display: flex; */
            /* justify-content: center; */
          }
        }
      }
    }
    tbody {
      display: block;
      /* max-height: 60vw; */
      /* overflow-y: auto; */
      @media (max-width: 768px) {
        /* max-height: 180vw; */
      }
    }
  }
`;

export default Family;
