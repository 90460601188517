import React from "react";
import styled from "styled-components";
import Intro from "../components/Intro";
import PmSection from "../components/PmSection";
import OurRole from "../components/OurRole";
import OurServices from "../components/OurServices";
import MissionAndVision from "../components/MissionAndVision";
import CeoMessage from "../components/CeoMessage";
import TaxCalc from "../components/TaxCalc";

const HomePage = () => {
  return (
    <Home>
      <Intro />
      <PmSection />
      <MissionAndVision />
      <OurRole />
      <OurServices />
      <TaxCalc />
      <CeoMessage />
    </Home>
  );
};
const Home = styled.div`
  min-height: 100vh;
`;
export default HomePage;
