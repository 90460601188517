import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import StyledPage from "../components/assets/StyledPage";
import { axiosAuth } from "../util/api/integration";
import colors from "../util/colors";
import { formatText } from "../util/utilFunctions";
import editIcon from "../images/edit.png";
import Button from "../components/assets/Button";
import history from "../util/api/history";
import CustomUpload from "../components/assets/CustomUpload";
const ManageListing = () => {
  const { id: pId } = useParams();
  const [details, setDetails] = useState({});
  const [addressDetails, setAddressDetails] = useState({});
  const [files, setFiles] = useState();
  const [edit, setEdit] = useState(false);
  const [submittingImages, setSubmittingImages] = useState(false);

  // const addressFields = [
  //   { name: "apart_no", displayName: "Apartment Number" },
  //   { name: "floor_no", displayName: "Floor Number" },
  //   { name: "building_no", displayName: "Building Number" },
  //   { name: "street" },
  //   { name: "area" },
  //   { name: "city" },
  //   { name: "country" },
  // ];

  const fields = [
    { name: "name1", displayName: "Property Name" },
    { name: "description" },
    {
      name: "sale_state",
      type: "select",
      options: [
        { name: "Available", value: "available" },
        { name: "Unavailable", value: "unavailable" },
        { name: "Sold", value: "sold" },
      ],
    },
    {
      name: "rent_state",
      type: "select",
      options: [
        { name: "Available", value: "available" },
        { name: "Unavailable", value: "unavailable" },
        { name: "Rented", value: "rented" },
      ],
    },
    {
      name: "property_category",
      type: "select",
      options: [
        { name: "Apartment", value: "apartment" },
        { name: "Villa", value: "villa" },
        { name: "Land", value: "land" },
      ],
    },
    {
      name: "rooms_count",
      displayName: "Number of rooms",
      type: "number",
      required: false,
    },
    {
      name: "bathrooms_count",
      displayName: "Number of bathrooms",
      type: "number",
      required: false,
    },
    {
      name: "floors_count",
      displayName: "Number of floors",
      type: "number",
      required: false,
    },
    { name: "area", displayName: "Area (Sqm)", type: "number" },
    { name: "value", type: "number" },
    // { name: "last_settlement_date", type: "date" },
  ];

  useEffect(() => {
    const getDetails = async () => {
      if (pId) {
        try {
          const res = await axiosAuth.get(`/properties/${pId}`);
          setDetails(res.data);
          setAddressDetails(res.data?.address);
        } catch (error) {
          alert(error.response?.data?.message || "Something went wrong!");
        }
      }
    };
    getDetails();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (pId) {
      const { id, address, ...rest } = details;
      try {
        const res = await axiosAuth.put(`/properties/${id}`, rest);
        console.log(res);
        alert("Property edited successfully.");
        history.push("/listings");
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await axiosAuth.post("/properties", {
          ...details,
          owner_name: "Admin",
        });
        console.log(res);
        alert("Property added successfully.");
        history.push("/listings");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("img", file);
    const res = await axiosAuth.post(`/properties/${pId}/uploadImage`, data);
    console.log(res);
  };

  const submitImages = async (e) => {
    e.preventDefault();
    if (files) {
      setSubmittingImages(true);
      try {
        for (let i = 0; i < Array.from(files).length; i++) {
          await uploadImage(Array.from(files)[i]);
        }
        alert("Images uploaded successfully");
        setFiles();
        setSubmittingImages(false);
      } catch (error) {
        console.log(error);
        setSubmittingImages(false);
      }
    }
  };

  return (
    <StyledPage lightMode backgroundColor="#FCFAF7" no-padding>
      <StyledContainer>
        {pId && (
          <form className="images-form" onSubmit={submitImages}>
            <CustomUpload
              files={files}
              setFiles={setFiles}
              label="Upload images"
              accept="image/png, image/gif, image/jpeg"
            />
            <input
              className="submit-btn"
              type="submit"
              disabled={submittingImages}
            />
          </form>
        )}
        <div className="listing-page-heading">
          <h1 className="fs30">{pId ? `Property #${pId}` : "Add Property"}</h1>
          {pId && !edit && (
            <img onClick={() => setEdit(true)} src={editIcon} alt="edit" />
          )}
        </div>
        <form id="listing" className="listing-form" onSubmit={submitHandler}>
          {fields.map((field) =>
            field.type === "select" ? (
              <div className="input-div" key={field.name}>
                <label htmlFor={field.name}>
                  {field.displayName || formatText(field.name)}
                  {field.required === false ? "" : "*"}
                </label>
                <select
                  required={field.required !== false ? true : false}
                  disabled={!edit && pId}
                  className="input-global"
                  value={details[field.name]}
                  onChange={(e) => {
                    setDetails({ ...details, [field.name]: e.target.value });
                  }}
                  name={field.name}
                  id={field.name}
                >
                  <option value=""></option>
                  {field.options &&
                    field.options.map((o) => (
                      <option value={o.value}>{o.name}</option>
                    ))}
                </select>
              </div>
            ) : (
              <div className="input-div" key={field.name}>
                <label htmlFor={field.name}>
                  {field.displayName || formatText(field.name)}
                  {field.required === false ? "" : "*"}
                </label>
                <input
                  required={field.required !== false ? true : false}
                  disabled={!edit && pId}
                  value={details[field.name]}
                  className="input-global"
                  onChange={(e) => {
                    setDetails({ ...details, [field.name]: e.target.value });
                  }}
                  type={field.type}
                  key={field.name}
                />
              </div>
            )
          )}
          {/* {addressFields.map((field) => (
            <div className="input-div" key={field.name}>
              <label htmlFor={field.name}>
                {field.displayName || formatText(field.name)}
              </label>
              <input
                disabled={!edit && id}
                value={addressDetails[field.name]}
                className="input-global"
                onChange={(e) => {
                  setAddressDetails({
                    ...addressDetails,
                    [field.name]: e.target.value,
                  });
                }}
                type={field.type}
                key={field.name}
              />
            </div>
          ))} */}
        </form>

        {((pId && edit) || !pId) && (
          <input
            className=" fs16 submit-btn"
            type="submit"
            form="listing"
            label={"Submit"}
          />
        )}
      </StyledContainer>
    </StyledPage>
  );
};

const StyledContainer = styled.div`
  padding: 2vw 0vw 0vw 6vw;
  padding-bottom: 3vw;
  .listing-page-heading {
    display: flex;
    align-items: center;
    gap: 1vw;
    margin-bottom: 3vw;
    img {
      width: 2vw;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .listing-form {
    padding-right: 3vw;
    gap: 1vw 2vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    .input-div {
      display: flex;
      flex-direction: column;
      &:not(:last-of-type) {
        margin-bottom: 1vw;
        @media (max-width: 768px) {
          margin-bottom: 4vw;
        }
      }
      input,
      select {
        padding-top: 0;
        padding-bottom: 0;
      }
      label {
        color: ${colors.brown};
        font-size: 0.76vw;
        @media (max-width: 768px) {
          font-size: 2.9vw;
        }
      }
    }
    button {
      background: ${colors.brown};
      margin-top: 5.35vw;
      margin-bottom: 0.76vw;
      &.disabled-button {
        background: ${colors.lightGrey};
      }
      @media (max-width: 768px) {
        margin-top: 29.3vw;
      }
    }
    a {
      align-self: flex-end;
    }
  }
  .images-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .submit-btn {
    margin-top: 3vw;
    width: 6vw;
    height: 3.33vw;
    font-size: 0.833vw;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
    color: ${colors.white};
    border-radius: 0.35vw;
    background: ${colors.brown};
    &:disabled {
      background: #b9b9b9;
    }
    @media (max-width: 768px) {
      height: 9.7vw;
      font-size: 2.9vw;
      border-radius: 1.33vw;
    }
  }
`;
export default ManageListing;
