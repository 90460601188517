import React, { useEffect } from "react";
import styled from "styled-components";
const Pagination = ({
  data,
  page,
  setPage,
  perPage,
  setPerPage,
  noScroll,
  className,
}) => {
  useEffect(() => {
    !noScroll &&
      window.scroll({
        top: 0,
        left: 0,
      });
  }, [data, noScroll]);

  const first = () => {
    if (page !== 1) {
      setPage(1);
    }
  };
  const last = () => {
    if (page !== data.pages) {
      setPage(data.pages);
    }
  };
  const next = () => {
    if (page < data.pages) {
      setPage((page) => page + 1);
    }
  };
  const previous = () => {
    if (page > 1) {
      setPage((page) => page - 1);
    }
  };
  return (
    <StyledPagination
      className={`${className || ""}${data.count === 0 ? "hidden" : ""}`}
    >
      <div className="pagination">
        <div title="First page" className="action first-page" onClick={first}>
          {"<<"}
        </div>
        <div title="Previous page" className="action" onClick={previous}>
          {"<"}
        </div>
        <p>
          {"Items "}
          {(data.page - 1) * data.per_page + 1}-
          {Math.min(data.page * data.per_page, data.count)} out of {data.count}
        </p>
        <div title="Next page" className="action" onClick={next}>
          {">"}
        </div>
        <div title="Last page" className="action" onClick={last}>
          {">>"}
        </div>
        <div className="options">
          <p>Entries per page: </p>
          <select
            name="entries"
            id="entries"
            onChange={(e) => {
              setPerPage(e.target.value);
              setPage(1);
            }}
            value={perPage}
          >
            <option value="1">1</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </div>
      </div>
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  &.hidden {
    display: none;
  }
  height: 3vw;
  font-size: 1vw;
  border-top: solid 0.07vw #e8e8e8;
  background: white;
  @media (max-width: 768px) {
    height: 9vw;
    font-size: 3vw;
    border-top: solid 0.21vw #e8e8e8;
  }
  div,
  p {
    margin-left: 1vw;
    @media (max-width: 768px) {
      margin-left: 3vw;
    }
  }
  .pagination {
    height: 100%;
    display: flex;
    padding: 0 8vw;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 768px) {
      padding: 0 24vw;
    }
    .action {
      cursor: pointer;
    }
  }
  .options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2vw;
    font-size: 1vw;
    @media (max-width: 768px) {
      padding: 0 6vw;
      font-size: 3vw;
    }

    select {
      background: none;
      border: none;
      border-bottom: 0.2vw grey solid;
      margin-left: 2vw;
      outline: none;
      font-size: 1vw;
      @media (max-width: 768px) {
        border-bottom: 0.6vw grey solid;
        margin-left: 6vw;
        font-size: 3vw;
      }
    }
  }
`;
export default Pagination;
