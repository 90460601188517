import jwt_decode from "jwt-decode";
import {
  clearUserData,
  updateLoggedInUser,
} from "../../redux/actions/loginAction";
// import store from "../../redux/store";
import store from "../../redux/store";
import history from "./history";
import { axiosAuth, axiosOMS } from "./integration";

export const login = (credentials, firstLogin = false) => {
  return new Promise((resolve, reject) => {
    axiosOMS
      .post("/login", credentials)
      .then((res) => {
        console.log("login res", res);
        // updating local storage
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("uid", res.data.UserID);
        if (jwt_decode(res.data.access_token).user_type === "admin")
          localStorage.setItem("isAdmin", true);
        // getting user data
        getUserData()
          .then((userData) => {
            store.dispatch(updateLoggedInUser(userData));
            history.replace(
              `${localStorage.getItem("isAdmin") ? "/users" : "/"}`
            );
          })
          .catch((error) => reject(error));
        resolve(res.data);
        history.replace("/");
      })

      .catch((error) => {
        // alert(error.response?.data?.message || "Something went wrong!");
        reject(error);
      });
  });
};

export const getUserData = () => {
  return new Promise((resolve, reject) => {
    axiosAuth
      .get("/me")
      .then((res) => resolve(res.data))
      .catch((error) => {
        alert(error.response?.data?.message || "Something went wrong!");
        reject(error);
      });
  });
};

export const register = (info) => {
  return new Promise((resolve, reject) => {
    axiosOMS
      .post("/register", info)
      .then((res) => {
        resolve(res);
        alert(res.data.message);
        history.push("/users");
      })
      .catch((error) => {
        reject(error);
        alert(error.response?.data?.message || "Error has occured");
      });
  });
};

export const logout = () => {
  history.replace("/login");
  localStorage.clear();
  store.dispatch(clearUserData());
};

export const getValidToken = (currentToken, refreshToken, currentDecoded) => {
  return new Promise((resolve, reject) => {
    const expiryDate = currentDecoded.exp * 1000;
    const currentDate = new Date().getTime();
    if (expiryDate < currentDate) {
      console.log("expired token");
      axiosOMS
        .get("/refresh", {
          headers: { Authorization: `Bearer ${refreshToken}` },
        })
        .then((res) => {
          localStorage.setItem("token", res.data["access_token"]);
          console.log("Token has been renewed");
          resolve(res.data["access_token"]);
        })
        .catch((err) => reject(err));
    } else {
      console.log("valid token");
      resolve(currentToken);
    }
  });
};

export const changePassword = (info) => {
  return new Promise((resolve, reject) => {
    axiosOMS
      .patch("/changepassword", info, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("reset_token")}`,
        },
      })
      .then((res) => {
        localStorage.clear();
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("uid", res.data.UserID);
        getUserData()
          .then((userData) => {
            store.dispatch(updateLoggedInUser(userData));
            history.replace("/");
            resolve(userData);
          })
          .catch((error) => {
            alert(error.response?.data?.message || "Something went wrong!");
            reject(error);
          });
      });
  });
};
