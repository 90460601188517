import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import StyledPage from "../components/assets/StyledPage";
import PropertyCard from "../components/properties/PropertyCard";
import colors from "../util/colors";
import { useLocation } from "react-router-dom";
import { leaseApartments, saleApartments } from "../util/apartments";
import { axiosAuth } from "../util/api/integration";
import usePropertiesFetch from "../components/assets/hooks/usePropertiesFetch";
import { Loading } from "../components/assets/icons/icons";

const Properties = () => {
  const { pathname } = useLocation();
  const [option, setOption] = useState(pathname.split("/")[1]);
  const [endpoint, setEndpoint] = useState(
    `/properties?${
      option === "lease-properties"
        ? "rent_state=available"
        : "sale_state=available"
    }`
  );

  useEffect(() => {
    setOption(pathname.split("/")[1]);

    setEndpoint(
      `/properties?${
        option === "lease-properties"
          ? "rent_state=available"
          : "sale_state=available"
      }`
    );
  }, [pathname, option]);

  const [pageNumber, setPageNumber] = useState(1);

  const { loading, error, properties, hasMore } = usePropertiesFetch(
    pageNumber,
    endpoint,
    null,
    setPageNumber
  );

  const observer = useRef();
  const lastPropertyRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber((n) => n + 1);
          }
        },
        { threshold: 0.5 }
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <StyledPage no-padding>
      <StyledContainer>
        <h1 className="properties-heading fs36">
          {option === "lease-properties"
            ? "Properties for lease"
            : "Properties for sale"}
        </h1>
        {properties.map((property, index) => {
          if (properties.length === index + 1) {
            return (
              <PropertyCard
                key={Math.random(0, 10000)}
                property={property}
                type={option}
                MyRef={lastPropertyRef}
              />
            );
          } else {
            return (
              <PropertyCard
                key={Math.random(0, 10000)}
                property={property}
                type={option}
              />
            );
          }
        })}
        {/* <PropertyCard key={Math.random(0, 10000)} /> */}
        <p className={`loading ${!loading ? "hidden" : ""}`}>
          LOADING.. <Loading />
        </p>
      </StyledContainer>
    </StyledPage>
  );
};
const StyledContainer = styled.div`
  .properties-heading {
    color: ${colors.brown};
    margin: 0 0 3.7vw 6.9vw;
  }
  .loading {
    z-index: 10;
    transition: max-height 1s linear;
    overflow: hidden;
    width: 100%;
    display: flex;
    background: #af916d;
    color: #e5e5e5;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0vw;
    left: 0;
    font-size: 1vw;
    max-height: 100vw;
    &.hidden {
      /* opacity: 0; */
      max-height: 0vw;
    }
    svg {
      width: 3vw;
    }
    @media (max-width: 768px) {
      font-size: 3vw;
      svg {
        width: 9vw;
      }
    }

    /* color: black; */
  }
`;
export default Properties;
