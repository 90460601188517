import React, { useState } from "react";
import styled from "styled-components";
import bg from "../../images/bgsecondary.jpg";
import { register } from "../../util/api/authFunctions";
import validateInfo from "../../util/api/registerValidation";
import colors from "../../util/colors";
import StyledPage from "../assets/StyledPage";
import SubmitButton from "../assets/SubmitButton";
const Register = () => {
  const [personalInfo, setPersonalInfo] = useState({
    name: "",
    email: "",
    password: "123456",
    mobile_no: "",
    UserID: "",
  });
  const [errors, setErrors] = useState([]);

  //Handler
  const submitHandler = (e) => {
    e.preventDefault();
    setErrors([]);
    let valErrors = validateInfo(personalInfo);
    if (valErrors.length) {
      setErrors(valErrors);
      return;
    } else {
      register(personalInfo).then((res) => {
        setPersonalInfo({
          name: "",
          email: "",
          mobile_no: "",
          UserID: "",
          family_id: "",
          user_type: "",
        });
      });
    }
  };
  return (
    <StyledPage no-padding background={bg}>
      <StyledContainer>
        <h1 className="fs30">Register</h1>
        <form onSubmit={submitHandler}>
          <div className="form-item">
            <label htmlFor="name">Name *</label>
            <input
              required
              className="input-global"
              type="text"
              id="name"
              onChange={(e) =>
                setPersonalInfo({
                  ...personalInfo,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="form-item">
            <label htmlFor="email">Email *</label>
            <input
              required
              className="input-global"
              type="email"
              id="email"
              value={personalInfo.email}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, email: e.target.value })
              }
            />
          </div>
          <div className="form-item">
            <label htmlFor="mobile_no">Mobile Number</label>
            <input
              // required
              className="input-global"
              type="text"
              id="mobile_no"
              value={personalInfo.mobile_no}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, mobile_no: e.target.value })
              }
            />
          </div>
          <div className="form-item">
            <label htmlFor="UserID">User ID *</label>
            <input
              required
              className="input-global"
              type="text"
              id="UserID"
              value={personalInfo.UserID}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, UserID: e.target.value })
              }
            />
          </div>
          <div className="form-item">
            <label htmlFor="family_id">Family ID</label>
            <input
              className="input-global"
              type="text"
              id="family_id"
              value={personalInfo.family_id}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, family_id: e.target.value })
              }
            />
          </div>
          <div className="form-item">
            <label htmlFor="user_type">User Type *</label>
            <select
              required
              className="input-global"
              type="text"
              id="user_type"
              value={personalInfo.user_type}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, user_type: e.target.value })
              }
            >
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
          </div>
          <SubmitButton label="Sign up" />
        </form>
        {/* <p>
          Already have an account ?{" "}
          <span>
            <Link to="/login">Sign in</Link>
          </span>
        </p> */}
      </StyledContainer>
    </StyledPage>
  );
};
const StyledContainer = styled.div`
  background: #fbfbfd;
  width: 33.5vw;
  padding: 2.8vw 2.08vw 2.43vw 2.29vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  align-self: center;
  margin-bottom: 13.2vw;
  border-radius: 0.35vw;

  @media (max-width: 768px) {
    width: 79.7vw;
    margin-bottom: 33.9vw;
    margin-top: 30.4vw;
    padding: 10.9vw 7.8vw 10.13vw 8.2vw;
    border-radius: 1.33vw;
  }
  h1 {
    color: ${colors.brown};
    margin-bottom: 1.9vw;
    @media (max-width: 768px) {
      margin-bottom: 12vw;
    }
  }
  a {
    text-decoration: none;
    color: ${colors.brown};
    font-size: 0.9vw;
    @media (max-width: 768px) {
      font-size: 3.5vw;
    }
  }
  p {
    font-size: 0.9vw;
    @media (max-width: 768px) {
      font-size: 3.5vw;
      margin-top: 1.3vw;
    }
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
    .form-item {
      display: flex;
      flex-direction: column;
      &:not(:last-of-type) {
        margin-bottom: 1vw;
        @media (max-width: 768px) {
          margin-bottom: 4vw;
        }
      }
      label {
        color: ${colors.brown};
        font-size: 0.76vw;
        @media (max-width: 768px) {
          font-size: 2.9vw;
        }
      }
      select {
        padding: 0;
      }
    }
    button {
      background: ${colors.brown};
      margin-top: 5.35vw;
      margin-bottom: 0.76vw;
      @media (max-width: 768px) {
        margin-top: 29.3vw;
      }
    }
    a {
      align-self: flex-end;
    }
  }
  #reset-pw {
    margin-top: 0.35vw;
    @media (max-width: 768px) {
      margin-top: 1.33vw;
    }
  }
`;
export default Register;
