import React from "react";
import styled from "styled-components";
import colors from "../util/colors";
import BorderLine from "./BorderLine";

const MissionAndVision = () => {
  return (
    <StyledSection>
      <div className="mission" id="mission">
        <h1>Mission</h1>
        <p>
          To increase our client’s revenue and maximize their asset value by
          providing various financial solutions.
        </p>
      </div>
      <div className="vision" id="vision">
        <h1>Vision</h1>
        <p>
          To become a regional leader by providing FinTech solutions that would
          bring ongoing value to various segments.
        </p>
      </div>
      <BorderLine />
    </StyledSection>
  );
};

const StyledSection = styled.div`
  position: relative;
  margin-top: 3.33vw;
  padding-bottom: 5.33vw;
  width: 84.5vw;
  @media (max-width: 768px) {
    width: 85.33vw;
    margin: auto;
    margin-top: 21.3vw;
  }

  .mission {
    margin-bottom: 2.9vw;
    margin-left: 8.2vw;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
  .vision {
    margin-left: 8.2vw;
    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 8vw;
    }
  }
  h1 {
    color: ${colors.brown};
    font-size: 3.5vw;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 6.7vw;
      line-height: 12.5vw;
      margin-bottom: 6.7vw;
    }
  }
  p {
    color: ${colors.lightGrey};
    font-size: 1.74vw;
    line-height: 2.64vw;
    @media (max-width: 768px) {
      font-size: 3.2vw;
      line-height: 6vw;
      margin-left: 0;
    }
  }
`;
export default MissionAndVision;
