import React from "react";
import styled from "styled-components";
import { statsItems } from "../../util/api/data";
import colors from "../../util/colors";
import { LogItem } from "./DashboardPropertyCard";

const FamilyStats = ({ familyStats, title }) => {
  return (
    <StyledSection>
      <LogItem
        className="title-item"
        noAmount
        itemName={title || "Family Service Summary"}
      />
      <div className="summary-container">
        {statsItems.map((item) => (
          <LogItem
            key={item}
            className="log-item"
            itemName={item}
            itemAmount={familyStats[item] || 0}
          />
        ))}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  border: solid 0.07vw #dfe0eb;
  border-radius: 0.56vw;
  border-top: none;
  overflow: hidden;
  margin-bottom: 2vw;
  background: white;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  .title-item {
    color: ${colors.darkBrown};
    @media (max-width: 768px) {
      // height: auto;
      font-size: 3.2vw;
    }
  }
  .summary-container {
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .log-item {
      @media (max-width: 768px) {
        // height: auto;
        font-size: 3.2vw;
      }
    }
  }
`;

export default FamilyStats;
