import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Button from "./Button";

const StyledPage = ({ children, scroll, ...props }) => {
  const [windowScroll, setWindowScroll] = useState(0);

  useEffect(() => {
    for (let item of document
      .getElementById("main-nav-links")
      .getElementsByTagName("a")) {
      item.style.color = props.lightMode ? `${colors.black}` : "";
    }
    return () => {
      for (let item of document
        .getElementById("main-nav-links")
        .getElementsByTagName("a")) {
        item.style.color = "";
      }
    };
  }, [props.lightMode]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setWindowScroll(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setWindowScroll(window.scrollY);
      });
    };
  }, []);

  return (
    <Page {...props}>
      {children}
      {scroll && windowScroll > 0 && (
        <Button
          onClick={() => {
            window.scroll(0, 0);
          }}
          className="scroll-btn"
          label="^"
        />
      )}
    </Page>
  );
};

const Page = styled.div`
  min-height: 100vh;
  background-image: ${(props) =>
    props.background ? `url(${props.background})` : ""};
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ""};
  background-size: cover;
  background-repeat: no-repeat;
  padding: ${(props) =>
    props["no-padding"] ? "12.26vw 0vw 0vw 0vw" : " 23.26vw 0vw 0vw 6.94vw"};
  display: flex;
  flex-direction: column;
  .scroll-btn {
    position: fixed;
    bottom: 3vw;
    right: 1vw;
    width: 2vw;
    height: 2vw;
    background: ${colors.brown};
    @media (max-width: 768px) {
      width: 6vw;
      height: 6vw;
      bottom: 6vw;
      right: 2vw;
    }
  }
  @media (max-width: 768px) {
    padding: ${(props) =>
      props["no-padding"] ? "32.26vw 0vw 0vw 0vw" : " 23.26vw 0vw 0vw 6.94vw"};
  }
`;

export default StyledPage;
