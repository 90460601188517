import React from "react";
import styled from "styled-components";
// import { colors } from "../../util/colors";
import colors from "../../util/colors";
import { Link } from "react-router-dom";
const Button = ({ className, label, onClick, link }) => {
  if (label) label = label.toUpperCase();
  if (onClick) {
    return (
      <StyledButton className={className} onClick={onClick}>
        {label}
      </StyledButton>
    );
  } else if (link) {
    return (
      <Link to={link}>
        <StyledButton className={className}>{label}</StyledButton>
      </Link>
    );
  } else {
    return <StyledButton className={className}>{label}</StyledButton>;
  }
};

export const StyledButton = styled.button`
  height: 3.33vw;
  font-size: 0.833vw;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${colors.white};
  border-radius: 0.35vw;

  @media (max-width: 768px) {
    height: 9.7vw;
    font-size: 2.9vw;
    border-radius: 1.33vw;
  }
`;
export default Button;
