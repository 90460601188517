import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import bg from "../../images/bgsecondary.jpg";
import { changePassword } from "../../util/api/authFunctions";
import validateInfo from "../../util/api/registerValidation";
import colors from "../../util/colors";
import StyledPage from "../assets/StyledPage";
import SubmitButton from "../assets/SubmitButton";
const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    password1: "",
    password2: "",
  });
  const [errors, setErrors] = useState([]);

  //Handler
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    setErrors([]);
    let valErrors = validateInfo(info);
    if (valErrors.length) {
      setErrors(valErrors);
      setLoading(false);
      return;
    } else {
      changePassword(info)
        .then((res) => {
          setInfo({
            password1: "",
            password2: "",
          });
          setLoading(false);
        })
        .catch((error) => {
          alert(error.response?.data?.message || "Something went wrong!");
          setLoading(false);
        });
    }
  };
  // useEffect(() => {
  //   !localStorage.getItem("reset_token") && history.push("/login");
  //   return () => {
  //     localStorage.clear();
  //   };
  // }, []);
  return (
    <StyledPage no-padding background={bg}>
      <StyledContainer>
        <h1 className="fs30">CHANGE PASSWORD</h1>
        <form onSubmit={submitHandler}>
          <div className="form-item">
            <label htmlFor="password">Password</label>
            <input
              required
              className="input-global"
              type="password"
              id="password"
              value={info.password1}
              onChange={(e) =>
                setInfo({
                  ...info,
                  password1: e.target.value,
                })
              }
            />
          </div>
          <div className="form-item">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              className="input-global"
              type="password"
              id="confirm-password"
              value={info.password2}
              onChange={(e) =>
                setInfo({
                  ...info,
                  password2: e.target.value,
                })
              }
              required
            />
          </div>
          <SubmitButton
            className={`${loading ? "disabled-button" : ""}`}
            disabled={loading}
            label={`${loading ? "Loading ..." : "Submit"}`}
          />
        </form>
        <p>
          Already changed your password ?{" "}
          <span>
            <Link to="/login">Sign in</Link>
          </span>
        </p>
      </StyledContainer>
    </StyledPage>
  );
};
const StyledContainer = styled.div`
  background: #fbfbfd;
  width: 33.5vw;
  padding: 2.8vw 2.08vw 2.43vw 2.29vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  align-self: center;
  margin-bottom: 13.2vw;
  border-radius: 0.35vw;

  @media (max-width: 768px) {
    width: 79.7vw;
    margin-bottom: 33.9vw;
    margin-top: 30.4vw;
    padding: 10.9vw 7.8vw 10.13vw 8.2vw;
    border-radius: 1.33vw;
  }
  h1 {
    color: ${colors.brown};
    margin-bottom: 1.9vw;
    @media (max-width: 768px) {
      margin-bottom: 12vw;
    }
  }
  a {
    text-decoration: none;
    color: ${colors.brown};
    font-size: 0.9vw;
    @media (max-width: 768px) {
      font-size: 3.5vw;
    }
  }
  p {
    font-size: 0.9vw;
    @media (max-width: 768px) {
      font-size: 3.5vw;
      margin-top: 1.3vw;
    }
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
    .form-item {
      display: flex;
      flex-direction: column;
      &:not(:last-of-type) {
        margin-bottom: 1vw;
        @media (max-width: 768px) {
          margin-bottom: 4vw;
        }
      }
      label {
        color: ${colors.brown};
        font-size: 0.76vw;
        @media (max-width: 768px) {
          font-size: 2.9vw;
        }
      }
    }
    button {
      background: ${colors.brown};
      margin-top: 5.35vw;
      margin-bottom: 0.76vw;
      &.disabled-button {
        background: ${colors.lightGrey};
      }
      @media (max-width: 768px) {
        margin-top: 29.3vw;
      }
    }
    a {
      align-self: flex-end;
    }
  }
  #reset-pw {
    margin-top: 0.35vw;
    @media (max-width: 768px) {
      margin-top: 1.33vw;
    }
  }
`;
export default ChangePassword;
