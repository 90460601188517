import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import bg from "../../images/bgsecondary.jpg";
import { login } from "../../util/api/authFunctions";
import history from "../../util/api/history";
import colors from "../../util/colors";
import StyledPage from "../assets/StyledPage";
import SubmitButton from "../assets/SubmitButton";

const SignIn = () => {
  const { UserID } = useSelector((state) => state.user);
  useEffect(() => {
    if (UserID)
      history.push(localStorage.getItem("isAdmin") ? "/users" : "/dashboard");
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [creds, setCreds] = useState({ email: "", password: "" });
  const loginHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    login(creds)
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        // alert(error.response?.data?.message || "Something went wrong!");
        // setLoginFailed(true);
        if (
          error.response?.data?.message ===
          "You have not changed your password, please change your password first!"
        ) {
          localStorage.clear();
          localStorage.setItem(
            "reset_token",
            error.response?.data?.reset_token
          );
          history.push("/change-password");
          return;
        }
        // err.response?.data?.message ===
        // "You have not changed your password, please change your password first!"
        //   ? history.push("/change")
        //   : "";
        setErrorMessage(error.response?.data?.message);
        setLoading(false);
      });
  };
  return (
    <StyledPage no-padding background={bg}>
      <StyledContainer>
        <h1 className="fs30">Log in to your account</h1>
        <form onSubmit={loginHandler}>
          <div className="form-item">
            <label htmlFor="email">Email</label>
            <input
              required
              className="input-global"
              type="email"
              id="email"
              value={creds.email}
              onChange={(e) => {
                setCreds({ ...creds, email: e.target.value });
              }}
            />
          </div>
          <div className="form-item">
            <label htmlFor="password">Password</label>
            <input
              required
              className="input-global"
              type="password"
              id="password"
              value={creds.password}
              onChange={(e) => {
                setCreds({ ...creds, password: e.target.value });
              }}
            />
          </div>
          <Link to="/reset" id="reset-pw">
            Forgot password
          </Link>
          <p className="error-message">{errorMessage ? errorMessage : ""}</p>
          <SubmitButton
            disabled={loading}
            className={`${loading ? "disabled-button" : ""}`}
            label={`${loading ? "Loading ..." : "Sign in"}`}
          />
        </form>
        {/* <p>
          New here?{" "}
          <span>
            <Link to="/register">Create a new account</Link>
          </span>
        </p> */}
      </StyledContainer>
    </StyledPage>
  );
};
const StyledContainer = styled.div`
  background: #fbfbfd;
  width: 33.5vw;
  height: 33.5vw;
  padding: 2.8vw 2.08vw 2.43vw 2.29vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  align-self: center;
  margin-bottom: 13.2vw;
  border-radius: 0.35vw;

  @media (max-width: 768px) {
    width: 79.7vw;
    height: 129.1vw;
    margin-bottom: 33.9vw;
    margin-top: 30.4vw;
    padding: 10.9vw 7.8vw 10.13vw 8.2vw;
    border-radius: 1.33vw;
  }
  h1 {
    color: ${colors.brown};
    margin-bottom: 1.9vw;
    @media (max-width: 768px) {
      margin-bottom: 12vw;
    }
  }
  a {
    text-decoration: none;
    color: ${colors.brown};
    font-size: 0.9vw;
    @media (max-width: 768px) {
      font-size: 3.5vw;
    }
  }
  p {
    font-size: 0.9vw;
    &.error-message {
      color: red;
      margin-top: 2vw;
      text-align: center;
      @media (max-width: 768px) {
        margin-top: 3vw;
      }
    }
    @media (max-width: 768px) {
      font-size: 3.5vw;
      margin-top: 1.3vw;
    }
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
    .form-item {
      display: flex;
      flex-direction: column;
      &:not(:last-of-type) {
        margin-bottom: 1vw;
        @media (max-width: 768px) {
          margin-bottom: 4vw;
        }
      }
      label {
        color: ${colors.brown};
        font-size: 0.76vw;
        @media (max-width: 768px) {
          font-size: 2.9vw;
        }
      }
    }
    button {
      background: ${colors.brown};
      margin-top: 5.35vw;
      margin-bottom: 0.76vw;
      &.disabled-button {
        background: ${colors.lightGrey};
      }
      @media (max-width: 768px) {
        margin-top: 29.3vw;
      }
    }
    a {
      align-self: flex-end;
    }
  }
  #reset-pw {
    margin-top: 0.35vw;
    @media (max-width: 768px) {
      margin-top: 1.33vw;
    }
  }
`;
export default SignIn;
