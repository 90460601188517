import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../util/colors";
import Amount from "../assets/Amount";
import Label from "../assets/Label";
const Transactions = () => {
  const transactions = useSelector((state) => state.user.transactions);
  const isFamilyMember = useSelector((state) => state.user.family_id);
  return (
    <StyledTransactions>
      <div className="title-bar">
        <h1>
          Transactions
          <span>
            <Link
              to={isFamilyMember ? "/all-transactions" : "/my-transactions"}
            >
              (View All)
            </Link>
          </span>
        </h1>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <td>Type</td>
              <td>Date</td>
              <td>Details</td>
              <td>Property</td>
              <td>Amount</td>
              <td>Balance</td>
            </tr>
          </thead>
          {transactions && (
            <tbody>
              {transactions.map((transaction) => (
                <tr key={`transaction.unit_code ${Math.random(10000)}`}>
                  <td className="type">
                    {transaction.amount > 0 ? (
                      <Label className="credit">Credit</Label>
                    ) : (
                      <Label className="debit" backgroundColor="red">
                        Debit
                      </Label>
                    )}
                  </td>
                  <td>{transaction.date}</td>
                  <td>{`${transaction.name} (${transaction.code})`}</td>
                  <td>{transaction.property_name}</td>
                  <td className="amount">
                    <Amount>{transaction.amount}</Amount>
                  </td>
                  <td className="table-balance">
                    <Amount table>{transaction.ending_balance}</Amount>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </StyledTransactions>
  );
};
const StyledTransactions = styled.div`
  @media (max-width: 768px) {
    margin-top: 6.4vw;

    .title-bar {
      margin-bottom: 3.2vw;
      h1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 4.8vw;
        line-height: 6.133vw;
        span {
          font-size: 3.733vw;
          font-weight: 700;
          line-height: 4.8vw;
        }
      }
    }
  }
  .table-container {
    width: 100%;
    overflow-x: auto;
    @media (max-width: 768px) {
      margin-bottom: 6vw;
    }
    /* Customizing scroll bar */
    ::-webkit-scrollbar {
      width: 0.55vw;
      height: 1vw;
      @media (max-width: 768px) {
        width: 1.7vw;
      }
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f3efe9;
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.brown};
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      /* background: ${colors.lightMain}; */
    }
  }

  table {
    position: relative;
    /* width: 83.6vw; */
    width: 100%;
    background: white;
    overflow-y: hidden;
    border-radius: 2.1vw;
    margin-bottom: 3vw;
    padding: 1vw;
    font-size: 0.9vw;
    /* margin-top: 1.04vw; */
    @media (max-width: 768px) {
      border-radius: 6vw;
      margin-bottom: 0vw;
      padding: 3vw;
      font-size: 3.733vw;
      /* margin-top: 3.12vw; */
      width: 250vw;
    }
    thead {
      color: #334d6e;
      font-weight: 700;
    }
    tbody {
      * {
        font-family: "Poppins", sans-serif;
      }
      display: block;
      /* max-height: 30vw; */
      /* overflow-y: scroll; */
      @media (max-width: 768px) {
        /* max-height: 90vw; */
      }

      /* Customizing scroll bar */
      ::-webkit-scrollbar {
        width: 0.55vw;
        height: 1vw;
        @media (max-width: 768px) {
          width: 1.7vw;
        }
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f3efe9;
        border-radius: 1vw;
        @media (max-width: 768px) {
          border-radius: 3vw;
        }
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${colors.brown};
        border-radius: 1vw;
        @media (max-width: 768px) {
          border-radius: 3vw;
        }
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        /* background: ${colors.lightMain}; */
      }
      td {
        /* color: #707683;
      &.credit {
        color: #7fb089;
      }
      &.debit {
        color: #b06d77;
      }
      */
        &.table-balance {
          font-weight: 600;
        }
      }
    }

    // general rows and cells styling
    tr {
      width: 100%;
      height: 3.7vw;
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        height: 11.1vw;
      }
    }
    td {
      flex: 1;
      // text-align: center;
      &:nth-child(1) {
        flex: 0.7;
      
      }
      &:nth-child(2) {
      
          flex: 0.7;
        
      }
      &:nth-child(3) {
        flex: 1.5;
       
      }
      &:nth-child(4) {
        flex: 1.5;
       
      }
      .credit {
        width: 5.317vw;
        @media (max-width: 768px) {
          width: 20.8vw;
        }
      }
      .debit {
        width: 5.317vw;
        @media (max-width: 768px) {
          width: 18.133vw;
        }
      }
    }
    .scroll-top-button {
      background: ${colors.brown};
      position: absolute;
      top: 1vw;
      right: 1vw;
      width: 1vw;
      height: 1vw;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 0.1vw;
      font-size: 0.8vw;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export default Transactions;
