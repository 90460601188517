import React from "react";
import { StyledButton } from "./Button";

const SubmitButton = ({ className, label, ...rest }) => {
  if (label) label = label.toUpperCase();
  return (
    <StyledButton className={className} type="submit" {...rest}>
      {label}
    </StyledButton>
  );
};

export default SubmitButton;
