import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

.fs30 {
  font-size: 2.08vw;
  @media (max-width: 768px) {
    font-size: 6.67vw;
  }
}
.fs36 {
  font-size: 2.5vw;
  @media (max-width: 768px) {
    font-size: 6.67vw;
  }
}

.fs25{
    font-size: 1.74vw;
  @media (max-width: 768px) {
    font-size: 3.73vw;
  }
}
.fs20{
    font-size: 1.39vw;
  @media (max-width: 768px) {
    font-size: 5.33vw;
  }
}
.fs16{
    font-size: 1.11vw;
  /* @media (max-width: 768px) {
    font-size: 3.73vw;
  } */
}
.fw600{
  font-weight: 600;
}

.input-global {
  height: 2.14vw;
  border: solid 0.05vw #e3e3e3;
  outline: none;
  padding: 0.6vw;
  font-size: 0.83vw;
  @media (max-width: 768px) {
    height: 9.66vw;
    font-size: 2.9vw;
  }

  &::placeholder {
    font-size: 0.83vw;
    @media (max-width: 768px) {
      font-size: 2.9vw;
    }
  }
}

.mobile-only{
  @media (min-width: 769px) {
  display: none !important;
  }
}

.desktop-only {
 @media (max-width: 768px) {
  display: none !important;
  }
}

`;
export default GlobalStyles;
