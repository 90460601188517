import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "../../assets/Modal";
import history from "../../../util/api/history";

const ConfirmDelete = ({ onConfirm, message, ...props}) => {
  return (
    <Modal urlKey={"confirm"} history={history}>
      <StyledModal>
        <p className="header">{message}</p>
        <div className="modal-buttons">
          <button
            className="cancel"
            onClick={() => {
              history.push(history.location.pathname);
            }}
          >
            Cancel
          </button>
          <button className="confirm" onClick={onConfirm}>
           {props.confirm_text || "Yes, Delete"}
          </button>
        </div>
      </StyledModal>
    </Modal>
  );
};
const StyledModal = styled.div`
  padding: 1.667vw;
  width: 31.25vw;
  // height: 9.514vw;
  border-radius: 0.347vw;
  border: 0.069vw solid #dcdcdc;
  background: #fff;
  .header {
    color: #010101;
    font-family: Mulish;
    font-size: 1.389vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.736vw;
    margin-bottom: 1.667vw;
    @media (min-width: 1440px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  .modal-buttons {
    width: 100%;
    display: flex;
    gap: 1.667vw;
    justify-content: center;
    button {
      width: 9.306vw;
      border-radius: 0.278vw;
      text-align: center;
      font-family: Mulish;
      font-size: 1.111vw;
      font-style: normal;
      font-weight: 600;
      line-height: 1.389vw;
      height: 2.778vw;
      cursor : pointer;
      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .cancel {
      border: 0.069vw solid var(--brown, #af916d);
      color: var(--brown, #af916d);
    }

    .confirm {
      border: 0.069vw solid var(--brown, #af916d);
      background: var(--red, #b06d77);
      color: var(--white, #fff);
    }
  }
`;

export default ConfirmDelete;
