import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../images/logo.png";
import NavLinks from "./NavLinks";
import Cta from "./Cta";
import { Link } from "react-router-dom";
import feather from "../images/feather.svg";
import colors from "../util/colors";
import { useLocation } from "react-router-dom";
const Nav = () => {
  const location = useLocation();
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  useEffect(() => {
    setMobileNavVisible(false);
  }, [location]);
  return (
    <StyledNav>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="equity-logo" />
        </Link>
      </div>
      <div className="nav">
        <NavLinks id="main-nav-links" />
        <Cta />
      </div>

      <img
        src={feather}
        className={`feather ${mobileNavVisible ? "rotated" : ""}`}
        alt=""
        onClick={() => setMobileNavVisible(!mobileNavVisible)}
      />
      <div className={`mobile-nav ${mobileNavVisible ? "" : "hidden-nav"}`}>
        <Cta />
        <NavLinks />
      </div>
    </StyledNav>
  );
};
const StyledNav = styled.nav`
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  padding: 3.75vw 8.2vw;
  height: 13.26vw;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    height: 23.6vw;
  }
  .logo {
    img {
      width: 8.2vw;
      @media (max-width: 768px) {
        width: 21.33vw;
      }
    }
  }
  .nav {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      display: none;
    }
  }
  .mobile-nav {
    display: none;
    @media (max-width: 768px) {
      transition: all 0.2s ease-in;
      overflow: hidden;
      background: ${colors.black};
      box-shadow: 0vw 0vw 2vw ${colors.brown};
      /* border: solid 0.5vw ${colors.brown}; */
      border-radius: 2vw;
      padding: 3vw;
      max-height: 100vw;
      display: unset;
      position: absolute;
      right: 10vw;
      top: 16.5vw;
      z-index: 10;
      &.hidden-nav {
        /* display: none; */
        max-height: 0;
        padding: 0;
        overflow: hidden;
        box-shadow: none;

        a {
          opacity: 0;
        }
      }
    }
  }
  .feather {
    display: none;
    @media (max-width: 768px) {
      transition: all 0.2s ease-in;
      display: unset;
      width: 8.5vw;
      &.rotated {
        transform: rotate(-90deg);
      }
    }
  }
`;

export default Nav;
