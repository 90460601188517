import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import LoadingPage from "../../pages/LoadingPage";
import { updateLoggedInUser } from "../../redux/actions/loginAction";
import store from "../../redux/store";
import { getUserData } from "../../util/api/authFunctions";
import { axiosAuth } from "../../util/api/integration";
import colors from "../../util/colors";
import { getFamilyStats } from "../../util/utilFunctions";
import BlankDashboard from "./BlankDashboard";
import Family from "./Family";
import FamilyStats from "./FamilyStats";
import Overview from "./Overview";
import Properties from "./Properties";
import Transactions from "./Transactions";

const DashboardDetails = () => {
  const { properties } = useSelector((state) => state.user);
  const isFamilyMember = useSelector((state) => state.user.family_id);
  const [family, setFamily] = useState();
  const [familyStats, setFamilyStats] = useState();
  const [loading, setLoading] = useState(false);
  const stats = useSelector((state) => state.user.services_stats);
  useEffect(() => {
    const updateUserData = async () => {
      const userData = await getUserData();
      store.dispatch(updateLoggedInUser(userData));
    };
    updateUserData();
  }, []);

  useEffect(() => {
    const fetchFamily = async () => {
      if (isFamilyMember) {
        setLoading(true);
        try {
          const response = await axiosAuth.get("/me/family");
          setFamily(response.data);
          setFamilyStats(getFamilyStats(response.data));
          setLoading(false);
        } catch (error) {
          alert(error.response?.data?.message || "Something went wrong!");
          setLoading(false);
        }
      }
    };
    fetchFamily();
  }, []);
  if (loading) {
    return <LoadingPage />;
  } else {
    return properties.length === 0 ? (
      <BlankDashboard />
    ) : (
      <StyledDashboard>
        <Overview />
        {isFamilyMember ? (
          <Family family={family} familyStats={familyStats} />
        ) : (
          <FamilyStats familyStats={stats} title="Services Summary" />
        )}
        <Properties />
        <Transactions />
      </StyledDashboard>
    );
  }
};

const StyledDashboard = styled.div`
  width: 82.6vw;
  margin: auto;
  position: relative;
  @media (max-width: 768px) {
    width: 91.467vw;
  }
  .title-bar {
    margin-bottom: 2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .search-section {
      display: flex;
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 11.5vw;
    }
    a {
      color: ${colors.brown};
      font-size: 1.38vw;
      margin-left: 0.7vw;
      @media (max-width: 768px) {
        font-size: 3vw;
        margin-left: 2.1vw;
      }
    }
  }
`;
export default DashboardDetails;
