export const getUnitStatus = (rent_state, sale_state) => {
  if (sale_state === "sold") return "Sold";
  if (rent_state === "rented") return "Rented";
  if (rent_state === "available" && sale_state === "available")
    return "Available for rent and sale";
  if (rent_state === "available") return "Available for rent";
  if (sale_state === "available") return "Available for sale";
  return "Unavailable";
};

export const formatText = (text, separator = "_") => {
  const arr = text.split(separator);
  let arr2 = [];
  arr.map((part) => {
    arr2.push(part[0].toUpperCase() + part.slice(1));
  });
  return arr2.join(" ");
};

// takes an array of object, return array of name, value objects, for dropdown menu component
export const formatObjArr = (objArray, name, value) => {
  const arr = [];
  objArray.map((item) => {
    arr.push({
      name: item[name],
      value: item[value],
    });
  });
  return arr;
};

export const getFamilyStats = (family) => {
  //Array of objects
  console.log(family);
  // for each family member , for each key in members services stats,
  let result = {};
  if (family) {
    family.map((member) => {
      Object.keys(member.services_stats).map((key) => {
        if (result[key]) {
          result[key] += member.services_stats[key];
        } else {
          result[key] = member.services_stats[key];
        }
      });
    });
  }
  return result;
};
