import React from "react";
import styled from "styled-components";
import colors from "../util/colors";
import ceo from "../images/ceo.png";
import illustration from "../images/illust.png";

const CeoMessage = () => {
  return (
    <StyledSection>
      <h1>CEO's Message</h1>
      <p>
        "During the past 50 years, investment in real estate proved to be the
        best investment option in the Egyptian market. However, the market has
        been changing and becoming more volatile, therefore, it is now more
        important than ever to make sure the assets are managed correctly to
        continuously generate revenues."
      </p>
      <div className="ceo-image">
        <img src={ceo} alt="ceo" className="ceo" />
        <div className="illustration">
          <p>Bassel El Batouty</p>
          <p>Chief Executive Officer</p>
        </div>
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  overflow-y: hidden;
  padding: 2vw 0vw;
  padding-bottom: 0;
  h1 {
    font-size: 2.5vw;
    margin: 0;
    padding: 0;
    background: ${colors.brown};
    width: fit-content;
    padding: 0.5vw;
    padding-left: 12vw;
    padding-right: 1vw;
    @media (max-width: 768px) {
      font-size: 6.7vw;
    }
  }
  p {
    font-size: 1.74vw;
    line-height: 2.5vw;
    padding: 0 8.2vw;
    text-align: justify;
    color: ${colors.lightGrey};
    margin-top: 3vw;
    @media (max-width: 768px) {
      font-size: 3.2vw;
      line-height: 3.9vw;
      margin-top: 9vw;
    }
  }
  .ceo-image {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 4vw;
    padding: 0 8.2vw;
    transform: translateY(1vw);
    @media (max-width: 768px) {
      padding: 0;
      justify-content: center;
    }
    .ceo {
      width: 15vw;
      @media (max-width: 768px) {
        width: 30vw;
      }
    }
    .illustration {
      position: relative;
      align-self: center;
      img {
        width: 50vw;
        @media (max-width: 768px) {
          height: 35vw;
        }
      }
      p {
        margin: 0;
        font-size: 2vw;
        font-weight: 700;
        color: ${colors.brown};
        &:nth-child(2) {
          font-size: 1vw;
          opacity: 0.7;
          @media (max-width: 768px) {
            font-size: 2.5vw;
            margin-top: 1vw;
          }
        }
        @media (max-width: 768px) {
          font-size: 5vw;
        }
      }
    }
  }
`;

export default CeoMessage;
