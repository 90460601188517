import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import StyledPage from "../components/assets/StyledPage";
import { axiosAuth } from "../util/api/integration";
import colors from "../util/colors";
import { formatText } from "../util/utilFunctions";
import editIcon from "../images/edit.png";
import Button from "../components/assets/Button";
import history from "../util/api/history";
import CustomUpload from "../components/assets/CustomUpload";
import PropertyTables from "../components/dashboard/detailed-property/PropertyTables";
import { ReactComponent as Close } from "../images/red-close.svg";
const ManageProperty = () => {
  const { id: pId } = useParams();
  const [details, setDetails] = useState({});
  const [addressDetails, setAddressDetails] = useState({});
  const [files, setFiles] = useState();
  const [edit, setEdit] = useState(true);
  const [initialDetails, setInitialDetails] = useState({});
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(true);
  const [imgId, setImgId] = useState();

  const fields = [
    { name: "value", type: "number" },
    { name: "annual_tax", type: "number" },
    { name: "last_settlement_date", type: "date" },
  ];

  useEffect(() => {
    const getDetails = async () => {
      if (pId) {
        try {
          const res = await axiosAuth.get(`/properties/${pId}`);
          setDetails(res.data);
          setInitialDetails(res.data);
          setAddressDetails(res.data?.address);
          if (res.data.images.length > 0) {
            const imageObj = res.data.images?.reduce((acc, curr) => {
              return curr.id > acc.id ? curr : acc;
            });
            setImage(imageObj?.url);
            setImgId(imageObj?.id);
          }
          setLoading(false);
        } catch (error) {
          alert(error.response?.data?.message || "Something went wrong!");
          setLoading(false);
        }
      }
    };
    getDetails();
  }, []);

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("img", file);
    const res = await axiosAuth.post(`/properties/${pId}/uploadImage`, data);
    console.log(res);
  };

  const submitImages = async () => {
    // e.preventDefault();
    if (files) {
      try {
        for (let i = 0; i < Array.from(files).length; i++) {
          await uploadImage(Array.from(files)[i]);
        }
        // alert("Images uploaded successfully");
        setFiles();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const cancelHandler = async (e) => {
    e.preventDefault();
    setDetails(initialDetails);
    setFiles();
  };

  const handleEmptyFeilds = (obj) => {
    for (var key in obj) {
      console.log(key, obj[key]);
      if (obj[key] == "") {
        obj[key] = null;
      }
    }
    return obj;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (pId) {
      let payload = (({
        name1,
        value,
        annual_tax,
        last_settlement_date,
        owner_name,
      }) => ({ name1, value, annual_tax, last_settlement_date, owner_name }))(
        details
      );
      try {
        submitImages();
        const res = await axiosAuth.put(
          `/properties/${pId}`,
          handleEmptyFeilds(payload)
        );
        console.log(res);
        if (files) {
          try {
            const deleteImgRes = await axiosAuth.delete(`/images/${imgId}`);
          } catch (e) {
            console.log("error from remove old image", e);
          }
        }

        alert("Property edited successfully.");
        history.push(`/user-properties/${details.UserID}`);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await axiosAuth.post("/properties", details);
        console.log(res);
        alert("Property added successfully.");
        history.push("/listings");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <StyledPage lightMode backgroundColor="#FCFAF7" no-padding>
      <StyledContainer>
        <div className="listing-page-heading">
          <p className="p-title">{pId ? details["name1"] : "Add Property"}</p>
          {/* <h1 className="fs30">{pId ? `Property #${pId}` : "Add Property"}</h1> */}
          {pId && !edit && (
            <img onClick={() => setEdit(true)} src={editIcon} alt="edit" />
          )}
        </div>
        <div className="form-container">
          <form id="listing" className="listing-form" onSubmit={submitHandler}>
            {fields.map((field) =>
              field.type === "select" ? (
                <div className="input-div" key={field.name}>
                  <label htmlFor={field.name}>
                    {field.displayName || formatText(field.name)}
                  </label>
                  <select
                    // required
                    disabled={!edit && pId}
                    className="input-global"
                    value={details[field.name]}
                    onChange={(e) => {
                      setDetails({ ...details, [field.name]: e.target.value });
                    }}
                    name={field.name}
                    id={field.name}
                  >
                    {field.options &&
                      field.options.map((o) => (
                        <option value={o.value}>{o.name}</option>
                      ))}
                  </select>
                </div>
              ) : (
                <div className="input-div" key={field.name}>
                  <label htmlFor={field.name}>
                    {field.displayName || formatText(field.name)}
                  </label>
                  <input
                    // required
                    disabled={!edit && pId}
                    value={details[field.name]}
                    className="input-global"
                    onChange={(e) => {
                      setDetails({ ...details, [field.name]: e.target.value });
                    }}
                    type={field.type}
                    key={field.name}
                  />
                </div>
              )
            )}
            <input
              type="file"
              // multiple
              id="upload-photo"
              onChange={(e) => {
                setFiles(e.target.files);
                setImage(URL.createObjectURL(e.target.files[0]));
              }}
              style={{ display: "none" }}
            />
            <div className="upload-div">
              {/* <p>
                {files?.length > 0 && files.length + " image(s) selected"}
              </p> */}
              <p>Property Image</p>
              {image ? (
                <div className="image-overlay">
                  <Close
                    onClick={() => {
                      setFiles();
                      setImage();
                    }}
                  />
                  <img alt="Property Image" src={image} />
                </div>
              ) : (
                <label for="upload-photo" className="upload-btn">
                  Upload image
                </label>
              )}
            </div>
            {/* {addressFields.map((field) => (
            <div className="input-div" key={field.name}>
              <label htmlFor={field.name}>
                {field.displayName || formatText(field.name)}
              </label>
              <input
                disabled={!edit && id}
                value={addressDetails[field.name]}
                className="input-global"
                onChange={(e) => {
                  setAddressDetails({
                    ...addressDetails,
                    [field.name]: e.target.value,
                  });
                }}
                type={field.type}
                key={field.name}
              />
            </div>
          ))} */}
            <div></div>
            {((pId && edit) || !pId) && (
              // <input
              //   className=" fs16 submit-btn"
              //   type="submit"
              //   form="listing"
              //   label={"Submit"}
              // />
              <div className="actions-div">
                <button className="cancel-btn" onClick={cancelHandler}>
                  Cancel
                </button>
                <button className="save-btn" type="submit">
                  Save Changes
                </button>
              </div>
            )}
          </form>
        </div>
        {!loading && (
          <div className="tables-container">
            <PropertyTables
              unit_code={details.unit_code}
              page="manage-properties"
            />
          </div>
        )}
      </StyledContainer>
    </StyledPage>
  );
};

const StyledContainer = styled.div`
  padding: 0 8.681vw 14.931vw 8.681vw;
  // padding-bottom: 3vw;
  .p-title {
    color: var(--black, #010101);
    font-family: Mulish;
    font-size: 2.083vw;
    line-height: 2.639vw;
    font-style: normal;
    font-weight: 800;
  }
  .form-container {
    padding: 2.222vw 1.667vw 1.667vw;
    width: 82.639vw;
    // height: 45.833vw;
    border-radius: 0.347vw;
    border: 0.069vw solid #dcdcdc;
    background: #fff;
  }
  .listing-page-heading {
    display: flex;
    align-items: center;
    gap: 1vw;
    margin-bottom: 2.222vw;
    img {
      width: 2vw;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .listing-form {
    // padding-right: 3vw;
    gap: 1.667vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    .input-div {
      display: flex;
      flex-direction: column;
      &:not(:last-of-type) {
        // margin-bottom: 1vw;
        @media (max-width: 768px) {
          margin-bottom: 4vw;
        }
      }
      input,
      select {
        border-radius: 0.347vw;
        border: 0.069vw solid var(--stroke, #ebe8df);
        background: #fbfbfd;
        width: 38.819vw;
        height: 3.472vw;
        padding: 0 1.111vw;
        color: var(--black, #010101);
        font-family: Mulish;
        font-size: 0.972vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      label {
        color: #9e9e9e;
        font-size: 0.764vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.11px;
        margin-bottom: 0.278vw;
        @media (max-width: 768px) {
          font-size: 2.9vw;
        }
      }
    }
    .upload-div {
      display: flex;
      flex-direction: column;
      justify-content: end;

      p {
        color: #9e9e9e;
        font-size: 0.764vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.11px;
        margin-bottom: 0.278vw;
      }
      .upload-btn {
        width: 38.819vw;
        height: 3.472vw;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.347vw;
        border: 0.069vw solid var(--brown, #af916d);
        background: #fff;
        color: var(--brown, #af916d);
        text-align: center;
        font-family: Mulish;
        font-size: 0.972vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
      }
      .image-overlay {
        position: relative;
        width: 9.167vw;
        height: 5.694vw;
        svg {
          position: absolute;
          top: -0.486vw;
          right: -0.486vw;
          cursor: pointer;
          width: 1.389vw;
          height: 1.389vw;
        }
        img {
          width: 9.167vw;
          height: 5.694vw;
          object-fit: contain;
        }
      }
    }
    button {
      background: ${colors.brown};
      margin-top: 5.35vw;
      margin-bottom: 0.76vw;
      &.disabled-button {
        background: ${colors.lightGrey};
      }
      @media (max-width: 768px) {
        margin-top: 29.3vw;
      }
    }
    a {
      align-self: flex-end;
    }
  }
  .actions-div {
    margin-top: 1.667vw;
    display: flex;
    gap: 1.667vw;
    align-items: center;
    justify-content: end;
    button {
      outline: none;
      width: 9.306vw;
      height: 2.778vw;
      text-align: center;
      font-family: Mulish;
      font-size: 1.111vw;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 0.278vw;
      border: 0.069vw solid var(--brown, #af916d);
      cursor: pointer;
      margin: 0;
    }
    .cancel-btn {
      background: #fff;
      color: var(--brown, #af916d);
    }
    .save-btn {
      background: var(--brown, #af916d);
      color: var(--white, #fff);
    }
  }

  .submit-btn {
    margin-top: 3vw;
    width: 6vw;
    height: 3.33vw;
    font-size: 0.833vw;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
    color: ${colors.white};
    border-radius: 0.35vw;
    background: ${colors.brown};
    @media (max-width: 768px) {
      height: 9.7vw;
      font-size: 2.9vw;
      border-radius: 1.33vw;
    }
  }
`;
export default ManageProperty;
