import axios from "axios";
import jwt_decode from "jwt-decode";
import { getValidToken } from "./authFunctions";

// const baseURL = "http://3.20.120.149:8004";
const baseURL = "https://equityegypt.com:8004";

export const axiosOMS = axios.create({
  baseURL,
});

export const axiosAuth = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
});
axiosAuth.interceptors.request.use(
  async (config) => {
    const currentToken = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refresh_token");
    const currentDecoded = jwt_decode(currentToken);
    try {
      const newToken = await getValidToken(
        currentToken,
        refreshToken,
        currentDecoded
      );
      config.headers["Authorization"] = `Bearer ${newToken}`;
      return config;
    } catch (error) {
      alert(error.response?.data?.message || "Something went wrong!");
    }
  },
  (err) => Promise.reject(err)
);
