import React from "react";
import styled from "styled-components";
import colors from "../util/colors";

const BorderLine = () => {
  return <StyledBorder></StyledBorder>;
};
const StyledBorder = styled.div`
  position: absolute;
  margin-bottom: auto;
  bottom: 0;
  left: calc((100vw - 90vw) / 2);
  height: 0.4vw;
  width: 90vw;
  background: ${colors.brown};
  @media (max-width: 768px) {
    display: none;
  }
`;
export default BorderLine;
