import React from "react";
import { Carousel } from "react-responsive-carousel";
// import { Carousel } from "react-carousel-minimal";
import logo from "../../images/re.jpeg";
import apt1img21 from "../../images/Apartments/Zamalek/21.jpeg";
import styled from "styled-components";
const ImageCarousel = ({ images }) => {
  return (
    <StyledCarouselContainer>
      <Carousel
        // centerMode={true}
        width="100%"
        // dynamicHeight={false}
        // thumbWidth="8.6vw"
        showStatus={false}
        transitionTime={150}
        // showArrows={false}
        // showThumbs={false}
        autoPlay={false}
        useKeyboardArrows={true}
        emulateTouch={true}
        infiniteLoop
      >
        {images.map((image) => (
          <img src={image} alt="" />
        ))}
      </Carousel>
    </StyledCarouselContainer>
  );
};
const StyledCarouselContainer = styled.div`
  .slide {
    img {
      height: 24.7vw;
      object-fit: cover;
      @media (max-width: 768px) {
        height: 50vw;
      }
    }
  }
`;

export default ImageCarousel;
