import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import history from "../../../util/api/history";
import { axiosAuth } from "../../../util/api/integration";
import colors from "../../../util/colors";
import Amount from "../../assets/Amount";
import Label from "../../assets/Label";

import Pagination from "../../assets/Pagination";
import AddInstallment from "../Installments/AddInstallment";

import { ReactComponent as Delete } from "../../../images/delete.svg";
import { ReactComponent as Edit } from "../../../images/edit2.svg";
import { ReactComponent as DeleteSquare } from "../../../images/delete-square.svg";
import { ReactComponent as Add } from "../../../images/add-square.svg";
import ConfirmDelete from "./ConfirmDelete";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const InstallmentsTable = ({
  unit_code,
  pId,
  installments,
  refresh,
  setRefresh,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [currentKey, setcurrentKey] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const ref = useRef(null);
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const showActions = pathname == "manage-properties";

  const handleClickOutside = (event) => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }

    if (menuIsOpen) {
      setMenuIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuIsOpen]);

  const deleteHandler = async (id) => {
    try {
      const res = await axiosAuth.delete(`/installment/${id}`);
      // alert(`Installment is deleted successfully`);
      history.push(history.location.pathname);
      setRefresh(!refresh);
    } catch (error) {
      alert(error.response?.data?.message || "Something went wrong!");
    }
  };

  const deletAllInstallments = async () => {
    if (pId) {
      try {
        const res = await axiosAuth.delete(`/installments/${pId}`);
        // alert(" All Property Installments Deleted Successfully");
        history.push(history.location.pathname);
      } catch (e) {
        console.log("Error while deleting all property installments", e);
        alert(e.response?.data?.message || "Something went wrong!");
      }
    }
  };

  return (
    <StyledTable className="details-table">
      {showActions && (
        <>
          <AddInstallment pId={pId} key={currentKey} />
          <ConfirmDelete
            message={
              confirmDelete == "all"
                ? "Delete all installments for this Property?"
                : "Are you sure you want to delete Installment ?"
            }
            onConfirm={
              confirmDelete == "all"
                ? deletAllInstallments
                : () => {
                    deleteHandler(confirmDelete);
                  }
            }
          />
        </>
      )}
      <thead>
        <tr className="table-header">
          <p className="table-name"> Property Installments </p>
          {showActions && (
            <div className="buttons">
              {installments && installments.length > 0 && (
                <button
                  onClick={() => {
                    setConfirmDelete("all");
                    history.push("?confirm=true");
                  }}
                >
                  <DeleteSquare />
                  <p className="delete-txt">Delete All Installments</p>
                </button>
              )}
              <button
                onClick={() => {
                  setMenuIsOpen(!menuIsOpen);
                  setcurrentKey(Math.random());
                }}
              >
                <Add />
                <p>Add New Installment</p>
              </button>
            </div>
          )}
          {menuIsOpen && (
            <div
              className="installment-type-menu"
              onBlur={() => {
                setMenuIsOpen(false);
              }}
              ref={ref}
            >
              <Link to="?manage-installment=true&type=single">
                Single Installment
              </Link>
              <Link to="?manage-installment=true&type=bulk">
                Bulk Installments
              </Link>
            </div>
          )}
        </tr>
        <tr>
          <td>ID</td>
          <td>Type</td>
          <td>Due Date</td>
          <td>Percentage</td>
          <td>Amount</td>
          <td>Status</td>
          <td>Payment Date</td>
          {/* <td>Maintenance Deposit Installment</td> */}
          {showActions && <td>Actions</td>}
        </tr>
      </thead>
      {installments && (
        <tbody>
          {installments.length > 0 ? (
            installments.map((installment, index) => {
              return (
                <tr key={`transaction.unit_code ${Math.random(10000)}`}>
                  <td>{installment.id}</td>
                  <td>
                    {installment.installment_type == 1
                      ? "Maintenance Deposit"
                      : "Installment"}
                  </td>
                  <td>{installment.installment_due_date}</td>
                  {installment.percentage > 0 ? (
                    <td>{Math.round(installment.percentage * 10) / 10}%</td>
                  ) : (
                    <td>--</td>
                  )}

                  <td className="amount">
                    <p className="fw600">
                      {(+installment.installment_amount)
                        .toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        .replace(/\.00$/, "")}{" "}
                      EGP
                    </p>
                  </td>
                  <td className="type">
                    {installment.status == 1 ? (
                      <Label className="paid">PAID</Label>
                    ) : installment.status == 3 ? (
                      <Label
                        backgroundColor="#F9CF71"
                        color="#000"
                        className="upcoming"
                      >
                        UPCOMING
                      </Label>
                    ) : (
                      <Label backgroundColor="#B06D77" className="overdue">
                        OVERDUE
                      </Label>
                    )}
                  </td>
                  <td>{installment.paid_date}</td>
                  {/* <td className="amount">
                    {installment.maintenance_deposite_installment && (
                      <p className="fw600">
                        {installment.maintenance_deposite_installment
                          .toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                          .replace(/\.00$/, "")}{" "}
                        EGP
                      </p>
                    )}
                  </td> */}
                  {showActions && (
                    <td className="actions">
                      <Edit
                        onClick={() => {
                          setcurrentKey(installment.id);
                          history.push({
                            pathname: history.location.pathname,
                            search: "?manage-installment=true",
                            state: {
                              id: installment.id,
                              status: installment.status,
                            },
                          });
                        }}
                      />
                      <Delete
                        onClick={() => {
                          setConfirmDelete(installment.id);
                          history.push("?confirm=true");
                          // deleteHandler(installment.id);
                        }}
                      />
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <p className="no-records">No records found</p>
          )}
        </tbody>
      )}
      {/* {rawData && (
        <Pagination
          data={rawData}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          noScroll
        />
      )} */}
    </StyledTable>
  );
};
const StyledTable = styled.table`
  position: relative;
  width: 83.6vw;
  background: white;
  overflow-y: hidden;
  border-radius: 2.1vw;
  margin-bottom: 3vw;
  padding: 1vw;
  padding-top: 0;
  font-size: 0.9vw;
  margin-top: 1.04vw;
  @media (max-width: 768px) {
    border-radius: 2.133vw;
    margin-bottom: 0vw;
    padding: 0 4.267vw 4.267vw;
    font-size: 3.733vw;
    line-height: 4.8vw;
    margin-top: 4.267vw;
    width: 250vw;
  }
  .table-header {
    position: relative;
    height: 5.972vw;
    padding: 2.083vw 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.069vw solid #dcdcdc;
    margin-bottom: 1.111vw;

    @media (max-width: 768px) {
      height: auto;
      padding: 4.267vw 0;
      border-bottom: 0.267vw solid #dcdcdc;
      margin-bottom: 4.267vw;
    }

    .table-name {
      font-family: Mulish;
      font-size: 1.389vw;
      line-height: 1.667vw;
      font-style: normal;
      font-weight: 800;
      color: var(--black, #010101);

      @media (max-width: 768px) {
        font-size: 4.267vw;
        line-height: 5.333vw;
      }
    }

    .buttons {
      display: flex;
      gap: 3.472vw;
      button {
        outline: none;
        border: none;
        cursor: pointer;
        background: none;
        display: flex;
        gap: 0.278vw;
        align-items: center;
        p {
          color: var(--brown, #af916d);
          font-family: Mulish;
          font-size: 1.111vw;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-decoration-line: underline;

          @media (min-width: 1440px) {
            font-size: 16px;
          }
        }
        .delete-txt {
          color: var(--red, #b06d77);
        }
        svg {
          width: 1.667vw;
          height: 1.667vw;

          @media (min-width: 1440px) {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .installment-type-menu {
      position: absolute;
      top: 5.069vw;
      right: 0;
      border-radius: 0.556vw;
      border: 0.069vw solid var(--stroke, #ebe8df);
      background: #fff;
      box-shadow: 0px 0.278vw 0.417vw -0.139vw rgba(16, 24, 40, 0.07),
        0px 0.833vw 1.111vw -0.278vw rgba(16, 24, 40, 0.15);
      padding: 0.278vw 0;
      display: flex;
      flex-direction: column;
      a {
        width: 12.639vw;
        text-decoration: none;
        padding: 0.833vw 0 0.833vw 1.111vw;
        color: var(--brown, #af916d);
        font-family: Mulish;
        font-size: 1.111vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &:not(:last-child) {
          border-bottom: 0.069vw solid #ebe8df;
        }
        @media (min-width: 1440px) {
          font-size: 16px;
        }
      }
    }
  }
  thead {
    color: #334d6e;
    font-weight: 700;
    font-family: Mulish;

    td {
      font-weight: 700;
      font-family: Mulish;
      text-align: left;
    }
  }
  tbody {
    * {
      font-family: Mulish;
    }
    .type {
      font-weight: 700;
      .paid {
        width: 4.167vw;
        @media(max-width : 768px){
          width: 20vw;
        }
      }

      .upcoming {
        width: 7.292vw;
        @media(max-width : 768px){
          width: 30vw;
        }
      }

      .overdue {
        width: 6.333vw;
        @media(max-width : 768px){
          width: 25vw;
        }
      }
    }
    display: block;
    max-height: 30vw;
    overflow-y: scroll;
    @media (max-width: 768px) {
      max-height: 90vw;
    }

    /* Customizing scroll bar */
    ::-webkit-scrollbar {
      width: 0.55vw;
      height: 1vw;
      @media (max-width: 768px) {
        width: 1.7vw;
      }
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f3efe9;
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.brown};
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      /* background: ${colors.lightMain}; */
    }
  }

  // general rows and cells styling
  .no-records {
    margin-top: 1vw;
    text-align: center;
    font-size: 1vw;
    color: ${colors.darkGrey};
    @media (max-width: 768px) {
      margin-top: 3vw;
      font-size: 3vw;
    }
  }
  tr {
    width: 100%;
    height: 3.7vw;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      height: 11.1vw;
    }
  }
  td {
    text-align: left;
    flex: 1;

    font-weight: 600;
    &:nth-child(1) {
      flex: 0.4;
    }
    &:nth-child(2) {
      flex: 1.3;
    }
    // &:nth-child(6) {
    //   flex: .8;
    // }
    &:nth-child(4) {
      flex: 1;
    }
  }
  .actions {
    display: flex;

    gap: 1.111vw;
    justify-content: start;
    svg {
      cursor: pointer;
      width: 1.667vw;
      height: 1.667vw;
    }
  }
  .scroll-top-button {
    background: ${colors.brown};
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 1vw;
    height: 1vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.1vw;
    font-size: 0.8vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export default InstallmentsTable;
