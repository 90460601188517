import React from "react";
import styled from "styled-components";
import logo from "../images/logo.png";
import colors from "../util/colors";
import NavLinks from "./NavLinks";

const Footer = () => {
  return (
    <StyledFooter>
      <img src={logo} alt="equity-logo" />
      <NavLinks />
      <div>
        <a href="https://maps.app.goo.gl/H9gLHWJKUEoDYUcY6" target="_blank" className="address-link">
        <p>Nile view club, Dokki</p>
        <p>Giza, Egypt</p>
        </a>
        <p>
          Tel: <a href="tel:+200233358584">+200233358584</a>
        </p>
        <p>
          <a href="mailto:info@equityegypt.com">info@equityegypt.com</a>
        </p>
        <p>© 2022 Equity Egypt</p>
      </div>
    </StyledFooter>
  );
};
const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 18.1vw;
  padding: 4.17vw 3vw 4.93vw 3vw;
  background: ${colors.darkGrey};
  .address-link{
    text-decoration: none;
  }
  ul {
    li {
      .popup-menu {
        background: none;
        border: solid 0.1vw ${colors.darkBrown};
        .menu-item {
          width: 100%;
          &:hover {
            background: black;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    height: auto;
    padding: 7.2vw 3vw 5.9vw 3vw;
    gap: 4vw;
  }
  img {
    width: 9.5vw;
    @media (max-width: 768px) {
      width: 14.7vw;
    }
  }
  ul li a {
    color: ${colors.brown};
  }
  @media (max-width: 768px) {
    .nav-links {
      justify-content: flex-start;
      align-items: center;
    }
    ul {
      flex-direction: column;
      width: 100%;
      li {
        text-align: left;
        a {
          font-size: 3.2vw;
          line-height: 6vw;
        }
        .popup-menu {
          background: none;
          border: none;
          .menu-item {
            &:hover {
              background: none;
            }
          }
        }
      }
    }
  }
  p {
    font-size: 0.9vw;
    line-height: 1.11vw;
    color: ${colors.lightGrey};
    a {
      text-decoration: none;
      color: inherit;
    }
    @media (max-width: 768px) {
      line-height: 3.2vw;
      font-size: 2.4vw;
    }
  }
`;

export default Footer;
