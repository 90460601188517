import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import NumberCard from "../assets/NumberCard";

const Overview = () => {
  const { overview } = useSelector((state) => state.user);
  console.log(overview);
  return (
    <StyledSection>
      <h1 className="overview-title fs30">Overview</h1>
      <div className="totals-panel">
        <NumberCard label="Total Units" number={overview.properties_count} />
        <NumberCard
          isAmount
          isDefault
          label="Debit"
          number={overview.sum_debit}
        />
        <NumberCard
          isAmount
          isDefault
          label="Credit"
          number={overview.sum_credit}
        />
        <NumberCard isAmount label="Balance" number={overview.balance} />
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  .overview-title {
    @media (max-width: 768px) {
      font-size: 4.8vw;
      line-height: 6.133vw;
      margin-bottom: 4.267vw;
    }
  }
  .totals-panel {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 2vw;
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 3.467vw;
      margin-bottom: 6.4vw;
    }
  }
`;

export default Overview;
