import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "../../assets/Modal";
import { ReactComponent as Close } from "../../../images/white-close.svg";
import history from "../../../util/api/history";

const ViewImage = ({ image }) => {
  return (
    <Modal urlKey={"view-image"} history={history}>
      <StyledModal>
        <Close
          onClick={() => {
            history.replace(history.location.pathname);
          }}
        />

        <img src={image} className="p-img" />
      </StyledModal>
    </Modal>
  );
};
const StyledModal = styled.div`
  position: relative;
  background: #000;
  width: 93.056vw;
  height: 90vh;
  border-radius: 0.556vw;
  display: flex;
  justify-content: center;
  svg {
    position: absolute;
    top: 1.667vw;
    right: 2.222vw;
    width: 2.222vw;
    height: 2.222vw;
    cursor: pointer;
\
    @media (min-width: 1440px) {
      width: 32px;
      height: 32px;
    }
  }
  .p-img {
    width: 72.847vw;
    height: 90vh;
    object-fit: contain;
  }
`;

export default ViewImage;
