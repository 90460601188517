import React from "react";
import styled from "styled-components";
import bg from "../images/bg2.png";
import bgrolemobile from "../images/bgrolemobile.png";
import bullet from "../images/bullet.png";
import colors from "../util/colors";
import BorderLine from "./BorderLine";

const OurRole = () => {
  return (
    <StyledSection>
      <div className="text">
        <h1>Our Role</h1>
        <div className="item">
          <img src={bullet} alt="" />
          <p>
            Our responsibility is to provide further into depth analysis of real
            estate portfolios along with the process of conducting valuations
            which could dominate existing market conditions and bring actual
            market value.
          </p>
        </div>
        <div className="item">
          <img src={bullet} alt="" />
          <p>
            Through our findings, we will present our clients with an adjustable
            action plan by demonstrating different paths available for selling
            or buying an asset accompanied by a suitable time frame in-order to
            set expectations for both parties involved in the process.
          </p>
        </div>
        <div className="item">
          <img src={bullet} alt="" />
          <p>
            Once the client approves the plan, we will begin our execution
            process. This will involve issuing a monthly/quarterly statement,
            exhibiting revenues and related expenses with a frequent settlement
            through the client’s bank account.
          </p>
        </div>
        <div className="item">
          <img src={bullet} alt="" />
          <p>
            Our clients will receive detailed updates regarding any recent
            activities on their property.
          </p>
        </div>
      </div>
      <BorderLine />
    </StyledSection>
  );
};
const StyledSection = styled.div`
  position: relative;
  width: 100%;
  min-height: 40vw;
  padding-bottom: 3vw;
  background: url(${bg});
  background-size: cover;
  @media (max-width: 768px) {
    /* min-height: 145vw; */
    height: fit-content;
    padding-bottom: 13.3vw;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    background: url(${bgrolemobile});
    background-size: cover;
  }
  .text {
    margin-left: 8.2vw;
    width: 64.5vw;
    @media (max-width: 768px) {
      width: 85.33vw;
      margin: auto;
    }
    h1 {
      color: ${colors.brown};
      font-size: 3.5vw;
      font-weight: 700;
      line-height: 6.5vw;
      margin-bottom: 0.56vw;
      @media (max-width: 768px) {
        font-size: 6.7vw;
        line-height: 12.5vw;
        margin-bottom: 6.67vw;
      }
    }
    .item {
      display: flex;
      align-items: flex-start;
      &:not(:first-of-type) {
        margin-top: 3.75vw;
      }
      img {
        width: 1.9vw;
        @media (max-width: 768px) {
          width: 7vw;
        }
      }
      p {
        color: ${colors.lightGrey};
        font-size: 1.74vw;
        margin-left: 1.5vw;
        line-height: 2.64vw;

        @media (max-width: 768px) {
          font-size: 3.2vw;
          line-height: 6vw;
          margin-left: 4vw;
        }
      }
    }
  }
`;

export default OurRole;
