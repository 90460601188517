import accounting from "../images/accounting.png";
import facility from "../images/facility.png";
import finance from "../images/finance.png";
import property from "../images/property.png";
import rent from "../images/rent.png";
const services = [
  {
    imageUrl: rent,
    cardTitle: "Property Selling and Leasing",
    serviceDesc:
      "We market our client’s properties, develop/manage contracts, follow up with leases and sell properties with added value.",
  },
  {
    imageUrl: finance,
    cardTitle: "Direct Finance",
    serviceDesc:
      "We provide property financing solutions to clients faster and easier compared to other entities that lend out money.",
  },
  {
    imageUrl: facility,
    cardTitle: "Facility Management and Maintenance",
    serviceDesc:
      "Combining our deep knowledge in architecture and design to provide expert renovation and refurbishing services to any kind of real estate property.",
  },
  {
    imageUrl: accounting,
    cardTitle: "Taxation",
    serviceDesc:
      "We take the hassle out of dealing with any third party or government institutions by taking responsibility for any legal property obligations.",
  },
  {
    imageUrl: property,
    cardTitle: "Valuation",
    serviceDesc:
      "We determine the market value of the property on the basis of several factors in-order to enhance and adjust the property’s worth in the market.",
  },
];

export default services;
