import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { axiosAuth } from "../../util/api/integration";
import { formatObjArr } from "../../util/utilFunctions";
import DashboardPropertyCard from "./DashboardPropertyCard";
import SearchBar, { SelectBar } from "./SearchBar";
const Properties = () => {
  const properties = useSelector((state) => state.user.properties);
  const [displayProperties, setDisplayProperties] = useState(properties);
  const [searchMatches, setSearchMatches] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [submittedSearch, setSubmittedSearch] = useState();
  const [familyMember, setFamilyMember] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const isFamilyMember = useSelector((state) => state.user.family_id);
  const { family } = useSelector((state) => state.user);
  useEffect(() => {
    const getSearchMatches = async () => {
      if (submittedSearch || familyMember) {
        setSearchLoading(true);
        try {
          const response = await axiosAuth.get(
            `me/family/properties?&unit_code=${submittedSearch}&UserID=${familyMember}`
          );
          setSearchMatches(response.data.results);
          setSearchLoading(false);
        } catch (error) {
          alert(error.response?.data?.message || "Something went wrong!");
          setSearchLoading(false);
        }
      }
    };
    getSearchMatches();
  }, [submittedSearch, familyMember]);

  useEffect(() => {
    if (searchMatches) {
      setDisplayProperties(searchMatches);
    } else {
      setDisplayProperties(properties);
    }
  }, [searchMatches]);

  useEffect(() => {
    if (!searchKey && !familyMember) setSearchMatches(null);
    if (!searchKey) setSubmittedSearch("");
  }, [searchKey, familyMember]);
  return (
    <PropertiesPanel>
      <div className="title-bar">
        <h1 className="properties-title fs30">
          Properties
          <span>
            <Link to={isFamilyMember ? "/all-properties" : "my-properties"}>
              (View All)
            </Link>
          </span>
        </h1>
        <div className="search-section">
          {isFamilyMember && (
            <SelectBar
              className="member-select"
              placeholder="Select Family Member"
              disabled={searchLoading}
              options={formatObjArr(family, "name", "UserID")}
              value={familyMember}
              setValue={setFamilyMember}
            />
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSubmittedSearch(searchKey);
            }}
          >
            <SearchBar
              disabled={searchLoading}
              value={searchKey}
              setValue={setSearchKey}
            />
          </form>
        </div>
      </div>
      {/* To be integrated */}
      <div className="properties-panel">
        {displayProperties.map((property) => (
          <DashboardPropertyCard key={property.unit_code} property={property} />
        ))}
      </div>
    </PropertiesPanel>
  );
};
const PropertiesPanel = styled.div`
  @media (max-width: 768px) {
    margin-top: 4.267vw;

    .title-bar {
      .properties-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 4.8vw;
        line-height: 6.133vw;
        span {
          font-size: 3.733vw;
          font-weight: 700;
          line-height: 4.8vw;
        }
      }
    }
  }
  select {
    margin-right: 1vw;
  }
  .properties-panel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    grid-gap: 2.1vw;
    margin-bottom: 2vw;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 3.2vw;
    }
  }
`;

export default Properties;
