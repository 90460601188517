import React from "react";
import styled from "styled-components";
import { ReactComponent as Loading } from "../components/assets/icons/loading.svg";

const LoadingPage = () => {
  return (
    <StyledPage>
      <Loading />
    </StyledPage>
  );
};
const StyledPage = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fcfaf7;
  svg {
    width: 12vw;
  }
`;
export default LoadingPage;
