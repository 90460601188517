import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import ImageCarousel from "../assets/ImageCarousel";
import { Beds, Baths, Area } from "../assets/icons/icons";
import logo from "../../images/re.jpeg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ph from "../../images/placeholderimage.png";

const PropertyCard = ({ property, type, ...props }) => {
  const [vw, setVw] = useState(window.innerWidth);
  const formatter = new Intl.NumberFormat("en");
  console.log(property.images);
  useEffect(() => {
    window.addEventListener("resize", () => setVw(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setVw(window.innerWidth));
    };
  }, []);
  return (
    <StyledCard ref={props.MyRef}>
      {/* {vw > 786 ? (
        <div className="carousel-section">
          <ImageCarousel />
        </div>
      ) : (
        <div className="image-section">
          <img src={logo} alt="" />
        </div>
      )} */}
      <div className="carousel-section">
        {property.images.length > 0 ? (
          <ImageCarousel images={property.images.map((i) => i.url)} />
        ) : (
          <img className="ph-image" src={ph} alt="" />
        )}
      </div>
      <div className="property-text">
        <h1 className="fs30 property-heading">{property.name1}</h1>
        <div className="desc-price fs20">
          <p>{property.property_category}</p>
          <p>
            {formatter.format(parseInt(property.value))} EGP{" "}
            {type === "lease-properties" && (property.rent_type || "monthly")}
          </p>
        </div>
        <div className="property-details fs20">
          <div className="beds">
            <Beds /> {property.rooms_count}
          </div>
          <div className="baths">
            <Baths /> {property.bathrooms_count}
          </div>
          <div className="area">
            <Area />
            {property.area} sqm
          </div>
        </div>
        <p className="property-details-paragraph fs16">
          {property.description}
        </p>
      </div>
    </StyledCard>
  );
};
const StyledCard = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 42.8vw;
  padding: 4.375vw 13.9vw 4.7vw 14.4vw;
  width: 87vw;
  width: 100%;
  color: ${colors.white};
  border-top: solid 0.07vw #4a4a4a;
  @media (max-width: 768px) {
    min-height: 118vw;
    flex-direction: column;
    padding: 12vw 9.6vw 9.6vw 9.9vw;
    justify-content: flex-start;
  }
  .carousel-section {
    width: 34.17vw;
    .ph-image {
      width: 100%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    .thumb {
      border: none;
      img {
        height: 3vw;
        object-fit: cover;
        @media (max-width: 768px) {
          height: 9vw;
        }
      }
      &.selected {
        border: ${colors.brown} 0.05vw solid;
      }
    }

    /* .box {
      display: flex;
      flex-direction: column;

      .thumbnails {
        align-self: center;

        img {
          width: 8.6vw;
          height: 6.76vw;
          object-fit: cover;
        }
      }
    } */
  }
  .property-text {
    color: ${colors.white};
    width: 34.7vw;
    @media (max-width: 768px) {
      width: 100%;
    }
    .property-heading {
      color: ${colors.brown};
    }
    .desc-price {
      display: flex;
      justify-content: space-between;
      align-self: center;
    }
    .property-details {
      display: flex;
      align-items: center;
      margin-bottom: 1.08vw;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        &:not(:nth-child(1)) {
          margin-left: 1.5vw;
          @media (max-width: 768px) {
            margin-left: 5.33vw;
          }
        }
        svg {
          margin-right: 0.7vw;
          @media (max-width: 768px) {
            margin-right: 2.7vw;
          }
        }
        .beds-icon {
          width: 2.29vw;
          height: 1.3vw;
          @media (max-width: 768px) {
            width: 8.8vw;
            height: 5.1vw;
          }
        }
        .baths-icon {
          width: 2.29vw;
          height: 1.67vw;
          @media (max-width: 768px) {
            width: 8.4vw;
            height: 6.2vw;
          }
        }
        .area-icon {
          width: 1.9vw;
          height: 1.9vw;
          @media (max-width: 768px) {
            width: 7.3vw;
            height: 7.3vw;
          }
        }
      }
    }
    .property-details-paragraph {
      @media (max-width: 768px) {
        font-size: 4vw;
        margin-top: 4vw;
      }
    }
  }
`;
export default PropertyCard;
