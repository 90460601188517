import React, { useEffect, useState } from "react";
import styled from "styled-components";
import image from "../../../images/docplaceholder.jpeg";
import { axiosAuth } from "../../../util/api/integration";
import colors from "../../../util/colors";
import { formatText, getUnitStatus } from "../../../util/utilFunctions";
import Amount from "../../assets/Amount";
import Label from "../../assets/Label";
import { ReactComponent as Document } from "../../../images/document.svg";
import pdf from "../../../images/pdf.png";
import AllAttachments from "./AllAttachments";
import history from "../../../util/api/history";
import { Link } from "react-router-dom";
import ViewImage from "./ViewImage";
import placeholder from "../../../images/property-placeholder.png";

const properties_unit_values = {
  U000141: 16596000,
  U000166: 71910000,
  U000163: 17396000,
  U000165: 104770000,
  U000168: 105350000,
  U000164: 100910000,
  U000167: 6953000,
  U000143: 17002000,
};

const PropertyDetails = ({ unit_code, stats, data }) => {
  return (
    <StyledHeader className="detailed-property-header">
      {data && (
        <>
          {stats && (
            <div className="installment-stats">
              <div className="stats-card">
                <p className="key">Total Installments</p>
                <p className="value">
                  {(+stats.totalAmount)
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .replace(/\.00$/, "")}{" "}
                  EGP
                </p>
              </div>
              <div className="stats-card">
                <p className="key">Total Paid Installments</p>
                <p className="value">
                  {(+stats.paidAmount)
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .replace(/\.00$/, "")}{" "}
                  EGP
                </p>
              </div>
              <div className="stats-card">
                <p className="key">Remaining</p>
                <p className="value">
                  {(+stats.remaining)
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .replace(/\.00$/, "")}{" "}
                  EGP
                </p>
              </div>
            </div>
          )}
          <div className="container">
            <div className="row1">
              <div className="cards">
                <div className="card">
                  <div className="card-header">
                    <div className="circle">
                      {data.owner_name?.slice(0, 2)?.toUpperCase()}
                    </div>
                    <div className="owner">
                      <p className="text">Owner</p>
                      <p className="name">{data.owner_name}</p>
                    </div>
                  </div>
                  <div className="card-info">
                    <div className="details-item">
                      <p className="key">Unit Value</p>
                      {data.value ? (
                        <p>
                          {data.value
                            .toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            .replace(/\.00$/, "")}
                        </p>
                      ) : (
                        <p>
                          {properties_unit_values[
                            unit_code
                          ]?.toLocaleString() || "----"}
                        </p>
                      )}
                    </div>
                    <div className="details-item">
                      <p className="key">Annual Tax</p>

                      {data.annual_tax !== null ? (
                        <p>
                          {data.annual_tax
                            ?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            .replace(/\.00$/, "")}
                        </p>
                      ) : (
                        <p>----</p>
                      )}
                    </div>
                    <div className="details-item">
                      <p className="key">Tax State</p>
                      <p>{data.tax_state ? data.tax_state : "----"}</p>
                    </div>
                    <div className="details-item">
                      <p className="key">Last Settlement Date</p>
                      <p>
                        {data.last_settlement_date
                          ? data.last_settlement_date
                          : "----"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-info">
                    <div className="details-item">
                      <p className="key">Status </p>
                      <p>{getUnitStatus(data.rent_state, data.sale_state)}</p>
                    </div>
                    <div className="details-item">
                      <p className="key">Tenant Name </p>
                      <p>{data.tenant_name2 ? data.tenant_name2 : "----"}</p>
                    </div>
                    <div className="details-item">
                      <p className="key">Amount</p>
                      <p>
                        {" "}
                        {data.rent_amount
                          ? data.rent_amount + " EGP"
                          : "----"}{" "}
                      </p>
                    </div>
                    <div className="details-item">
                      <p className="key">Rent Type</p>
                      <p>{data.rent_type || "----"}</p>
                    </div>
                    <div className="details-item">
                      <p className="key">From</p>
                      <p>{data.rent_startdate || "----"}</p>
                    </div>
                    <div className="details-item">
                      <p className="key">To</p>
                      <p>{data.rent_enddate || "----"}</p>
                    </div>
                  </div>
                </div>
              </div>
              {data.images.length > 0 ? (
                <div className="desktop-only">
                  <ViewImage image={data.images[0].url} />
                  <div
                    className="image-container"
                    onClick={() => {
                      history.push("?view-image=true");
                    }}
                  >
                    <img src={data.images[0].url} className="property-image" />
                  </div>
                </div>
              ) : (
                <img
                  src={placeholder}
                  className="property-placeholder desktop-only"
                />
              )}
            </div>

            <div className="attachments">
              <div className="attachments-header">
                <p> Attachments</p>

                {data.property_docs.length > 7 && (
                  <span>
                    <Link to="?all-attachments=true">(View All)</Link>
                  </span>
                )}
              </div>
              {data.property_docs.length > 0 ? (
                <>
                  <AllAttachments attachments={data.property_docs} />
                  <div className="attachments-grid">
                    {/* {data.property_docs.map((doc) => ( */}
                    {data.property_docs.slice(0, 7).map((doc) => (
                      <a
                        className="attachment-link"
                        key={doc.url}
                        href={doc.url}
                      >
                        <div className="attachment">
                          {/* <img src={image} alt="doc-placeholder" /> */}
                          {/* <Document /> */}
                          <img src={pdf} />
                          <p>{doc.name}</p>
                        </div>
                      </a>
                    ))}
                  </div>
                </>
              ) : (
                <p className="no-docs">No documents available</p>
              )}
            </div>
          </div>
        </>
      )}
    </StyledHeader>
  );
};
const StyledHeader = styled.div`
  * {
    font-family: "Mulish", sans-serif;
  }
  .installment-stats {
    display: flex;
    gap: 2.083vw;
    margin-bottom: 2.222vw;
    @media (max-width: 768px) {
      margin-bottom: 6.4vw;
    }
    .stats-card {
      padding: 1.667vw;
      width: 26.181vw;
      height: 7.917vw;
      border-radius: 0.556vw;
      border: 0.069vw solid #ebe8df;
      background: var(--white, #fff);

      .key {
        color: var(--golden-grey, #b4ac9f);
        font-family: Mulish;
        font-size: 1.319vw;
        font-style: normal;
        font-weight: 700;
        line-height: 1.667vw;
        margin-bottom: 0.833vw;
        @media (min-width: 1440px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
      .value {
        color: var(--black, #010101);
        font-family: Mulish;
        font-size: 1.667vw;
        font-style: normal;
        font-weight: 700;
        line-height: 2.083vw;
        @media (min-width: 1440px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
    @media (max-width: 768px) {
      width: 91.467vw;
      padding: 4.267vw 0;
      flex-direction: column;
      gap: 0;
      margin-bottom: 4.267vw;
      border-radius: 2.133vw;
      border: 0.267vw solid #ebe8df;
      background: var(--white, #fff);
      margin-left: 4.267vw;
      .stats-card {
        width: auto;
        height: auto;
        border-radius: 0;
        border: 0;
        border-bottom: 0.267vw solid #ebe8df;
        margin-bottom: 4.267vw;
        padding: 0 4.267vw 4.267vw;
        .key {
          font-size: 3.733vw;
          line-height: 4.8vw;
        }
        .value {
          font-size: 4.8vw;
          line-height: 6.133vw;
        }
      }
      .stats-card:last-of-type {
        border: 0;
        margin: 0;
        padding-bottom: 0;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 3.472vw;
    width: 82.639vw;
    /* align-items: center; */
    @media (max-width: 768px) {
      width: 100%;
      gap: 6.4vw;
    }

    .row1 {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        padding: 0 4.267vw;
      }
    }
    .cards {
      display: flex;
      flex-direction: column;
      gap: 0.972vw;
      @media (max-width: 768px) {
        gap: 4.267vw;
      }
    }
    .image-container {
      cursor: pointer;
      width: 46.597vw;
      height: 28.056vw;
      border-radius: 0.556vw;
      border: 0.069vw solid #000;
      background: #000;
      .property-image {
        width: 46.597vw;
        height: 28.056vw;
        object-fit: contain;
        border-radius: 0.556vw;
      }
    }
    .property-placeholder {
      width: 46.597vw;
      height: 28.056vw;
      border-radius: 0.556vw;
    }

    .card {
      width: 34.653vw;
      // height: 14.583vw;
      border-radius: 0.556vw;
      border: 0.069vw solid #ebe8df;
      background: #fff;
      .card-header {
        padding: 1.111vw 1.667vw;
        display: flex;
        align-items: center;
        gap: 1.111vw;
        border-bottom: 0.069vw solid #ebe8df;
        .circle {
          width: 3.472vw;
          height: 3.472vw;
          border-radius: 50%;
          background: #af916d;
          color: #fff;
          font-family: Mulish;
          font-size: 1.319vw;
          font-style: normal;
          font-weight: 700;
          line-height: 1.389vw;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .owner {
          .text {
            color: var(--light-grey, #a7a9ac);
            font-family: Mulish;
            font-size: 0.972vw;
            line-height: 1.25vw;
            font-style: normal;
            font-weight: 600;
            @media (min-width: 1440px) {
              font-size: 14px;
              line-height: 18px;
            }
          }
          .name {
            color: #010101;
            font-family: Mulish;
            font-size: 1.111vw;
            font-style: normal;
            font-weight: 500;
            line-height: 1.389vw;

            @media (min-width: 1440px) {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
      .card-info {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 1.111vw 1.667vw;
        row-gap: 1.111vw;
        .details-item {
          p {
            color: #010101;
            font-family: Mulish;
            font-size: 1.111vw;
            font-style: normal;
            font-weight: 500;
            line-height: 1.389vw;
            @media (min-width: 1440px) {
              font-size: 16px;
              line-height: 20px;
            }
          }
          .key {
            color: #a7a9ac;
            font-family: Mulish;
            font-size: 0.972vw;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25vw;
            @media (min-width: 1440px) {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }

      @media (max-width: 768px) {
        width: 91.467vw;
        border-radius: 1.067vw;
        border: 0.267vw solid #ebe8df;
        .card-header {
          padding: 4.267vw;
          gap: 4.267vw;
          border-bottom: 0.267vw solid #ebe8df;
          .circle {
            width: 13.333vw;
            height: 13.333vw;
            font-size: 5.067vw;
            line-height: 5.333vw;
          }
          .owner {
            .text {
              font-size: 3.733vw;
              line-height: 4.8vw;
            }
            .name {
              font-size: 4.267vw;
              line-height: 5.333vw;
            }
          }
        }
        .card-info {
          grid-template-columns: 60% 40%;
          padding: 4.267vw;
          row-gap: 4.267vw;
          .details-item {
            p {
              font-size: 3.733vw;
              line-height: 4.8vw;
            }
            .key {
              font-size: 3.2vw;
              line-height: 4vw;
            }
          }
        }
      }
    }
    .attachments {
      width: 82.639vw;
      .attachments-header {
        display: flex;
        align-items: center;
        gap: 0.556vw;
        margin-bottom: 2.222vw;

        a {
          color: var(--brown, #af916d);
          text-align: center;
          font-family: Mulish;
          font-size: 1.389vw;
          line-height: 1.736vw;
          font-style: normal;
          font-weight: 700;

          @media (min-width: 1440px) {
            font-size: 20px;
            line-height: 25px;
          }
        }
        p {
          color: #010101;
          font-family: Mulish;
          font-size: 2.083vw;
          line-height: 2.639vw;
          font-style: normal;
          font-weight: 800;

          @media (min-width: 1440px) {
            font-size: 30px;
            line-height: 38px;
          }
        }
      }
      .attachments-grid {
        display: grid;
        gap: 1.319vw;
        grid-template-columns: repeat(7, 1fr);
        .attachment-link {
          text-decoration: none;
          color: black;
          .attachment {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 0.556vw;
            border: 0.069vw solid #ebe8df;
            background: #fff;
            width: 10.694vw;
            height: 9.931vw;
            padding: 0.972vw;

            img {
              width: 3.681vw;
              height: 4.514vw;
            }
            p {
              text-align: center;
              color: #010101;
              font-family: Cairo;
              font-size: 0.833vw;
              line-height: 1.528vw;
              font-style: normal;
              font-weight: 600;
              @media (min-width: 1440px) {
                font-size: 12px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      .attachments {
        width: auto;
        .attachments-header {
          display: flex;
          align-items: center;
          gap: 0;
          justify-content: space-between;
          margin-bottom: 5.067vw;
          padding: 0 4.267vw;

          a {
            font-size: 3.733vw;
            line-height: 4.8vw;
          }
          p {
            font-size: 4.8vw;
            line-height: 6.133vw;
          }
        }
        .attachments-grid::-webkit-scrollbar {
          display: none;
        }
        .attachments-grid {
          gap: 2.133vw;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          overflow: scroll;
          .attachment-link {
            .attachment {
              border-radius: 2.133vw;
              border: 0.267vw solid #ebe8df;
              width: 34.667vw;
              height: 38.133vw;
              // padding: 6.4vw;

              img {
                width: 14.133vw;
                height: 17.333vw;
              }
              p {
                font-size: 3.2vw;
                line-height: 5.867vw;
              }
            }
          }
          .attachment-link:first-of-type {
            margin-left: 4.267vw;
          }
          .attachment-link:last-of-type {
            margin-right: 4.267vw;
          }
        }
      }
    }
  }
`;
export default PropertyDetails;
