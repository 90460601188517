import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
/*
USAGE:
illustration: svg imported as ReactChild
accept: same as html default
*/
const CustomUpload = ({
  files,
  setFiles,
  illustration: Upload = DefaultIllust,
  label,
  ...props
}) => {
  return (
    <StyledInputDiv className="upload-image-input">
      <label for="upload-photo">
        <Upload />
        <p className="fs16 fw600">
          {(files?.length > 0 && files.length + " image(s) selected") ||
            label ||
            "Upload images"}
        </p>
      </label>
      <input
        type="file"
        multiple
        accept={props.accept ? props.accept : "*"}
        id="upload-photo"
        onChange={(e) => setFiles(e.target.files)}
      />
    </StyledInputDiv>
  );
};

const StyledInputDiv = styled.div`
  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      width: 5vw;
      height: auto;
      margin: auto;
      @media (max-width: 768px) {
        width: 15vw;
      }
    }
    p {
      color: ${colors.mainBlue};
      /* width: 80%; */
      text-align: center;
    }
  }
  #upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
`;

const DefaultIllust = () => {
  return (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1656_35753)">
        <path
          d="M15.1251 54.9998C11.1137 55.0005 7.26627 53.4077 4.42927 50.5717C1.59227 47.7357 -0.00195595 43.8889 -0.0026853 39.8775C-0.00341464 35.8661 1.58941 32.0187 4.42538 29.1817C7.26135 26.3447 11.1082 24.7505 15.1196 24.7498C16.6682 21.0394 19.4222 17.9592 22.9368 16.0066C26.4514 14.054 30.5216 13.3429 34.4901 13.9881C38.4586 14.6334 42.0938 16.5973 44.8087 19.5629C47.5237 22.5284 49.16 26.3224 49.4533 30.3323L50.8751 30.2498C54.1571 30.2498 57.3047 31.5536 59.6255 33.8743C61.9463 36.1951 63.2501 39.3427 63.2501 42.6248C63.2501 45.9068 61.9463 49.0545 59.6255 51.3752C57.3047 53.696 54.1571 54.9998 50.8751 54.9998H15.1251ZM15.1251 27.4998C11.843 27.4998 8.69538 28.8036 6.37462 31.1243C4.05385 33.4451 2.75006 36.5927 2.75006 39.8748C2.75006 43.1568 4.05385 46.3045 6.37462 48.6252C8.69538 50.946 11.843 52.2498 15.1251 52.2498H50.8751C52.3269 52.2486 53.7596 51.9191 55.066 51.2858C56.3724 50.6525 57.5186 49.7318 58.4188 48.5928C59.319 47.4538 59.9499 46.1259 60.2642 44.7085C60.5785 43.2911 60.5681 41.821 60.2338 40.4082C59.8994 38.9954 59.2498 37.6766 58.3336 36.5504C57.4173 35.4243 56.2582 34.5199 54.943 33.9052C53.6277 33.2905 52.1905 32.9812 50.7388 33.0006C49.2871 33.02 47.8586 33.3676 46.5603 34.0173L46.7501 31.6248C46.7544 27.958 45.4265 24.4144 43.0134 21.6535C40.6004 18.8926 37.2665 17.1023 33.6321 16.6157C29.9978 16.1291 26.3104 16.9794 23.256 19.0083C20.2017 21.0373 17.9884 24.1067 17.0281 27.6455L15.1251 27.4998ZM33.0001 46.7498V32.3123L39.1876 38.4998L41.2501 36.6738L31.6251 27.0488L22.0001 36.6738L24.0626 38.4998L30.2501 32.3123V46.7498H33.0001Z"
          fill="#AF916D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1656_35753">
          <rect width="66" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CustomUpload;
