import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../util/colors";
import SubmitButton from "../assets/SubmitButton";
import StyledPage from "../assets/StyledPage";
import bg from "../../images/bgsecondary.jpg";
import { axiosOMS } from "../../util/api/integration";
import history from "../../util/api/history";

const Reset = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const resetPwHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosOMS.post("/forgotpassword", { email });
      alert(response.data.message);
      setEmail("");
      setLoading(false);
      history.push("/login");
    } catch (error) {
      alert(error.response?.data?.message || "Something went wrong!");
      setLoading(false);
    }
  };

  return (
    <StyledPage no-padding background={bg}>
      <StyledContainer>
        <h1 className="fs30">Reset Password</h1>
        <form onSubmit={resetPwHandler}>
          <div className="form-item">
            <label htmlFor="email">Email *</label>
            <input
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="input-global"
              type="email"
              id="email"
            />
          </div>
          <Link to="/contacts" id="reset-pw">
            Having a problem? Contact Us
          </Link>
          <SubmitButton
            disabled={loading}
            className={`${loading ? "disabled-button" : ""}`}
            label={`${loading ? "Loading ..." : "Submit"}`}
          />
        </form>
      </StyledContainer>
    </StyledPage>
  );
};
const StyledContainer = styled.div`
  background: #fbfbfd;
  width: 33.5vw;
  height: 33.5vw;
  padding: 2.8vw 2.08vw 2.43vw 2.29vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  align-self: center;
  margin-bottom: 13.2vw;
  border-radius: 0.35vw;

  @media (max-width: 768px) {
    width: 79.7vw;
    height: 129.1vw;
    margin-bottom: 33.9vw;
    margin-top: 30.4vw;
    padding: 10.9vw 7.8vw 10.13vw 8.2vw;
    border-radius: 1.33vw;
  }
  h1 {
    color: ${colors.brown};
    margin-bottom: 1.9vw;
    @media (max-width: 768px) {
      margin-bottom: 12vw;
    }
  }
  a {
    text-decoration: none;
    color: ${colors.brown};
    font-size: 0.9vw;
    @media (max-width: 768px) {
      font-size: 3.5vw;
    }
  }
  p {
    font-size: 0.9vw;
    @media (max-width: 768px) {
      font-size: 3.5vw;
      margin-top: 1.3vw;
    }
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
    .form-item {
      display: flex;
      flex-direction: column;
      &:not(:last-of-type) {
        margin-bottom: 1vw;
        @media (max-width: 768px) {
          margin-bottom: 4vw;
        }
      }
      label {
        color: ${colors.brown};
        font-size: 0.76vw;
        @media (max-width: 768px) {
          font-size: 2.9vw;
        }
      }
    }
    button {
      background: ${colors.brown};
      margin-top: 5.35vw;
      margin-bottom: 0.76vw;
      &.disabled-button {
        background: ${colors.lightGrey};
      }
      @media (max-width: 768px) {
        margin-top: 29.3vw;
      }
    }
    a {
      align-self: flex-end;
    }
  }
  #reset-pw {
    margin-top: 0.35vw;
    @media (max-width: 768px) {
      margin-top: 1.33vw;
    }
  }
`;
export default Reset;
