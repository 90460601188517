import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { axiosAuth } from "../../../util/api/integration";
import { installments_data } from "./installments-data";
import InstallmentsTable from "./InstallmentsTable";
import TransactionsTable from "./TransactionsTable";
import { SelectBar } from "../SearchBar";
import { transactionsNames } from "../../../util/api/data";
import colors from "../../../util/colors";
import { useLocation } from "react-router";

const PropertyTables = ({
  unit_code,
  installmentsStats,
  setInstallmentsStats = () => null,
  ...props
}) => {
  const [transactionType, setTransactionType] = useState("");
  const [transactions, setTransactions] = useState();
  const [transcationsRawData, setTranscationsRawData] = useState();
  const [t_page, setT_page] = useState(1);
  const [t_perPage, setT_perPage] = useState(10);
  const [installments, setInstallments] = useState([]);
  const [installmentsRawData, setInstallmentsRawData] = useState();
  const [i_page, setI_page] = useState(1);
  const [i_perPage, setI_perPage] = useState(10);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [installmentsCount, setInstallmentsCount] = useState(0);
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const installments_only = pathname == "manage-properties";
  const [table, setTable] = useState(
    installments_only ? "installments" : "transactions"
  );
  const isInitialMount = useRef(true);
  const [data, setData] = useState();
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosAuth.get(`properties/${unit_code}`);
        setData(res.data);
      } catch (error) {
        alert(error.response?.data?.message || "Something went wrong!");
      }
    };
    fetchData();
  }, []);

  /* 
  fetch static installments
   */
  // useEffect(() => {
  //   setInstallments(installments_data[unit_code]);
  //   setInstallmentsCount(installments_data[unit_code]?.length || 0);
  //   console.log(
  //     installments_data[unit_code],
  //     installments_data[unit_code]?.length
  //   );
  // }, []);

  function countAmounts(arr) {
    let totalAmount = 0;
    let paidAmount = 0;

    for (let i = 0; i < arr.length; i++) {
      totalAmount += +arr[i].installment_amount;
      if (arr[i].status == 1) {
        paidAmount += +arr[i].installment_amount;
      }
    }
    let remaining = totalAmount - paidAmount;
    return { totalAmount, paidAmount, remaining };
  }

  useEffect(() => {
    if (data) {
      const fetchPropertyInstallments = async () => {
        try {
          const response = await axiosAuth.get(`/installments/${data.id}`);
          console.log(response);
          setInstallments(response.data.installments);
          setInstallmentsCount(response.data.installments.length);
          if (response.data.installments.length > 0) {
            setInstallmentsStats(countAmounts(response.data.installments));
          }
          isInitialMount.current = false;
        } catch (error) {
          console.log("error from fetch installments", error);
          setInstallments([]);
          // alert(error.response?.data?.message || "Something went wrong!");
        }
      };
      fetchPropertyInstallments();
    }
  }, [i_page, i_perPage, data, location.key, refresh]);

  useEffect(() => {
    if (!installments_only) {
      const fetchPropertyTransactions = async () => {
        try {
          const response = await axiosAuth.get(
            `/properties/${unit_code}/transactions?page=${t_page}&per_page=${t_perPage}&name=${transactionType}`
          );
          setTranscationsRawData(response.data);
          setTransactions(response.data.results);
          if (isInitialMount.current) {
            setTransactionsCount(response.data.count);
            isInitialMount.current = false;
          }
        } catch (error) {
          alert(error.response?.data?.message || "Something went wrong!");
        }
      };
      fetchPropertyTransactions();
    }
  }, [t_page, t_perPage, transactionType]);

  useEffect(() => {
    if (transactionsCount == 0 && installmentsCount > 0) {
      setTable("installments");
    }
  }, [transactionsCount, installmentsCount]);

  return (
    <StyledSection>
      <div className="transactions-toolbar">
        <div className="tables-titles">
          {(transactionsCount == 0 && installmentsCount == 0) ||
          (transactionsCount > 0 && installmentsCount > 0) ? (
            <>
              {!installments_only && (
                <button
                  onClick={() => {
                    setTable("transactions");
                  }}
                  className={`${table == "transactions" ? "selected" : ""}`}
                >
                  Transactions
                </button>
              )}
              <button
                onClick={() => {
                  setTable("installments");
                }}
                className={`${table == "installments" ? "selected" : ""}`}
              >
                Installments
              </button>
            </>
          ) : transactionsCount > 0 ? (
            <button
              onClick={() => {
                setTable("transactions");
              }}
              className={`${table == "transactions" ? "selected" : ""}`}
            >
              Transactions
            </button>
          ) : (
            installmentsCount > 0 && (
              <button
                onClick={() => {
                  setTable("installments");
                }}
                className={`${table == "installments" ? "selected" : ""}`}
              >
                {" "}
                Installments
              </button>
            )
          )}
        </div>

        {/* <select>
          <option value="">Select Transaction Type</option>
        </select> */}
        {table == "transactions" && (
          <SelectBar
            className="trans-type-select desktop-only"
            placeholder="Select Transaction Type"
            options={transactionsNames}
            value={transactionType}
            setValue={setTransactionType}
          />
        )}
      </div>
      {table == "transactions" ? (
        <div className="table-container">
          <TransactionsTable
            unit_code={unit_code}
            transactionType={transactionType}
            transactions={transactions}
            rawData={transcationsRawData}
            page={t_page}
            setPage={setT_page}
            perPage={t_perPage}
            setPerPage={setT_perPage}
          />
        </div>
      ) : (
        <div className="table-container">
          <InstallmentsTable
            pId={data?.id}
            unit_code={unit_code}
            installments={installments}
            refresh={refresh}
            setRefresh={setRefresh}
            // rawData={installmentsRawData}
            // page={i_page}
            // setPage={setI_page}
            // perPage={i_perPage}
            // setPerPage={setI_perPage}
          />
        </div>
      )}
    </StyledSection>
  );
};

const StyledSection = styled.div`

@media(max-width : 768px){
  padding-left : 4.267vw ;
}
  .transactions-title {
    font-size: 2.1vw;
    @media (max-width: 768px) {
      font-size: 6vw;
    }
  }
  .tables-titles {
    display: flex;
    gap: 1.667vw;

    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0.556vw;
      color: var(--neutral-800, #191d23);
      text-align: center;
      font-family: Manrope;
      font-size: 1.111vw;
      line-height: 1.528vw;
      font-style: normal;
      font-weight: 400;
      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .selected {
      color: #af916d;
      border-bottom: 0.139vw solid #af916d;
    }
    @media (max-width: 768px) {
      gap: 9.867vw;

      button {
        padding: 2.667vw;
        font-size: 4.267vw;
        line-height: 5.333vw;
      }
      .selected {
        border-bottom: 0.533vw solid #af916d;
      }
    }
  }
  .transactions-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 83.6vw;
    margin-top: 2.917vw;
    margin-bottom: 1.667vw;
    @media (max-width: 768px) {
      width: auto;
      margin-top: 8.533vw;
      margin-bottom: 4.267vw;
    }
    select {
      outline: none;
      width: 28.5vw;
      height: 2.9vw;
      border: solid 0.07vw #ebe8df;
      font-size: 1vw;
      /* color: #9e9e9e; */
      border-radius: 0.56vw;
      @media (max-width: 768px) {
        width: 100%;
        height: 9vw;
        border: solid 0.21vw #ebe8df;
        font-size: 3vw;
        border-radius: 1.8vw;
      }
    }
  }
  .details-table {
    tbody {
      max-height: 30vw;
    }
  }
  .table-container {
    @media (max-width: 768px) {
      max-width: 95.733vw;
      overflow-x: auto;
      margin-bottom: 4.267vw;
      /* Customizing scroll bar */
      ::-webkit-scrollbar {
        width: 0.55vw;
        height: 1vw;
        @media (max-width: 768px) {
          width: 1.7vw;
        }
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f3efe9;
        border-radius: 1vw;
        @media (max-width: 768px) {
          border-radius: 3vw;
        }
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${colors.brown};
        border-radius: 1vw;
        @media (max-width: 768px) {
          border-radius: 3vw;
        }
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        /* background: ${colors.lightMain}; */
      }
    }
  }
`;

export default PropertyTables;
